import { Component, Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'image-dialog',
  templateUrl: 'aeimagedialog.component.html',
})
export class ImageDialog {
  imageurl: string = ""
  cancelButtonText = "Close"
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private imagedialogRef: MatDialogRef<ImageDialog>) {
    if (data) {
      this.imageurl = data.imageurl || this.imageurl;
      console.log('dialog' + this.imageurl);
    }
  }

}