import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map, share } from 'rxjs/operators';
import { Aebonusregistercustomer } from './aebonusregister_class_customer';
import { environment } from '../../../environments/environment';
import { AcdcNotificationsService } from 'acdc-notifications';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'dbconnection': 'us' })
};

const bonusregistercustomerserviceUrl = `${environment.apiServiceUrl}/aecustomervolume`;
const bonusregisterdownlineserviceUrl = `${environment.apiServiceUrl}/aecustomerdownline`;

@Injectable({
  providedIn: 'root'
})

export class AebonusregistercustomerService {

  constructor(private http: HttpClient,
    private acdcNotificationsService: AcdcNotificationsService) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getAebonusregisterCustomers (selectedcycle: string, vlimit: number): Observable<Aebonusregistercustomer[]> {
    const url = `${bonusregistercustomerserviceUrl}/00000,${selectedcycle},${vlimit}`;
    return this.http.get<Aebonusregistercustomer[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aebonusregistercustomers')),
        catchError(this.handleError('getAebonusregistercustomers', [])),
        share()
      );
  }

  getAebonusregisterDownlines (selectedcustomerdn: string, selectedcycle: string, selecteddevbonus: number): Observable<Aebonusregistercustomer[]> {
    const url = `${bonusregisterdownlineserviceUrl}/${selectedcustomerdn},${selectedcycle},${selecteddevbonus}`;
    return this.http.get<Aebonusregistercustomer[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aebonusregistercustomers')),
        catchError(this.handleError('getAebonusregistercustomers', [])),
        share()
      );
  }

 
}
