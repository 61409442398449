import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
    selector: 'ngx-showdownline',
    template: `<a href="javascript:void(0)" (click)="onClick()">
        <i *ngIf="!hasDownline" class="ion-sad"></i>
        <i *ngIf="hasDownline" class="ion-happy-outline"></i></a>`,
    })
    export class ShowdownlineComponent implements OnInit {
        public renderValue: string;
        public hasDownline: boolean;
        public sponsordn;
    
        @Input() value: string | number;
        @Input() rowData: any;
      
        @Output() save: EventEmitter<any> = new EventEmitter();

        constructor() {
        }
    
        ngOnInit() {
            this.renderValue = this.value.toString().toUpperCase();
            if (this.rowData.downline=== 0) {this.hasDownline = false} else{this.hasDownline = true}
            this.sponsordn = this.rowData.sponsorDn;
        }
    
        onClick() {
            var response = {
                customerdn: this.rowData.customerDn,
                members:this.rowData.downline,
                sponsordn: this.rowData.sponsorDn,
             }
            this.save.emit(response);
        }
        
    }
    