import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
@Component({
    selector: 'ngx-selectoperator',
    template: `<a href="javascript:void(0)" (click)="onClick()">
        <img *ngIf="selectedOperator.trim()!=rowdataOperator" width="30" height="30" src="../../../assets/images/unchecked.jpg">,
        <img *ngIf="selectedOperator.trim()==rowdataOperator" width="30" height="30" src="../../../assets/images/checked.jpg"></a>`,
    })
    export class SelectOperatorComponent implements OnInit {
        public renderValue: string;
        public selectedOperator: string;
        public rowdataOperator: string;
    
        @Input() value: string | number;
        @Input() rowData: any;
      
        @Output() save: EventEmitter<any> = new EventEmitter();

        constructor(
            private localstorage: LocalStorageService,
        ) {
            this.selectedOperator = this.localstorage.getItem("selectedoperator");


        }
    
        ngOnInit() {
            this.renderValue = this.value.toString();
            this.rowdataOperator = this.rowData.operatorDn + ' ' + this.rowData.operator
 //           alert(this.rowdataOperator + '-' + this.selectedOperator);
        }
    
        onClick() {
            var response = {
                id: this.rowData.id,
                operatorDn: this.rowData.operatorDn,
             }
            this.save.emit(response);
        }
        
    }
    