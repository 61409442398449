export const AeBonusTableSettings = {
  actions: {
    columnTitle: '',
    delete: false,
    add: false,
    edit: true,
  },
  mode: 'external',
  hideHeader: true,
  noDataMessage: '',
  pager: {
    display: true,
    perPage: 10,
  },
  edit: {
    editButtonContent: '<i class="ion-ios-eye-outline"></i>',
  },
  columns: {
    customerDn: {
      title: 'Customer',
      width: '10%',
    },
    customerName: {
      title: 'Customer Name',
      width: '15%'
    },
    sponsorDn: {
      title: 'Sponsor',
      width: '5%'
    },
    sponsorName: {
      title: 'Sponsor Name',
      width: '15%'
    },
 
    vp: {
      title: 'VP',
      width: '10%'
    },
    vg: {
      title: 'VG',
      width: '10%'
    },
    nombramiento: {
      title: 'Position',
      width: '15%',
    },
    totalBonos: {
      title: 'Total Bonus',
      width: '15%'
    },
    orden: {
      title: 'Serial',
      width: '5%'
    },
  }
};