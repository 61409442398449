import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Aeorganizationtree } from './aetreeshow_class_organizationtree'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CsvExport } from '../aeshared/aeshared_class_csvexport';
import { AcdcNotificationsService } from 'acdc-notifications';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { environment } from '../../../environments/environment';
import { ShowdownlineComponent } from './aetreeshow_render_showdownline'
import { BonusImageRenderComponent } from '../aeshared/aeshared_render_bonusimage.component';
import { ShowOperatorComponent } from './aetreeshow_render_showoperator'
import { SelectHeadTypeComponent } from './aetreeshow_render_selectheadtype'
import { HeadImageRenderComponent } from './aetreeshow_render_headimage'
import { ShowHeadTypeComponent } from './aetreeshow_render_showheadtype'
import { SelectOperatorComponent } from './aetreeshow_render_selectoperator'
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AetreeshowService } from './aetreeshow.service';
import { ConfirmationDialog } from '../aeshared/aedialog.component';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Aebonusoperator } from './aetreeshow_class_aebonusoperator';
import { Aebonusgroup } from './aetreeshow_class_aebonusgroup';

const bonusoperatorserviceUrl = `${environment.apiServiceUrl}/aebonusoperator`;
const bonusgroupserviceUrl = `${environment.apiServiceUrl}/aebonusgroup`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'dbconnection': 'us' })
};


@Component({
  selector: 'ngx-aetreeshow',
  templateUrl: './aetreeshow.component.html',
  styleUrls: ['./aetreeshow.component.scss']
})

export class AetreeshowComponent implements OnInit {

  showorganization: boolean = true;
  showoperators: boolean = false;
  showheadtypes: boolean = false;
  showheadprocess: boolean = false;

  selectedOperatorId: number;
  selectedOperatorDn: string;
  selectedOperator: string = '';
  hasOperator: boolean = false;
  selectedCustomerDn: string = '';
  selectedHeadType: number = 0;
  isGroupHead: boolean = false;
  selectedCycle: string;

  selectedGroupHeadDn: string = '';
  selectedGroupHeadType: number;

  aeheadtypes = [
    { id: 0, headType: 'noclub' },
    { id: 24, headType: 'club24' },
    { id: 25, headType: 'club24h' },
    { id: 50, headType: 'club50' },
    { id: 55, headType: 'club50h' },
  ];


  data: Aeorganizationtree[] = [];
  isLoadingResults = true;
  aeorganizationtree: Aeorganizationtree[];
  aebonusoperator: Aebonusoperator[];
  aebonusgroup: Aebonusgroup;
  customerDn: string;
  ciclosId: string;
  withVolume: string = 'n';
  withBonus: string = 'n';
  sponsorDn: string = undefined;
  treeSponsorDn: string;
  aetreewithbonustablesettings = {
    actions: {
      add: false,
      delete: false,
      edit: false,
    },
    mode: 'external',
    hideHeader: true,
    pager: {
      display: true,
      perPage: 20,
    },
    columns: {
      showdownline: {
        title: 'Downline',
        type: 'custom',
        renderComponent: ShowdownlineComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(response => {
            this.sponsorDn = response.customerDn;
            this.showdownline(response.customerdn, response.members)
          });
        }
      },
      downline: {
        title: 'Members',
        width: '5%'
      },
      customerDn: {
        title: 'Dn',
        width: '10%'
      },
      customerName: {
        title: 'Distributor',
        width: '40%'
      },
      vp: {
        title: 'Vp',
        width: '15%'
      },
      vg: {
        title: 'Vg',
        width: '15%'
      },
      vgA: {
        title: 'vgA',
        width: '15%'
      },
      v200: {
        title: 'Rent',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: BonusImageRenderComponent,
        valuePrepareFunction: (cell, row) => {
          return cell;
        },
      },
      operator: {
        title: 'Operator',
        type: 'custom',
        renderComponent: ShowOperatorComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(response => {
            this.hasOperator = response.hasOperator;
            this.selectedOperator = response.operator;
            this.selectedCustomerDn = response.operatorCustomerDn;
            this.localstorage.setItem('selectedoperator', this.selectedOperator)
            this.openOperatorDialog()
          });
        }
      },
      groupBonus: {
        title: 'Bonus',
        width: '10%',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          if (row.bonusType === 50) {
            return '<p class="cell_red_left">' + cell + '</p>';
          }
          else if (row.bonusType === 24) {
            return '<p class="cell_blue_left">' + cell + '</p>';
          }
          else {
            return '<p class="cell_none_center">' + cell + '</p>';
          }
        }
      },
      headType: {
        title: 'Group',
        type: 'custom',
        renderComponent: ShowHeadTypeComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(response => {
            this.selectedCustomerDn = response.customerDn;
            this.selectedHeadType = response.headType;
            this.isGroupHead = response.isgroupHead;
            this.localstorage.setItem('selectedheadtype', this.selectedHeadType)
            this.openHeadTypeDialog()
          });
        }
      },

    }
  };

  aetreetablesettings = {
    actions: {
      add: false,
      delete: false,
      edit: false,
    },
    mode: 'external',
    hideHeader: true,
    pager: {
      display: true,
      perPage: 20,
    },
    columns: {
      showdownline: {
        title: 'Downline',
        type: 'custom',
        renderComponent: ShowdownlineComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(response => {
            this.sponsorDn = response.customerDn;
            this.showdownline(response.customerdn, response.members)
          });
        }
      },
      downline: {
        title: 'Members',
        width: '5%'
      },
      customerDn: {
        title: 'Dn',
        width: '10%'
      },
      customerName: {
        title: 'Distributor',
        width: '40%'
      },
      vp: {
        title: 'Vp',
        width: '15%'
      },
      vg: {
        title: 'Vg',
        width: '15%'
      },
      vgA: {
        title: 'vgA',
        width: '15%'
      },
      v200: {
        title: 'Rent',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: BonusImageRenderComponent,
        valuePrepareFunction: (cell, row) => {
          return cell;
        },
      },
      operator: {
        title: 'Operator',
        type: 'custom',
        renderComponent: ShowOperatorComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(response => {
            this.hasOperator = response.hasOperator;
            this.selectedOperator = response.operator;
            this.selectedCustomerDn = response.operatorCustomerDn;
            this.localstorage.setItem('selectedoperator', this.selectedOperator)
            this.openOperatorDialog()
          });
        }
      },

    }
  };

  aebonusoperatorsettings = {
    actions: {
      position: 'left',
      columnTitle: '',
      delete: false,
      add: false,
      edit: false,
    },
    pager: {
      display: false,
    },
    mode: 'external',
    hideHeader: true,
    noDataMessage: '',
    columns: {
      id: {
        title: 'Select',
        type: 'custom',
        renderComponent: SelectOperatorComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(response => {
            this.selectedOperatorId = response.id;
            this.selectedOperatorDn = response.operatorDn
            if (response.id > 0) { this.processSelectedOperator(); }
          });
        }
      },
      operatorDn: {
        title: 'Operator',
        width: '15%',
        editable: false,
      },
      operator: {
        title: 'Name',
        width: '15%',
        editable: false,
      },
    },
  };

  aeheadtypessettings = {
    actions: {
      position: 'left',
      columnTitle: '',
      delete: false,
      add: false,
      edit: false,
    },
    pager: {
      display: false,
    },
    mode: 'external',
    hideHeader: true,
    noDataMessage: '',
    columns: {
      id: {
        title: 'Select',
        type: 'custom',
        renderComponent: SelectHeadTypeComponent,
        onComponentInitFunction: (instance: any) => {
          instance.save.subscribe(response => {
            this.selectedHeadType = response.id;
            this.processSelectedHeadType();
          });
        }
      },
      headType: {
        title: 'Group',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: HeadImageRenderComponent,
        valuePrepareFunction: (cell, row) => {
          return cell;
        },
      },
    },
  };


  constructor(
    private router: Router, private route: ActivatedRoute,
    private acdcNotificationsService: AcdcNotificationsService,
    private localstorage: LocalStorageService,
    private api: AetreeshowService,
    public dialog: MatDialog,
    private http: HttpClient,
  ) { }


  ngOnInit() {
    this.selectedCycle = this.localstorage.getItem('aecicloorganization');
    this.api.getBonusOperator().subscribe((opedata: Aebonusoperator[]) => {
      this.aebonusoperator = opedata;
    });
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Organization data',
      timeout: 60000,
      notificationLevel: 'Info'
    });
    this.treeSponsorDn = this.localstorage.getItem("treesponsordn");

    this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      const refresh = paramMap.get('refresh');
      if (refresh) {
        var parameters = this.route.snapshot.paramMap.get('aetreeparam');
        this.customerDn = parameters.split(',', 4)[0];
        this.ciclosId = parameters.split(',', 4)[1];
        this.withVolume = parameters.split(',', 4)[2];
        this.withBonus = parameters.split(',', 4)[3];
        if (this.treeSponsorDn == null) { this.treeSponsorDn = this.customerDn; }
        if (this.customerDn == undefined) {
          alert('customer undefined');
        }
        else {
          this.aeorganizationtree = this.route.snapshot.data['aetreeRes'];
          this.acdcNotificationsService.deleteToast(acdcid);
          // this.api.getBonusGroup(this.ciclosId, this.customerDn).subscribe((grpdata: Aebonusgroup) => {
          //   this.aebonusgroup = grpdata;
          //   this.selectedGroupHeadDn = grpdata.groupHeadDn;
          //   this.selectedGroupHeadType = grpdata.headType;
          // });
        }
      }
    })
  }
  ngAfterViewInit() {
    this.sponsorDn = this.localstorage.getItem("treesponsordn");

  }

  showdownline(customerdn, members) {
    if (members == 0) {
      this.acdcNotificationsService.toast({
        title: 'Distributor doesn\'t have a downline',
        timeout: 3000,
        notificationLevel: 'Info'
      });
    }
    else if (customerdn == this.customerDn) {
      this.acdcNotificationsService.toast({
        title: 'Already showing downline for distributor ' + customerdn,
        timeout: 3000,
        notificationLevel: 'Info'
      });
    }

    else {
      const url = '/aetreeshow/' + `${customerdn},${this.ciclosId},${this.withVolume},${this.withBonus}`;
      this.router.navigate([url]
        , {
          queryParams: { refresh: new Date().getTime() }
        }
      );
    }
  }

  back() {
    //   alert(this.customerDn+ ' ' + this.treeSponsorDn)

    if ((this.customerDn == this.treeSponsorDn)) {
      const url = '/aeuserdashboard'
      this.router.navigate([url]);
    } else {
      var customerDn = this.sponsorDn;
      console.log('Sponsor dn from aetreshow ' + customerDn);
      const url = '/aetreeshow/' + `${customerDn},${this.ciclosId},${this.withVolume},${this.withBonus}`;
      this.router.navigate([url]
        , {
          queryParams: { refresh: new Date().getTime() }
        }
      );

    }
  }

  backfromoperators() {
    // this.showgroupheads = false;
    this.showoperators = false;
    this.showheadtypes = false;
    this.showheadprocess = false;
    this.showorganization = true;
  }

  csv() {
    CsvExport.exportToCsv('Organization.csv', this.aeorganizationtree);
  }

  rowselect(event) {
    if (event.data.downline >= 0) {
      this.sponsorDn = event.data.sponsorDn;
    }
  }

  openOperatorDialog() {
    this.showorganization = false;
    this.showoperators = true;

  }

  openHeadTypeDialog() {
    if (!this.isGroupHead) {
      this.showorganization = false;
      this.showheadtypes = true;
    }
    else {
      alert('Procesar Headtype');
      this.showorganization = false;
      this.showheadtypes = false;
      this.showoperators = false;
      this.showheadprocess = true;


    }

  }

  processSelectedOperator() {
    var data = {
      "ciclosId": this.selectedCycle,
      "customerDn": this.selectedCustomerDn,
      "operadorDn": this.selectedOperatorDn
    };
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: '350px',
      height: '350px',
      data: {
        message: "Confirm Assigning Operator for selected Cycle",
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.http.post<number>(bonusoperatorserviceUrl, data, httpOptions).subscribe(
          res => {
            this.acdcNotificationsService.toast({
              title: 'Registered',
              timeout: 3000,
              notificationLevel: 'Info'
            });
            this.showorganization = true;
            this.showoperators = false;
            let prdind = this.aeorganizationtree.findIndex(x => x.customerDn === this.selectedCustomerDn);
            this.aeorganizationtree[prdind]['operator'] = this.localstorage.getItem('selectedoperator');
            const url = '/aetreeshow/' + `${this.selectedCustomerDn},${this.ciclosId},${this.withVolume},${this.withBonus}`;
            this.router.navigate([url]
              , {
                queryParams: { refresh: new Date().getTime() }
              }
            );

          });
      }
    });

  }

  processSelectedHeadType() {
    var data = {
      "ciclosId": this.selectedCycle,
      "customerDn": this.selectedCustomerDn,
      "groupHeadDn": this.selectedGroupHeadDn,
      "headType": this.selectedHeadType,
    };
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: '350px',
      height: '350px',
      data: {
        message: "Confirm Assigning Group for selected Cycle",
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.http.post<number>(bonusgroupserviceUrl, data, httpOptions).subscribe(
          res => {
            this.acdcNotificationsService.toast({
              title: 'Registered',
              timeout: 3000,
              notificationLevel: 'Info'
            });
            this.showorganization = true;
            this.showheadtypes = false;
            let prdind = this.aeorganizationtree.findIndex(x => x.customerDn === this.selectedCustomerDn);
            this.aeorganizationtree[prdind]['headType'] = this.selectedHeadType;
            const url = '/aetreeshow/' + `${this.selectedCustomerDn},${this.ciclosId},${this.withVolume},${this.withBonus}`;
            this.router.navigate([url]
              , {
                queryParams: { refresh: new Date().getTime() }
              }
            );

          });
      }
    });


  }

}
