import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

// import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LocalStorageService } from '../../../pages/aeshared/aeshared_service_localstorage.service';
import { AecleancurrentguidService } from '../../../pages/aeshared/aeshared_service_cleancurrentguid.service';
import { Router} from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  headerLabel = "";

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private localstorage: LocalStorageService,
    private layoutService: LayoutService,
    private router: Router,
    private cleancurrentguidApi: AecleancurrentguidService,
    private breakpointService: NbMediaBreakpointsService) {
    this.headerLabel =
      localstorage.getItem("customerdn") + '  ' +
      localstorage.getItem("customername") + '      ' + 
      '(v.'+
      localstorage.getItem("aedesktopversion") + ')  ' ;
    // + '  ' + localstorage.getItem("processwindows"); 

  }


  ngOnInit() {
    //  this.currentTheme = this.themeService.currentTheme;
    this.themeService.changeTheme(this.currentTheme);
    /*
        this.userService.getUsers()
          .pipe(takeUntil(this.destroy$))
          .subscribe((users: any) => this.user = users.nick);
    */
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  // navigateHome() {
  //   this.menuService.navigateHome();
  //   return false;
  // }

  navigateHome() {
    this.localstorage.setItem('exitindicator','true')
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }
}
