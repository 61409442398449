import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot} from '@angular/router';
import { AewentrytypeService } from './aewentrytype_service.service';

@Injectable({
    providedIn: 'root'
})


export class AewentrytypeResolverService implements Resolve<any> {
    
    constructor(
        private entrytypeApi: AewentrytypeService,
    ) { }

    resolve(route: ActivatedRouteSnapshot): any {
        var parameters = route.params['aewalletparam'];
        console.log('entrytype parameters ' + parameters)
        var isadmin = parameters.split(',',3)[1];
        return this.entrytypeApi.getAewtypes(isadmin);
    }
}