import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ThemeModule } from '../../@theme/theme.module';
import { CoreModule } from '../../@core/core.module';

import { ReactiveFormsModule } from '@angular/forms';

import {ChartModule} from 'primeng/chart';
import { NgAutonumericModule } from '@angularfy/ng-autonumeric';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import {ToastModule} from 'primeng/components/toast/toast';

import { 
  AcdcNotificationsModule, 
  AcdcHorizontalAlignment,
  AcdcVerticalAlignment,
  AcdcNotifcationsDefaultConfig 
} from 'acdc-notifications';
import { TooltipModule , TooltipOptions } from 'ng2-tooltip-directive';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';


import {
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbCardModule,
  NbWindowModule,
  NbSelectModule,
  NbInputModule,
  NbButtonModule,
  NbCheckboxModule,
} from '@nebular/theme';

import { NgSelectModule } from '@ng-select/ng-select';

const tooltipOptions: TooltipOptions = {
  'show-delay': 200,
  'theme':'light',
  'animation-duration' : 1000,
  'placement' : 'right' ,
  'offset': 10,
 }


const notificationsConfig: AcdcNotifcationsDefaultConfig = {
  // toast configs go here (planning to add in package other notification types)
  toast:{
    // general properties applied to all toast notification level types (info, error, warn, success)
 
    // new toast goes on the top of existing ones or to the bottom
    addToTop: true, 
    // toasts horizontal alignment (Left, Center, Right)
    horizontalAlignment: AcdcHorizontalAlignment.Right, 
    // toasts vertical alignment (Top, Bottom)
    verticalAlignment: AcdcVerticalAlignment.Top, 
    // comma separated css animation names. Predefined or custom css animation names can be used
    // Predefined animations:
    // acdcFadeOutAnimation, acdcFadeInAnimation, acdcSlideInLeftAnimation, acdcSlideOutRightAnimation
    // acdcSlideOutLeftAnimation, acdcSlideInRightAnimation, acdcSlideInTopAnimation, acdcSlideInBottomAnimation
    // acdcSlideOutTopAnimation, acdcSlideOutBottomAnimation
    createAnimations: 'acdcFadeInAnimation, acdcSlideInTopAnimation', 
    dismissAnimations: 'acdcFadeOutAnimation, acdcSlideOutRightAnimation', 
    // toasts background opacity (decimal number from 0 to 1).
    backgroundOpacity: 1,
    // toasts minimal count to show delete all toasts button
    minCntToShowDeleteAllBtn: 2, 
    // delete all toasts button background color ( pass value 'none' if no background needed)
    deleteAllBtnBackgroundColor: 'red', 
    // delete all toasts button icon color
    deleteAllBtnIconColor: 'white', 
    // toast container z-index
    zIndex: '1000000001', 
    // toast width
    width: '500px', 
    // toast title font size
    titleFontSize: '14px', 
    // toast message font size
    messageFontSize: '14px', 
    // toast title font family
    titleFontFamily: 'Verdana', 
    // toast message font family
    messageFontFamily: 'Arial', 
    
    // toast title color
    titleColor: 'white', 
    // toast message color
    messageColor: 'white', 
    // toast background color
    backgroundColor: 'white', 
    // toast icons color
    iconsColor: 'white', 
    // properties specific to notification level (have higher priority than general properties)
    warn: {
      titleColor: 'white',
      messageColor: 'white',
      backgroundColor: 'orange',
      iconsColor: 'white'
    },
    info: {
      titleColor: 'white',
      messageColor: 'white',
      backgroundColor: 'steelblue',
      iconsColor: 'white'
    },
    error: {
      titleColor: 'white',
      messageColor: 'white',
      backgroundColor: 'firebrick',
      iconsColor: 'white'
    },
    success: {
      titleColor: 'white',
      messageColor: 'white',
      backgroundColor: 'seagreen',
      iconsColor: 'white'
    }
  }
};


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,

    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),


     ThemeModule.forRoot(),
    CoreModule.forRoot(),

    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbCardModule,
    NbSelectModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    ChartModule,
    NgAutonumericModule,
    Ng2SmartTableModule,
    ToastModule,
    AcdcNotificationsModule.forRoot(notificationsConfig),
    TooltipModule.forRoot(tooltipOptions as TooltipOptions),
    NgSelectModule,
    AngularDateTimePickerModule,
    
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    ThemeModule,
    CoreModule,
    NbSidebarModule,
    NbMenuModule,
    NbDialogModule,
    NbWindowModule,
    NbToastrModule,
    NbCardModule,
    NbSelectModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    ChartModule,
    NgAutonumericModule,
    Ng2SmartTableModule,
    ToastModule,
    AcdcNotificationsModule,
    TooltipModule,
    NgSelectModule,
    AngularDateTimePickerModule,
  ]
})
export class AesharedModule { }
