import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Aewentrytype } from './aewentrytype_class_record';
import { Aewentrytypemodel } from './aewentrytype_class_model';
import { Aewtype} from './aewentrytype_class_aewtype';
import { environment } from '../../../environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const serviceUrl = `${environment.apiServiceUrl}/aewentrytype`;
const typeserviceUrl = `${environment.apiServiceUrl}/aewtype`;

@Injectable({
  providedIn: 'root'
})
export class AewentrytypeService {

  constructor(private http: HttpClient) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getAewtypes (isadmin: number): Observable<Aewtype[]> {
    const url = `${typeserviceUrl}/${isadmin}`;
    return this.http.get<Aewtype[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aewtype')),
        catchError(this.handleError('Aewtype', []))
      );
  }

  getAewentrytypes (): Observable<Aewentrytype[]> {
    const url = `${serviceUrl}`;
    return this.http.get<Aewentrytype[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aewentrytypes')),
        catchError(this.handleError('getAewentrytypes', []))
      );
  }

 

  getAewentrytype(id: number): Observable<Aewentrytype> {
    const url = `${serviceUrl}/${id}`;
    return this.http.get<Aewentrytype>(url, httpOptions).pipe(
      tap(_ => console.log(`fetched Aewentrytype id=${id}`)),
      catchError(this.handleError<Aewentrytype>(`getAewentrytype id=${id}`))
    );
  }

  addAewentrytype (aewentrytypemodel: Aewentrytypemodel): Observable<Aewentrytype> {
    const url = `${serviceUrl}`;
    return this.http.post<Aewentrytypemodel>(url, aewentrytypemodel, httpOptions).pipe(
      tap((aewentrytypeResp: Aewentrytype) => console.log(`added Aewentrytype`)),
      catchError(this.handleError<Aewentrytype>('addAewentrytype'))
    );
  }

  updateAewentrytype (id: number, aewentrytype: any): Observable<any> {
    const url = `${serviceUrl}/${id}`;
    return this.http.put(url, aewentrytype, httpOptions).pipe(
      tap(_ => console.log(`updated Aewentrytype id=${id}`)),
      catchError(this.handleError<any>('updateAewentrytype'))
    );
  }

  deleteAewentrytype (id: number): Observable<Aewentrytype> {
    const url = `${serviceUrl}/${id}`;
    return this.http.delete<Aewentrytype>(url, httpOptions).pipe(
      tap(_ => console.log(`deleted Aewentrytype id=${id}`)),
      catchError(this.handleError<Aewentrytype>('deleteAewentrytype'))
    );
  }
}
