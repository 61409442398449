
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

@Component({
  template: `<a href="javascript:void(0)" (click)="toggleShipping()">
  <i *ngIf="!chkOri" class="ion-ios-arrow-left"></i>
  <i *ngIf="chkOri" class="ion-ios-checkmark-outline"></i></a>`,
})

export class ReservedBonusRenderComponent implements ViewCell,  OnInit {

  @Input() value: number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();
  
  public
  chk: boolean;
  chkOri: boolean;
  constructor(private http: HttpClient,)  {  }

  ngOnInit() {
    if (this.value == 0) {this.chk = false; this.chkOri = false;} else {this.chk = true; this.chkOri = true}
    console.log(this.chk);
  }

  toggleShipping(){
    this.chkOri =  this.chk;
  }

}