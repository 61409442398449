import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Aeupline } from './aeuplineshow_classupline'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { Aeciclokeyvalue } from '../aeshared/aeciclokeyvalue';


@Component({
  selector: 'ngx-aeuplineshowuser',
  templateUrl: './aeuplineshowuser.component.html',
  styleUrls: ['./aeuplineshowuser.component.scss']
})

export class AeuplineshowuserComponent implements OnInit {

  data: Aeupline[] = [];
  isLoadingResults = true;
  aeupline: Aeupline[];
  customerDn: string;
  ciclosId: string;
  sponsorDn: string = undefined;
  uplineSponsorDn: string;

  aecycles: Aeciclokeyvalue[];
  selectedCycle: string;
  selectedCycleChanged = true;
 
  constructor(
    // private api: AeuplineshowService,
    private router: Router,  private route: ActivatedRoute,
    private localstorage: LocalStorageService,
  ) { }

  ngOnInit() {
    var localciclo = this.localstorage.getItem('aecicloorganization')
    this.aecycles = this.route.snapshot.data['aecicloRes'];
    if (localciclo == null) { this.selectedCycle = this.aecycles[0].key; }
    else { this.selectedCycle = localciclo; }
    this.localstorage.setItem('aecicloorganization', this.selectedCycle)
    this.uplineSponsorDn = this.localstorage.getItem("uplinesponsordn");
    this.customerDn = this.localstorage.getItem("customerdn");
    const url = '/aeuplineshow/' + `${this.customerDn},${this.selectedCycle}`;
    this.router.navigate([url], {
      queryParams: { refresh: new Date().getTime() }
    });
  }
 

}
