import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TreeModule } from 'angular-tree-component';
import { AedownlineComponent } from './aedownline.component';
import { AeasyncdownlineComponent } from './aeasyncdownline.component';
import { AefulldownlineComponent } from './aefulldownline.component';
@NgModule({
  declarations: [
    AeasyncdownlineComponent,
    AefulldownlineComponent,
    AedownlineComponent, 
  ],
  imports: [
    BrowserModule,
    FormsModule,
    TreeModule,
    CommonModule,
  ],
  providers: [],
})
export class AedownlineModule {}
