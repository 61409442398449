export const AeWalletCustomerTableSettings = {
  actions: {
    columnTitle: '',
    delete: false,
    add: false,
  },
  hideHeader: true,
  noDataMessage: '',
  pager: {
    display: true,
    perPage: 20,
  },

  mode: 'external',
  edit: {
    editButtonContent: '<i class="ion-ios-eye-outline"></i>',
  },
  columns: {
    customerDn: {
      title: 'Customer DN',
      width: '20%'
    },
    customerName: {
      title: 'Name',
      width: '50%'
    },
    walletBalance: {
      title: 'Balance',
      width: '20%'
    },
  },
};
