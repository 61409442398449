import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AesharedModule } from '../aeshared/aeshared.module';

import { Aewentrytype_Crud_Component } from '../aewentrytype/aewentrytype_crud.component';
import { AewentrytypeComponent } from '../aewentrytype/aewentrytype.component';
import { Aewallet_Crud_Component } from './aewallet_crud.component';
import { Aewallet_Creditlist_Component } from './aewallet_creditlist.component';
import { Aewallet_View_Component } from './aewallet_view.component';
import { AewalletComponent } from './aewallet.component';
import { AewallettransferComponent } from './aewallettransfer.component';

@NgModule({
  declarations: [
    Aewentrytype_Crud_Component,
    AewentrytypeComponent,
    Aewallet_Crud_Component,
    Aewallet_Creditlist_Component,    
    Aewallet_View_Component,
    AewalletComponent,
    AewallettransferComponent,
  ],
  imports: [
    CommonModule,
    AesharedModule,
  ]
})
export class AewalletsModule { }
