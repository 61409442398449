import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Aeorganizationtree } from './aetreeshow_class_organizationtree'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { Aeciclokeyvalue } from '../aeshared/aeciclokeyvalue';


@Component({
  selector: 'ngx-aetreeshowuser',
  templateUrl: './aetreeshowuser.component.html',
  styleUrls: ['./aetreeshowuser.component.scss']
})

export class AetreeshowuserComponent implements OnInit {

  data: Aeorganizationtree[] = [];
  isLoadingResults = true;
  aeorganizationtree: Aeorganizationtree[];
  customerDn: string;
  ciclosId: string;
  sponsorDn: string = undefined;
  treeSponsorDn: string;
  withVolume: string = 'n';

  aecycles: Aeciclokeyvalue[];
  selectedCycle: string;
  selectedCycleChanged = true;
 
  constructor(
    // private api: AetreeshowService,
    private router: Router,  private route: ActivatedRoute,
    private localstorage: LocalStorageService,
  ) { }

  ngOnInit() {
    var localciclo = this.localstorage.getItem('aecicloorganization')
    this.aecycles = this.route.snapshot.data['aecicloRes'];
    if (localciclo == null) { this.selectedCycle = this.aecycles[0].key; }
    else { this.selectedCycle = localciclo; }
    this.localstorage.setItem('aecicloorganization', this.selectedCycle)
    this.treeSponsorDn = this.localstorage.getItem("treesponsordn");
    this.customerDn = this.localstorage.getItem("customerdn");
    const url = '/aetreeshow/' + `${this.customerDn},${this.selectedCycle},${this.withVolume},n`;
    this.router.navigate([url], {
      queryParams: { refresh: new Date().getTime() }
    });
  }
 

}
