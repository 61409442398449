import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'ngx-development',
  styleUrls: ['./development.component.scss'],
  templateUrl: './development.component.html',
})
export class DevelopmentComponent {
  constructor(
  private router: Router) {}

  gotoDashboard() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }
}
