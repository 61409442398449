import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';

import 'rxjs/add/operator/toPromise';

import { Aestateprovincekeyvalue } from './aestateprovincekeyvalue';
import { Aestateprovincecountry } from './aestateprovincecountry';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const aestateserviceUrl = `${environment.apiServiceUrl}/aestateprovince`;

@Injectable({
  providedIn: 'root'
})
export class AestateprovincekeyvalueService {
//  private url = 'api/orderRequests';

  aestatesJson: Aestateprovincekeyvalue[];
  aestates = [];
  aestatescountryJson: Aestateprovincecountry[];
  aestatescountry = [];

  constructor(private http: HttpClient) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  getAestates(countryid: number): Observable<Aestateprovincekeyvalue[]> {
    const url = `${aestateserviceUrl}/${countryid}`;
    return this.http.get<Aestateprovincekeyvalue[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aestates')),
        catchError(this.handleError('getaestates', []))
      );
  }

  getAestatesData(countryid:number): any {
    return this.getAestates(countryid).subscribe((data: Aestateprovincekeyvalue[]) => {
      data.forEach(element => { this.aestates.push(element); });
    })
  }

  getAestatescountry(): Observable<Aestateprovincecountry[]> {
    const url = `${aestateserviceUrl}`;
    return this.http.get<Aestateprovincecountry[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aestatescountry')),
        catchError(this.handleError('getaestatescountry', []))
      );
  }

  getAestatescountryData(): any {
    return this.getAestatescountry().subscribe((data: Aestateprovincecountry[]) => {
      data.forEach(element => { this.aestatescountry.push(element); });
    })
  }
}
