import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
    selector: 'ngx-bonusrender',
    template: `<button (click)="onClick()" style="border: none">
    <img src="../../../assets/images/viewcolor.jpg"></button>`,
    })

    // template: `<a href="javascript:void(0)" (click)="onClick()">
    // <img src="../../../assets/images/delete.jpg"></a>`,

    export class OptionRenderComponent implements OnInit {
        public renderValue: string;
    
        @Input() value: string | number;
        @Input() rowData: any;
      
        @Output() save: EventEmitter<any> = new EventEmitter();

        constructor() {
        }
    
        ngOnInit() {
            this.renderValue = this.value.toString().toUpperCase();
        }
    
        onClick() {
            var response = {
                id: this.rowData.id,
                codigoBono:this.rowData.codigoBono,
                asignados:this.rowData.asignados,
                cupones: this.rowData.cupones,
             }
            this.save.emit(response);
        }
        
    }