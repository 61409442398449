import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AewarehouseService } from '../aeshared/aewarehouse.service'
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';

@Injectable({
    providedIn: 'root'
})


export class AepickupcentersassignedResolverService implements Resolve<any> {
    customerDn = "";
    constructor(
        private warehousesApi: AewarehouseService,
        private localstorage: LocalStorageService,
    ) {
        this.customerDn = localstorage.getItem("customerdn");

    }

    resolve(): any {
        return this.warehousesApi.getAedistributioncentersassigned(false,0);
    }
}