import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AesharedModule } from '../aeshared/aeshared.module';

import { AebonusComponent } from './aebonus.component';
import { AebonususerComponent } from './aebonususer.component';


@NgModule({
  declarations: [
    AebonusComponent,
    AebonususerComponent,
  ],
  imports: [
    CommonModule,
    AesharedModule,
  ],
  exports: [
    AebonusComponent,
    AebonususerComponent,
  ],
})
export class AebonusModule { }
