import { Component, AfterViewInit, OnInit } from '@angular/core';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { AestoreService } from './aestore.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Aestoreproductslist } from './aestore_class_productslist';
import { Aestoreproductsmodel } from './aestore_class_productsmodel';
import { Router, ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ImageRenderComponent } from './aestore_render_imagerender.component';
import { AcdcNotificationsService } from 'acdc-notifications';

const storeproductsserviceUrl = `${environment.apiServiceUrl}/aestoreproducts`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

@Component({
  selector: 'ngx-aestore',
  templateUrl: './aestore.component.html',
  styleUrls: ['./aestore.component.scss']
})
export class AestoreComponent implements AfterViewInit {
  customerDn = '';
  isLoadingResults = true;
  aestoreproducts: Aestoreproductslist[] = undefined;
  recompute: boolean = false;
  showaddproducts: boolean = false;

  productValidationMessage = '';

  labelContent = '';

  storeproductssettings = {
    actions: {
      columnTitle: '',
      delete: false,
      add: false,
      edit: true,
    },
    pager: {
      display: true,
      perPage: 200,
    },

    mode: 'inline',

    hideHeader: true,

    noDataMessage: '',

    edit: {
      editButtonContent: '<i class="ion-ios-plus-outline"></i>',
      saveButtonContent: '<i class="nb-checkmark-circle"></i>',
      cancelButtonContent: '<i class="nb-close-circled"></i>',
      confirmSave: true,
    },
    columns: {
      sku: {
        title: 'Sku',
        width: '10%',
        editable: false,
      },
      categoryName: {
        title: 'Category',
        width: '30%',
        editable: false,
      },
      productImg: {
        title: 'Image',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: ImageRenderComponent,
      },
      productName: {
        title: 'Product',
        width: '30%',
        editable: false,
      },
      productPrice: {
        title: 'Price',
        width: '5%',
      },
      id: {
        title: 'Serial',
        width: '5%',
        editable: false,
      },
    },
  };

  constructor(private api: AestoreService, private localstorage: LocalStorageService,
    private router: Router, private http: HttpClient,
    private route: ActivatedRoute,
    private acdcNotificationsService: AcdcNotificationsService,
  ) {
    this.customerDn = localstorage.getItem("customerdn");
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.showstoreTable(1);
  }

  showstoreTable(bshow: number) {
    if (bshow ==1) {
      this.labelContent = 'Selecting Products'
      this.showaddproducts = true;


      const acdcid: string = this.acdcNotificationsService.toast({
        title: 'Please wait... Loading Product data',
        timeout: 60000,
        notificationLevel: 'Info'
      });
      this.api.getAestoreproducts(this.customerDn)
        .subscribe((data: Aestoreproductslist[]) => {
          this.aestoreproducts = data;
          this.acdcNotificationsService.deleteToast(acdcid);
          if (this.aestoreproducts.length == 0) {
            this.acdcNotificationsService.toast({
              title: 'Warning... No Product data',
              timeout: 3000,
              notificationLevel: 'Warn'

            });
          }
        });
    }
  }

  onSaveConfirm(event) {
    var data = {
      "customerDn": this.customerDn,
      "productId": event.newData.id,
      "productPrice": parseFloat(event.newData.productPrice.replace(',', ''))
    };
    var oldPrice = parseInt(event.data.productPrice)

    if (this.validateProduct(data)) {
      console.log(data);
      this.http.post<number>(storeproductsserviceUrl, data, httpOptions).subscribe(
        res => {
          console.log(res);
          event.confirm.resolve(event.newData);
          this.acdcNotificationsService.toast({
            title: 'Updated...' + event.newData.productQuantity + ' ' + event.newData.productName,
            timeout: 3000,
            notificationLevel: 'Success'
          });

          let prdind = this.aestoreproducts.findIndex(x => x.id === event.newData.id)
          //
          this.showstoreTable(1);
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("Client-side error occured.");
            this.acdcNotificationsService.toast({
              title: 'Client-side error occured.',
              timeout: 3000,
              notificationLevel: 'Error'
            });
          } else {
            console.log("Server-side error occured.");
            this.acdcNotificationsService.toast({
              title: 'Server-side error occured.',
              timeout: 3000,
              notificationLevel: 'Error'
            });
          }
        });
    } else {
      this.acdcNotificationsService.toast({
        title: this.productValidationMessage,
        timeout: 3000,
        notificationLevel: 'Error'
      });
      event.confirm.reject();
    }
  }



  validateProduct(data: Aestoreproductsmodel): boolean {
    var validProduct = true
    if (data.productPrice < 0) {
      validProduct = false;
      this.productValidationMessage = 'Price must be greater or equal to 0';
    }
    return validProduct;
  }

  ordersBack() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  addtoCart() {
    this.showstoreTable(1);
  }
}

