import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AeformcantidadModel } from './aeformcantidad.model';
import { LocalStorageService } from '../../aeshared/aeshared_service_localstorage.service';


@Component({
  selector: 'ngx-aeformcantidad',
  templateUrl: './aeformcantidad.component.html',
  styleUrls: ['./aeformcantidad.component.scss']
})
export class AeformcantidadComponent implements OnInit {

  static id = 100;

  aebonusForm: FormGroup;
  mode = 'create';
  bonosprevios = "0";

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
              private dialogRef: MatDialogRef<AeformcantidadComponent>,
              private localstorage: LocalStorageService,
              private fb: FormBuilder) {
  }
 

  ngOnInit() {
    this.defaults = {} as AeformcantidadModel;
    this.aebonusForm = this.fb.group({
      cantidad: this.fb.control(1, [Validators.required, Validators.min(1), Validators.max(1)]),
    });
    this.bonosprevios = this.localstorage.getItem("bonusplusprevios");
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave() {
    const bono = this.aebonusForm.value;
    this.dialogRef.close(bono);
  }


 }
