import { Component, OnInit, AfterViewInit, ViewChild, OnChanges } from '@angular/core';
import { AcdcNotificationsService } from 'acdc-notifications';
import { Aeshippingservice } from './aeshipping_service_data.service';
import { SelectedRenderComponent } from './aeshipping_render_selected.component';
import { Aeshippingexcludelist } from './aeshipping_class_excludelist';
import { AeexcludeRenderComponent } from './aeshipping_render_exclude.component';
import { CsvExport } from '../aeshared/aeshared_class_csvexport';
import { Aeinvoicelist } from './aeshipping_class_aeinvoicelist';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';

const shippingserviceUrl = `${environment.apiServiceUrl}/aeshippingorder`;
const shippingregisterUrl = `${environment.apiServiceUrl}/aeshippingregister`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};


@Component({
  selector: 'ngx-aeshipping',
  templateUrl: 'aeshipping.component.html',
  styleUrls: ['aeshipping.component.css'],
})
export class AeshippingComponent implements OnInit, AfterViewInit {

  showinvoice: boolean = true;
  showexclude: boolean = false;
  showstatus: boolean = false;
  showinvoices: boolean = true;
  showmiamiinvoices: boolean = false;

  shipping: string = undefined;
  showshipping: boolean = false;
  aeinvoices: Aeinvoicelist[];
  aemiamiinvoices: Aeinvoicelist[];
  aeshippingexclude: Aeshippingexcludelist[];
  public chk: boolean = false;
  chkVerify: boolean = false;
  selectedWarehouse = undefined;
  selectedCategory = 1;
  selectedStatus = 1;
  selectedCountry = 1;
  currentOperator: string = '';
  aewarehouses = [];
  aewarecategories = [
    { key: 1, value: 'All' },
    { key: 2, value: 'C' },
    { key: 3, value: 'N' },
    { key: 4, value: 'N C' },
    { key: 5, value: 'X' },
    { key: 6, value: 'X C' },
  ];

  aeshippingstatus = [
    { key: 1, value: 'All' },
    { key: 2, value: 'Sent' },
    { key: 3, value: 'New' },
    { key: 4, value: 'Partial' },
    { key: 5, value: 'sUmmit' },
    { key: 6, value: 'pRinted' },
  ];

  aeinvoicesettings = {

    actions: {
      columnTitle: '',
      delete: false,
      add: false,
      edit: false,
    },
    pager: {
      display: false,
    },

    mode: 'external',
    hideHeader: true,
    noDataMessage: '',

    columns: {
      checked: {
        title: 'Prc',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: SelectedRenderComponent,
        valuePrepareFunction: (cell, row) => {
          return cell;
        },
        onComponentInitFunction: (instance) => {
        },
      },
      listOrder: {
        title: '#',
        width: '5%'
      },
      customerDn: {
        title: 'DN',
        width: '5%'
      },
      customerName: {
        title: 'Distributor',
        width: '35%'
      },
      orderNumber: {
        title: 'Invoice',
        width: '5%'
      },
      product: {
        title: 'Product',
        width: '5%'
      },
      invoiceDate: {
        title: 'Inv.Date',
        width: '5%'
      },
      orderDate: {
        title: 'Ord.Date',
        width: '5%'
      },
      isPartialOrder: {
        title: 'P',
        width: '5%'
      },
      shippingAddress: {
        title: 'Address',
        width: '35%'
      },
      dcenter: {
        title: 'Warehouse',
        width: '10%'
      },
      totalAmount: {
        title: 'Amount',
        width: '10%'
      },
      invoiceCategory: {
        title: 'Category',
        width: '5%',
        filter: false
      },
      invoiceStatus: {
        title: 'Status',
        width: '5%',
      },
      v: {
        title: 'V',
        width: '5%',
        filter: false
      },
    },
  };

  aemiamiinvoicesettings = {

    actions: {
      columnTitle: '',
      delete: false,
      add: false,
      edit: false,
    },
    pager: {
      display: false,
    },

    mode: 'external',
    hideHeader: true,
    noDataMessage: '',

    columns: {
      listOrder: {
        title: '#',
        width: '5%'
      },
      customerDn: {
        title: 'DN',
        width: '5%'
      },
      customerName: {
        title: 'Distributor',
        width: '35%'
      },
      orderNumber: {
        title: 'Invoice',
        width: '5%'
      },
      product: {
        title: 'Product',
        width: '5%'
      },
      invoiceDate: {
        title: 'Inv.Date',
        width: '5%'
      },
      orderDate: {
        title: 'Ord.Date',
        width: '5%'
      },
      isPartialOrder: {
        title: 'P',
        width: '5%'
      },      
      shippingAddress: {
        title: 'Address',
        width: '35%'
      },
      dcenter: {
        title: 'Warehouse',
        width: '10%'
      },
      totalAmount: {
        title: 'Amount',
        width: '10%'
      },
      invoiceCategory: {
        title: 'Category',
        width: '5%',
        filter: false
      },
      invoiceStatus: {
        title: 'Status',
        width: '5%',
      },
      v: {
        title: 'V',
        width: '5%',
        filter: false
      },
    },
  };

  aeexcludesettings = {
    actions: {
      position: 'right',
      columnTitle: '',
      delete: false,
      add: false,
      edit: false,
    },
    pager: {
      display: false,
    },
    mode: 'external',
    hideHeader: true,
    noDataMessage: '',
    columns: {
      checked: {
        title: 'Exclude',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: AeexcludeRenderComponent,
        valuePrepareFunction: (cell, row) => {
          return cell;
        },
        onComponentInitFunction: (instance) => {
        },
      },
      sku: {
        title: 'Sku',
        width: '10%'
      },
      productName: {
        title: 'Product',
        width: '35%'
      },
      id: {
        title: 'Serial',
        width: '35%'
      },
    },
  };


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private acdcNotificationsService: AcdcNotificationsService,
    private shippingapi: Aeshippingservice, private http: HttpClient,
    private localstorage: LocalStorageService,
  ) {
    this.currentOperator = this.localstorage.getItem("current");

  }

  ngOnInit(): void {
    this.aewarehouses = this.route.snapshot.data['aewarehouseshippingRes'];
    this.aeshippingexclude = this.route.snapshot.data['aeshippingexcludeRes'];
    // this.getInvoiceData(1);
    // console.log('invoices ' + this.aeinvoices);
  }

  ngAfterViewInit() {

  }

  getInvoiceData(cleanOption: number) {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Invoice data',
      timeout: 600000,
      notificationLevel: 'Info'
    });
    if (cleanOption > 0) { this.chk = false; }
    this.shippingapi.getAeinvoices(this.selectedWarehouse, this.selectedCategory, this.selectedStatus, this.chkVerify, cleanOption)
      .subscribe((data: Aeinvoicelist[]) => {
        this.aeinvoices = data;
        this.acdcNotificationsService.deleteToast(acdcid);
        if (this.aeinvoices.length == 0) {
          this.acdcNotificationsService.toast({
            title: 'No Invoices match criteria',
            timeout: 3000,
            notificationLevel: 'Warn'
          });

        }
      });

  }

  getMiamiInvoiceData() {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Miami Invoice data',
      timeout: 600000,
      notificationLevel: 'Info'
    });
    this.shippingapi.getAemiamiinvoices()
      .subscribe((data: Aeinvoicelist[]) => {
        this.aemiamiinvoices = data;
        this.acdcNotificationsService.deleteToast(acdcid);
        if (this.aemiamiinvoices.length == 0) {
          this.acdcNotificationsService.toast({
            title: 'No Miami Invoices data',
            timeout: 3000,
            notificationLevel: 'Warn'
          });
        }
      });

  }


  getExcludedData() {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Excluded Products',
      timeout: 60000,
      notificationLevel: 'Info'
    });
    this.shippingapi.getAeexclude(this.selectedWarehouse)
      .subscribe((data: Aeshippingexcludelist[]) => {
        this.aeshippingexclude = data;
        this.acdcNotificationsService.deleteToast(acdcid);
      });
  }

  invoiceback() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  shippingRegister() {
    if (this.selectedWarehouse != 1004) {
      const acdcid: string = this.acdcNotificationsService.toast({
        title: 'Please wait... Processing selected Orders',
        timeout: 7200000,
        notificationLevel: 'Info'
      });

      var data = {
        "warehouseId": this.selectedWarehouse,
        "orderNumber": "00000",
        "currentOperator": this.currentOperator
      };
      this.http.post<number>(shippingregisterUrl, data, httpOptions).subscribe(
        res => {
          console.log('Result ' + res);
          this.acdcNotificationsService.deleteToast(acdcid);
          this.getInvoiceData(1);
        });
    }
    else {
      this.acdcNotificationsService.toast({
        title: 'Master Distributor invoices must be reassigned ',
        timeout: 3000,
        notificationLevel: 'Error'
      });

    }
  }

  selectAll() {
    if (this.chk) { this.chk = false; } else { this.chk = true; }
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Checking/Unchecking selected records',
      timeout: 600000,
      notificationLevel: 'Info'
    });
    var data = {
      "orderNumber": "00000",
      "productId": 0,
      "checked": this.chk,
      "countryId": this.selectedCountry,
      "warehouseId": this.selectedWarehouse,
      "categoryId": this.selectedCategory,
      "statusId": this.selectedStatus,
      "verify": this.chkVerify
    };

    this.http.post<number>(shippingserviceUrl, data, httpOptions).subscribe(
      res => {
        console.log('Result ' + res);
        this.acdcNotificationsService.deleteToast(acdcid);
        this.getInvoiceData(0);
      });

  }

  csvDownload() {
    let today = new Date().toISOString().slice(0, 10)
    let csvfile = 'ShippingSummary_' + today + '.csv';
    CsvExport.exportToCsv(csvfile, this.aeinvoices);
  }

  csvMiamiDownload() {
    let today = new Date().toISOString().slice(0, 10)
    let csvfile = 'MiamiShippingSummary_' + today + '.csv';
    CsvExport.exportToCsv(csvfile, this.aemiamiinvoices);
    this.showmiamiinvoices = false;
    this.showinvoices = true;
  }

  showMiamiInvoices() {
    this.showinvoices = false;
    this.showmiamiinvoices = true;
    this.getMiamiInvoiceData();
  }

  invoiceEditDialog(event) {

  }
  onWarehouseChange(event) {
    this.aeinvoices = null;
    this.selectedWarehouse = event.id;
    this.selectedCountry = event.countryId;
    this.selectedCategory = 1;
    this.getExcludedData();
    this.getInvoiceData(1);
  }

  onCategoryChange(event) {
    this.aeinvoices = null;
    this.selectedCategory = event.key;
    this.getInvoiceData(2);
  }

  onStatusChange(event) {
    this.aeinvoices = null;
    this.selectedStatus = event.key;
    this.getInvoiceData(0);
  }

  excludeback() {
    this.showinvoice = true;
    this.showexclude = false;
    this.getExcludedData();
    this.getInvoiceData(1);
  }

  onVerify(event) {
    this.chkVerify = event.target.checked;
    this.getInvoiceData(2);
  }

  showExclude() { this.showinvoice = false; this.showexclude = true; }
}

