import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import { AecustomeraddressService } from './aecustomeraddress.service';


@Injectable({
    providedIn: 'root'
})


export class AepickupaddressResolverService implements Resolve<any> {

    constructor(
        private customeraddressApi: AecustomeraddressService,
    ) {

    }

    resolve(): any {
        return this.customeraddressApi.getAepickupaddress();
    }



}