import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Aewalletlist } from './aewallet_class_list';
import { Aewalletrecord } from './aewallet_class_record';
import { Aewalletmodel } from './aewallet_class_model';
import { environment } from '../../../environments/environment';
import { Aewalletbalance } from './aewallet_class_balance';
import { Aewalletinvalidtransfer} from './aewallet_class_invalidtransfer';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const walletlistserviceUrl = `${environment.apiServiceUrl}/aewalletlist`;
const walletrecordserviceUrl = `${environment.apiServiceUrl}/aewalletrecord`;
const walletbalanceserviceUrl = `${environment.apiServiceUrl}/aewbalance`;
const walletinvalidtransferserviceUrl = `${environment.apiServiceUrl}/aewalletinvalidtransfer`;
@Injectable({
  providedIn: 'root'
})
export class AewalletService {

  constructor(private http: HttpClient) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      return of(result as T);
    };
  }

  getAewallets (customerdn: string, ciclosid: string): Observable<Aewalletlist[]> {
    const url = `${walletlistserviceUrl}/${customerdn},${ciclosid},n`;
    return this.http.get<Aewalletlist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aewallet')),
        catchError(this.handleError('getAewallet', []))
      );
  }

  getAewalletsCredit (customerdn: string, ciclosid: string): Observable<Aewalletlist[]> {
    const url = `${walletlistserviceUrl}/${customerdn},${ciclosid},y`;
    return this.http.get<Aewalletlist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aewalletcreditlist')),
        catchError(this.handleError('getAewalletcreditlist', []))
      );
  }

   getAewallet(id: number): Observable<Aewalletrecord> {
     const url = `${walletrecordserviceUrl}/${id}`;
     return this.http.get<Aewalletrecord>(url, httpOptions).pipe(
       tap(_ => console.log(`fetched Aewalletrecord id=${id}`)),
       catchError(this.handleError<Aewalletrecord>(`getAewallet id=${id}`))
     );
   }

   getAewalletBalance(customerdn: any): Observable<any> {
    const url = `${walletbalanceserviceUrl}/${customerdn}`;
    return this.http.get<Aewalletbalance>(url, httpOptions).pipe(
      tap(_ => console.log(`fetched balance customerdn=${customerdn}`)),
      catchError(this.handleError<Aewalletrecord>(`getAewallet id=${customerdn}`))
    );
  }

   addAewallet (aewalletmodel: Aewalletmodel): Observable<Aewalletrecord> {
     const url = `${walletrecordserviceUrl}`;
     return this.http.post<Aewalletmodel>(url, aewalletmodel, httpOptions).pipe(
       tap((aewalletRes: Aewalletrecord) => console.log(`added Aewalletrecord`)),
       catchError(this.handleError<Aewalletrecord>('addAewallet'))
     );

   }

   updateAewallet (id: number, aewallet: any): Observable<any> {
     const url = `${walletrecordserviceUrl}/${id}`;
     return this.http.put(url, aewallet, httpOptions).pipe(
       tap(_ => console.log(`updated Aewallet id=${id}`)),
       catchError(this.handleError<any>('updateAewallet'))
     );
   }

   deleteAewallet (id: number) {
     const url = `${walletrecordserviceUrl}/${id}`;
     return this.http.delete(url,httpOptions)
   }

   getInvalidtransfer(customerDn: string, originDn: string): Observable<Aewalletinvalidtransfer> {
    const url = `${walletinvalidtransferserviceUrl}/${customerDn},${originDn}`;
    console.log('url ' + url);
    return this.http.get<Aewalletinvalidtransfer>(url, httpOptions);
  }
}
