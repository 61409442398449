export class Aewalletrecord {
    id: number;
    ciclosId: string;
    customerDn: string;
    originDn: string;
    entryType: number;
    entryAmount: number;
    entryNote: string;
    createUser: string;
}
