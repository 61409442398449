import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { AeformcantidadmesesModel } from './aeformcantidadmeses.model';
import { LocalStorageService } from '../../aeshared/aeshared_service_localstorage.service';
import { Aebonuspreviouslist } from '../../aebonusregister/aebonusregister_class_aebonuspreviouslist';
import { Aebonusregisterservice } from '../../aebonusregister/aebonusregister_service_data.service';
import { MatDialog } from '@angular/material';
import { CsvExport } from '../../aeshared/aeshared_class_csvexport';
import { AeformcantidadtotalsComponent } from '../aeformcantidadtotals/aeformcantidadtotals.component';
import { AeformcantidaddetailComponent } from '../aeformcantidaddetail/aeformcantidaddetail.component';

@Component({
  selector: 'ngx-aeformcantidadmeses',
  templateUrl: './aeformcantidadmeses.component.html',
  styleUrls: ['./aeformcantidadmeses.component.scss']
})
export class AeformcantidadmesesComponent implements OnInit {

  static id = 100;

  aebonusForm: FormGroup;
  mode = 'create';
  bonosprevios = "0";
  bonosprevios2 = "0";
  customerDn = "";
  ciclosId = "";
  showdetail = false;
  showform = true;
  customerFilter = "";
  customerLabel = "Customer";

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<AeformcantidadmesesComponent>,
    private localstorage: LocalStorageService,
    private bonusregisterapi: Aebonusregisterservice,
    public dialog: MatDialog,
    private fb: FormBuilder) {
  }


  ngOnInit() {
    this.defaults = {} as AeformcantidadmesesModel;
    this.aebonusForm = this.fb.group({
      cantidad: this.fb.control(1, [Validators.required, Validators.min(1), Validators.max(1)]),
      meses: this.fb.control(1, [Validators.required, Validators.min(1), Validators.max(2)]),
    });
    this.bonosprevios = this.localstorage.getItem("bonusplusprevios");
    this.bonosprevios2 = this.localstorage.getItem("bonusplusprevios2");
    this.customerDn = this.localstorage.getItem("bonuspluscustomerdn");
    this.ciclosId = this.localstorage.getItem("bonusplusciclosid");
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave() {
    const bono = this.aebonusForm.value;
    this.dialogRef.close(bono);
  }

  detail() {
    let dialogRef = this.dialog.open(AeformcantidaddetailComponent, {
      width: '900px',
      height: '550px',
    });
  }

  totals() {
    let dialogRef = this.dialog.open(AeformcantidadtotalsComponent, {
      width: '900px',
      height: '550px',
    });
  }




}




