
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

const shippingserviceUrl = `${environment.apiServiceUrl}/aeshippingorder`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

@Component({
  template: `
  <nb-checkbox status="primary" [ngModel]="chk"  (change)="toggleShipping($event)"></nb-checkbox>
`,
})

export class SelectedRenderComponent implements ViewCell,  OnInit {

  @Input() value: number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();
  
  public chk: boolean;
  constructor(private http: HttpClient,)  {  }

  ngOnInit() {
    if (this.value == 0) {this.chk = false;} else {this.chk = true;}
    console.log(this.chk);
  }

  toggleShipping(event){
   this.chk =  event.target.checked;
   var data = {
    "orderNumber": this.rowData.orderNumber,
    "productId": this.rowData.productId,
    "checked": this.chk
  };
  this.http.post<number>(shippingserviceUrl, data, httpOptions).subscribe(
    res => {
      console.log('Result ' + res);
      });
  }
}