import { Component, OnInit, AfterViewInit, ViewChild, OnChanges } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { Aeorganizationcustomerservice } from './aeorganization_service_customer.service';
import { Aeorganizationcustomer } from './aeorganization_class_customer';
import { AcdcNotificationsService } from 'acdc-notifications';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { CsvExport } from '../aeshared/aeshared_class_csvexport';
import { FormControl } from '@angular/forms';
import { Aeciclokeyvalue } from '../aeshared/aeciclokeyvalue';
import { AeOrganizationCustomerTableSettings } from './aeorganization_settings_customertablesettings'


@Component({
  selector: 'ngx-aeorganization',
  templateUrl: 'aeorganization.component.html',
  styleUrls: ['aeorganization.component.css'],
})
export class AeorganizationComponent implements OnInit, AfterViewInit {
  aecustomers: Aeorganizationcustomer[];
  organization: string = undefined;
  showorganization: boolean = false;
  showcustomer: boolean = true;

  aecycles: Aeciclokeyvalue[];
  selectedCycleFormControl = new FormControl();
  selectedCycle: string;
  selectedCycleChanged = true;
  selectedLabel: string;
  onlywithVolume: boolean = false;
  showBonus: boolean = false;
  withVolume: string = 'n';
  withBonus: string = 'n';
  isadmin: string = 'False'
  isoperadorcierre: string = 'False'

  aecustomertablesettings = AeOrganizationCustomerTableSettings;

  constructor(
    private router: Router,
    private customerapi: Aeorganizationcustomerservice,
    private route: ActivatedRoute,
    private acdcNotificationsService: AcdcNotificationsService,
    private localstorage: LocalStorageService,
  ) {
     this.isadmin = localstorage.getItem("isadmin");
     this.isoperadorcierre = localstorage.getItem("isoperadorcierre");
  }


  ngOnInit(): void {
    var localciclo = this.localstorage.getItem('aecicloorganization')
    this.aecycles = this.route.snapshot.data['aecicloRes'];
    if (localciclo == null) { this.selectedCycle = this.aecycles[0].key; }
    else { this.selectedCycle = localciclo; }
    this.selectedLabel = this.selectedCycle
    this.localstorage.setItem('aecicloorganization', this.selectedCycle)
  }

  ngAfterViewInit() {
    this.getCustomerData();
    console.log('customers ' + this.aecustomers);
  }

  getCustomerData() {
    this.showCustomerTable(true);
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Customers data',
      timeout: 60000,
      notificationLevel: 'Info'
    });
    this.customerapi.getAeorganizationCustomers(this.selectedCycle,this.withVolume).toPromise().then((data: Aeorganizationcustomer[]) => {
      this.aecustomers = data;
      this.acdcNotificationsService.deleteToast(acdcid);
    });

  }

  customerback() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  customerEditDialog(event) {
    this.localstorage.setItem("treesponsordn", event.data.sponsorDn);
    this.showCustomerTable(false);
    var customerDn = event.data.customerDn;
    var ciclosId = this.selectedCycle;

    console.log('aeOrganization Parameters ' + customerDn + ',' + ciclosId);
    const url = '/aetreeshow/' + `${customerDn},${ciclosId},${this.withVolume},${this.withBonus}`;
    this.router.navigate([url], {
      queryParams: { refresh: new Date().getTime() }
    });
  }

  showCustomerTable(bshow: boolean) {
    this.showcustomer = bshow;
    this.showorganization = !bshow;
  }

  back() {
    this.showCustomerTable(true);
  }

  onCycleChange(event) {
    this.selectedCycle = event.key;
    this.selectedCycleChanged = true;
    this.selectedLabel = this.selectedCycle
    this.localstorage.setItem('aecicloorganization', this.selectedCycle)
    this.getCustomerData();
  }

  onvolumeCheck(checked: boolean){
    this.onlywithVolume = checked;
    if (this.onlywithVolume) {this.withVolume = 'y'} else {this.withVolume = 'n'}
  }

  onbonusCheck(checked: boolean){
    this.showBonus = checked;
    if (this.showBonus) {this.withBonus = 'y'} else {this.withBonus = 'n'}
  }

  csv() {
    CsvExport.exportToCsv('Promotions.csv', this.aecustomers);
  }


}

