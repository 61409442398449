export const AeBonusDetailTableSettings = {
  actions: {
    columnTitle: '',
    delete: false,
    add: false,
    edit: false,
  },
  mode: 'external',
  hideHeader: true,
  noDataMessage: '',
  pager: {
    display: true,
    perPage: 10,
  },
  columns: {
    orden: {
      title: 'Serial',
      width: '10%'
    },
    claseBono: {
      title: 'Bonus Type',
      width: '10%'
    },
    tipoBonoId: {
      title: 'Bonus Id',
      width: '10%'
    },
    cantidadBonoCalculado: {
      title: 'Quantity',
      width: '10%',
    },
    cantidadBonoAcreditado: {
      title: 'Credited',
      width: '10%'
    },
    montoBonoAcreditado: {
      title: 'Amount',
      width: '10%'
    },
    cantidadBonoDownLine: {
      title: 'Downline Qty',
      width: '10%'
    },
    nombramiento: {
      title: 'Position',
      width: '10%',
    },
    customerDnOrigen: {
      title: 'From Dn',
      width: '10%'
    },
  }
};