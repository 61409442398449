import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot} from '@angular/router';
import { AetreeshowService } from './aetreeshow.service';


@Injectable({
    providedIn: 'root'
})


export class AetreshowResolverService implements Resolve<any> {

    constructor(
        private organizationtreeApi: AetreeshowService,
    ) {

    }

    resolve(route: ActivatedRouteSnapshot): any {
        var parameters = route.params['aetreeparam'];
        var sponsordn = parameters.split(',',4)[0];
        var ciclosid = parameters.split(',',4)[1];
        var withvolume = parameters.split(',',4)[2];  
        var withbonus = parameters.split(',',4)[3];        
        return this.organizationtreeApi.getOrganization(sponsordn,ciclosid,withvolume,withbonus)
    }



}