import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { LocalStorageService } from '../../aeshared/aeshared_service_localstorage.service';
import { Aebonuspreviouslist } from '../../aebonusregister/aebonusregister_class_aebonuspreviouslist';
import { Aebonusregisterservice } from '../../aebonusregister/aebonusregister_service_data.service';
import { Selected200BonusRenderComponent } from './aebonusregister_render_selected200.component';

import { CsvExport } from '../../aeshared/aeshared_class_csvexport';

@Component({
  selector: 'ngx-aeformcantidaddetail',
  templateUrl: './aeformcantidaddetail.component.html',
  styleUrls: ['./aeformcantidaddetail.component.scss']
})

export class AeformcantidaddetailComponent implements OnInit {

  static id = 100;

  mode = 'create';
  customerDn = "";
  ciclosId = "";
  aebonuspreviouslist: Aebonuspreviouslist[];
  public chk: boolean = false;
  previousdetailsettings = {
    actions: {
      columnTitle: 'xx',
      delete: false,
      add: false,
      edit: false,
    },
    mode: 'external',
    hideHeader: false,
    pager: {
      display: true,
      perPage: 10,
    },
    columns: {
      checked200: {
        title: 'Select',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: Selected200BonusRenderComponent,
        valuePrepareFunction: (cell, row) => {
          return cell;
        },
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(schecked => {
            if (schecked > 0) { this.getBonusData(); }
          });
        },
      },
      customerDn: {
        title: 'Code',
        width: '40%'
      },
      codigoBono: {
        title: 'Code',
        width: '15%'
      },
      months: {
        title: 'Months',
        width: '15%'
      },
      amount: {
        title: 'Amount',
        width: '15%'
      },
      id: {
        title: 'Serial',
        width: '15%'
      },
    }
  };

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<AeformcantidaddetailComponent>,
    private localstorage: LocalStorageService,
    private bonusregisterapi: Aebonusregisterservice,
  ) {
  }


  ngOnInit() {

    this.customerDn = this.localstorage.getItem("bonuspluscustomerdn");
    this.ciclosId = this.localstorage.getItem("bonusplusciclosid");
    this.getBonusData();

  }

  onCancel(): void {
    this.dialogRef.close();
  }

  csvPreviousBonus() {
    let csvfile = 'BonusSummary_Dn_' + this.customerDn + '_Cycle_' + this.ciclosId + '.csv';
    CsvExport.exportToCsv(csvfile, this.aebonuspreviouslist);
  }

  getBonusData(){
    this.bonusregisterapi.getBonuspreviouslist(this.customerDn, this.ciclosId, 201, '|', '|').
    subscribe((previousdata: Aebonuspreviouslist[]) => {
      this.aebonuspreviouslist = previousdata;
      console.log(previousdata);
    });
  }

  bonusEditDialog(event) {
    alert(event.data.id);

  }


}




