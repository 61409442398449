import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AesharedModule } from '../aeshared/aeshared.module';
import { AetreeshowComponent } from './aetreeshow.component';
import { AetreeshowuserComponent } from './aetreeshowuser.component';
import { ShowdownlineComponent} from './aetreeshow_render_showdownline';
import { SelectOperatorComponent} from './aetreeshow_render_selectoperator';
import { ShowOperatorComponent} from './aetreeshow_render_showoperator';
import { SelectHeadTypeComponent} from './aetreeshow_render_selectheadtype';
import { HeadImageRenderComponent} from './aetreeshow_render_headimage';
import { ShowHeadTypeComponent} from './aetreeshow_render_showheadtype';
import { OperatorRenderComponent} from './aetreeshow_render_operator';
import { AeoperatordialogComponent} from './aeoperatordialog.component';
import {MatDialogModule} from '@angular/material';
import { MatButtonModule, MatSelectModule, MatFormFieldModule } from '@angular/material';

//import { BonusImageRenderComponent } from '../aeshared/aeshared_render_bonusimage.component';

@NgModule({
  declarations: [
    AetreeshowComponent,
    AetreeshowuserComponent,
    ShowdownlineComponent,
    ShowOperatorComponent,
    SelectHeadTypeComponent,
    HeadImageRenderComponent,
    ShowHeadTypeComponent,
    SelectOperatorComponent,
    OperatorRenderComponent,
    AeoperatordialogComponent,
    ],
  entryComponents:[
    ShowdownlineComponent,
    ShowOperatorComponent,
    SelectHeadTypeComponent,
    HeadImageRenderComponent,
    ShowHeadTypeComponent,
    OperatorRenderComponent,
    AeoperatordialogComponent,
    SelectOperatorComponent,
  ],
  providers:[

  ],
  exports: [
    AetreeshowComponent,
    AetreeshowuserComponent,
  ],
  imports: [
  CommonModule,
  AesharedModule,
  MatDialogModule,
  MatButtonModule,
  MatSelectModule,
  MatFormFieldModule,
  ]
})
export class AetreeshowModule { }
