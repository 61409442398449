import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AesharedModule } from '../aeshared/aeshared.module';
import { AeorganizationComponent } from './aeorganization.component';
import { AeorganizationuserComponent } from './aeorganizationuser.component';

@NgModule({
  declarations: [
    AeorganizationComponent,
    AeorganizationuserComponent,    
    ],
  providers:[

  ],
  exports: [
    AeorganizationuserComponent,
  ],
  imports: [
    CommonModule,
    AesharedModule,
  ]
})
export class AeorganizationModule { }
