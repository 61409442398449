import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import { AeciclokeyvalueService } from './aeciclokeyvalue.service';


@Injectable({
    providedIn: 'root'
})


export class AeciclokeyvalueResolverService implements Resolve<any> {

    constructor(
        private ciclokeyvalueApi: AeciclokeyvalueService,
    ) {

    }

    resolve(): any {
        return this.ciclokeyvalueApi.getAeciclos();
    }



}