import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AeCicloVolumeTableSettings } from '../aeshared/aeciclovolume_settings_table';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';


@Component({
  selector: 'ngx-aeuserdashbord',
  templateUrl: './aeuserdashboard.component.html',
  styleUrls: ['./aeuserdashboard.component.scss']
})
export class AeuserdashboardComponent implements OnInit, AfterViewInit {

  dataG: any;
  showDataG: boolean = false;
  aeciclovalues = [];
  labelsA = [];
  data1 = [];
  data2 = [];
  availableDiamonds = 0;
  activeDownlines = 0;
  customerName = '';
  exitIndicator: string = 'false'

  aeciclovolumetablesettings = AeCicloVolumeTableSettings;

  constructor(
    private route: ActivatedRoute, private localstorage: LocalStorageService,
  ) {


  }
  options = {
    title: {
      display: true,
      text: 'PV & GV Results',
      fontSize: 16
    },
    legend: {
      position: 'bottom'
    },
  };

  ngOnInit() {
    this.exitIndicator = this.localstorage.getItem("exitindicator")
    if (this.exitIndicator == 'false') {
      this.customerName = this.localstorage.getItem('customername');
      this.aeciclovalues = this.route.snapshot.data['aeciclovolumeRes'];
      if (this.aeciclovalues != undefined) {
        this.activeDownlines = this.aeciclovalues[0].activeDownlines;
      }
      this.availableDiamonds = this.aeciclovalues[0].availableDiamonds;
      for (var i = 0; i < this.aeciclovalues.length; i++) {
        this.labelsA.push(this.aeciclovalues[i].ciclosId);
        this.data1.push(this.aeciclovalues[i].vpersonal);
        this.data2.push(this.aeciclovalues[i].vgrupo);
      }
      console.log(this.data1);
      console.log(this.data2);

      this.dataG = {

        labels: this.labelsA,
        datasets: [
          {
            label: 'PV',
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: this.data1
          },
          {
            label: 'GV',
            backgroundColor: '#9CCC65',
            borderColor: '#7CB342',
            data: this.data2
          }
        ]

      }
      console.log(this.dataG);
      this.showDataG = true;
    } else { window.location.href = "https://eternalsb.com"; }
  }

  ngAfterViewInit() {


  }

}
