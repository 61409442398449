import { Component, AfterViewInit } from '@angular/core';
import { AewalletService } from './aewallet_service.service';
import { Aewalletlist } from './aewallet_class_list';
import { AewalletcustomerService } from './aewallet_service_customer.service';
import { Aewalletcustomer } from './aewallet_class_customer';
import { Aewalletrecord } from './aewallet_class_record';
import { Router } from '@angular/router';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AcdcNotificationsService } from 'acdc-notifications';
import { CsvExport } from '../aeshared/aeshared_class_csvexport';
import { AeWalletCrudTableSettings } from './aewallet_settings_crudtable';
import { AeWalletCrudTableSettingsEjecutivo } from './aewallet_settings_crudtable_ejecutivo';
import { AeWalletCustomerTableSettings } from './aewallet_settings_customertable';
import { MatDialog, } from '@angular/material';
import { ConfirmationDialog } from '../aeshared/aedialog.component';

const walletrecordserviceUrl = `${environment.apiServiceUrl}/aewalletrecord`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};


@Component({
  selector: 'ngx-aewallet-crud',
  templateUrl: './aewallet_crud.component.html',
  styleUrls: ['./aewallet_view_and_crud.component.scss']
})
export class Aewallet_Crud_Component implements AfterViewInit {

  dataWallet: Aewalletlist[] = [];
  dataCustomer: Aewalletcustomer[] = [];
  isLoadingResults = true;
  aewallets: Aewalletlist[];
  aecustomers: Aewalletcustomer[];
  showwallet: boolean = false;
  showcustomer: boolean = true;

  selectedCustomerDn = null
  customerAvailableBalance = '0.00';
  customerWalletBalance = '0.00';
  backFromWallet = null;
  isadmin: string;
  iscservice: string;
  isejecutivo: string;

  walletcrudtablesettings = AeWalletCrudTableSettings;
  walletcrudtablesettingsejecutivo = AeWalletCrudTableSettingsEjecutivo;
  walletcustomertablesettings = AeWalletCustomerTableSettings;
 



  constructor(
    private walletapi: AewalletService, private customerapi: AewalletcustomerService,
    private router: Router, private localstorage: LocalStorageService,
    private acdcNotificationsService: AcdcNotificationsService,
    private http: HttpClient,
    private dialog: MatDialog,) {
    this.isadmin = this.localstorage.getItem("isadmin");
    this.iscservice = localstorage.getItem("iscservice"); 
    this.isejecutivo = this.localstorage.getItem("isejecutivo");
  }

  ngAfterViewInit() {
    this.backFromWallet = this.localstorage.getItem('backfromwallet');
    if (this.backFromWallet == null) {
      this.getCustomerData();
    }
    else {
      this.selectedCustomerDn = this.localstorage.getItem('walletcustomerdn');
      this.customerAvailableBalance = this.localstorage.getItem('customeravailablebalance');
      this.customerWalletBalance = this.localstorage.getItem('customerwalletbalance');
      this.showCustomerTable(false);
      this.getWalletData();
      this.backFromWallet = null;
    }
  }

  getCustomerData() {
    this.showCustomerTable(true);
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Customer Data',
      timeout: 20000,
      notificationLevel: 'Info'
    });
    this.customerapi.getAewalletCrudCustomers().subscribe((data: Aewalletcustomer[]) => {
      this.aecustomers = data;
      this.acdcNotificationsService.deleteToast(acdcid);
    });

  }

  openCreateDialog() {
    const url = '/aewallet/0,0,' + `${this.selectedCustomerDn}`;
    this.router.navigate([url]);
  }

  walletEditDialog(event) {
    const url = '/aewallet/' + `${event.data.id}` + ',0,' + `${this.selectedCustomerDn}`;
    this.router.navigate([url]);
  }

  customerEditDialog(event) {
    this.aewallets = undefined;
    this.showCustomerTable(false);
    this.selectedCustomerDn = event.data.customerDn;
    this.customerAvailableBalance = event.data.availableBalance;
    this.customerWalletBalance = event.data.walletBalance;
    this.localstorage.setItem('walletcustomerdn', this.selectedCustomerDn);
    this.localstorage.setItem('customeravailablebalance', this.customerAvailableBalance);
    this.localstorage.setItem('customerwalletbalance', this.customerWalletBalance);
    this.localstorage.setItem('returnurl', this.router.url)
    //   alert(this.localstorage.getItem('returnurl'));
    this.getWalletData();
  }

  getWalletData() {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Wallet data',
      timeout: 60000,
      notificationLevel: 'Info'
    });
    this.walletapi.getAewallets(this.selectedCustomerDn, '00000').subscribe((data: Aewalletlist[]) => {
      this.aewallets = data;
      this.acdcNotificationsService.deleteToast(acdcid);
      if (this.aewallets.length == 0) {
        this.acdcNotificationsService.toast({
          title: 'Warning...No Wallet data',
          timeout: 3000,
          notificationLevel: 'Warn'
        });
      }
    });
  }


  showCustomerTable(bshow: boolean) {
    this.showcustomer = bshow;
    this.showwallet = !bshow;

  }

  back() {
    this.getCustomerData();

  }

  customerback() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  csvWallet() {
    let csvfile = 'WalletData_Dn_' + this.selectedCustomerDn + '.csv';
    CsvExport.exportToCsv(csvfile, this.aewallets);
  }

  deleteLast() {
    var walletid = this.aewallets[0].id;
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      maxWidth: "400px",
      data: {
        message: "Deleting Wallet Entry Entry Serial  " + walletid
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        const aewalletrecord = new (Aewalletrecord);
        aewalletrecord.id = 0;
        aewalletrecord.entryType = -1;
        aewalletrecord.customerDn = 'D999';
        aewalletrecord.ciclosId = '00001';
        aewalletrecord.originDn = '';
        aewalletrecord.createUser = 'Admin';
        aewalletrecord.entryAmount = 0;
        aewalletrecord.entryNote = walletid.toString();
        this.http.post<number>(walletrecordserviceUrl, aewalletrecord, httpOptions).subscribe(
          res => {
            console.log('return res ' + res);
            if (res == walletid) {
              this.acdcNotificationsService.toast({
                title: 'Entry Deleted',
                timeout: 3000,
                notificationLevel: 'Success'
              });
              this.getWalletData();
            }
            else {
              this.acdcNotificationsService.toast({
                title: 'Error ' + res.toString(),
                message: 'Entry Not deleted',
                timeout: 5000,
                notificationLevel: 'Error'

              });
            }
          }
        );
      };
    });
  }

}
