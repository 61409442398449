import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Abonuscustomer } from './aebonus_class_customer';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const bonuscustomerserviceUrl = `${environment.apiServiceUrl}/aebonusorganization`;

@Injectable({
  providedIn: 'root'
})

export class Aebonuscustomerservice {

  constructor(private http: HttpClient) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getAebonusCustomers (selectedcycle: string): Observable<Abonuscustomer[]> {
    const url = `${bonuscustomerserviceUrl}/99999,${selectedcycle},y`;
    console.log(url);
    return this.http.get<Abonuscustomer[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aebonuscustomer')),
        catchError(this.handleError('getAebonuscustomer', []))
      );
  }

  
}
