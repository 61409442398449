import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'redirect',
  template: 'redirecting...'
})
export class RedirectComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit() {
    window.open('http://sql5030.site4now.net/Reports/browse/eternal', '_blank');
    const url = '/aeuserdashboard'
    this.router.navigate([url]);

  }
}