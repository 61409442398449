import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
    selector: 'ngx-showoperator',
    template: `<a href="javascript:void(0)" (click)="onClick()">
        <img *ngIf="!hasOperator" width="25" height="25" src="../../../assets/images/unchecked.jpg">
        <label *ngIf="hasOperator">{{operator}}</label></a>`,
    })
    export class ShowOperatorComponent implements OnInit {
        public renderValue: string;
        public hasOperator: boolean = false;
        public operator: string;
        public operatorDn: string;
        public operatorCustomerDn: string;
    
        @Input() value: string | number;
        @Input() rowData: any;
      
        @Output() save: EventEmitter<any> = new EventEmitter();

        constructor() {
        }
    
        ngOnInit() {
            this.renderValue = this.value.toString().toUpperCase();
            if (this.rowData.operator.trim()  === '') {this.hasOperator = false} else{this.hasOperator = true}
            this.operator = this.rowData.operator.trim();
        }
    
        onClick() {
            var response = {
                hasOperator: this.hasOperator,
                operator:this.rowData.operator,
                operatorDn: this.rowData.operatorDn,
                operatorCustomerDn: this.rowData.customerDn,
             }
            this.save.emit(response);
        }
        
    }
    