
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  template: `
  <button (click)="onClick()"><img src="../../../assets/images/checkmark.jpg"></button>
 `,
})


export class PromoComponentRenderRequirement implements ViewCell {

  @Input() value: number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();
  public allowedtransfer: boolean;

  onClick() {
    this.save.emit(this.rowData);
  }

}