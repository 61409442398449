
import { Component, Input, OnInit } from '@angular/core';


@Component({
  template: `
    <img *ngIf="value==220" src="../../../assets/images/checktiny.png"/>
    <img *ngIf="value==221" src="../../../assets/images/forbiddentiny.jpg"/>
    <img *ngIf="value==200" src="../../../assets/images/renttiny.jpg"/>
    <p *ngIf="value==0"></p>
  `,
})

export class BonusImageRenderComponent implements OnInit {
  public renderValue;
  @Input() value;
  constructor() { }
  ngOnInit() {
    this.renderValue = 'data:image/jpg;base64,' + this.value;
  }
}