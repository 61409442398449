import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AesharedModule } from '../aeshared/aeshared.module';
import { AeuplineComponent } from './aeupline.component';

@NgModule({
  declarations: [
    AeuplineComponent,
    ],
  providers:[

  ],
  exports: [
    AeuplineComponent,
  ],
  imports: [
    CommonModule,
    AesharedModule,
  ]
})
export class AeuplineModule { }
