import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AesharedModule } from '../aeshared/aeshared.module';
import { AeshippingComponent } from './aeshipping.component';
import { SelectedRenderComponent } from './aeshipping_render_selected.component';
import { AeexcludeRenderComponent } from './aeshipping_render_exclude.component';



@NgModule({
  declarations: [
    AeshippingComponent,
    SelectedRenderComponent,
    AeexcludeRenderComponent,
  ],
  entryComponents: [
    SelectedRenderComponent,
    AeexcludeRenderComponent,
  ],
  exports: [
    AeshippingComponent,
  ],
  imports: [
    CommonModule,
    AesharedModule,
  ]
})
export class AeshippingModule { }
