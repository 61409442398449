import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Aexpressdatalist } from './aexpressdata_class_aexpressdatalist';
import { Aexpressdatamodel } from './aexpressdata_class_aexpressdatamodel';
import { Aexpressdatadetailmodel } from './aexpressdata_class_aexpressdatadetailmodel';
import { Aexpressdatadetaillist } from './aexpressdata_class_aexpressdatadetaillist';
import { Aexpressdatakeyvalue } from './aexpressdata_class_aexpressdatalkeyvalue';
import { Aevalidxpressdata } from './aexpressdata_class_aevalidxpressdata';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const xpressdataserviceUrl = `${environment.apiServiceUrl}/aexpressdata`;
const xpressdatadetailserviceUrl = `${environment.apiServiceUrl}/aexpressdatadetail`;
const xpressdetailkeyvalueserviceUrl = `${environment.apiServiceUrl}/aexpressdetailkeyvalue`;
const xpressdatakeyvalueserviceUrl = `${environment.apiServiceUrl}/aexpressdatakeyvalue`;
const xpressdatavalidateserviceUrl = `${environment.apiServiceUrl}/aexpressdatavalidate`;

@Injectable({
  providedIn: 'root'
})

export class Aexpressdataservice {

  constructor(private http: HttpClient) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); 
      return of(result as T);
    };
  }

  getAexpressdata (): Observable<Aexpressdatalist[]> {
    const url = `${xpressdataserviceUrl}`;
    return this.http.get<Aexpressdatalist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aexpressdata')),
        catchError(this.handleError('getAexpressdata', []))
      );
  }

  
  getAexpressdatarecord (id: number): Observable<Aexpressdatalist> {
    const url = `${xpressdataserviceUrl}/${id}`;
    return this.http.get<Aexpressdatalist>(url, httpOptions).pipe(
      tap(_ => console.log(`fetched XpressData id=${id}`)),
      catchError(this.handleError<Aexpressdatalist>(`getAexpressdatarecord id=${id}`))
    );
  }

  getValidXpressdata(id: number, xpressname: string, xpresssku: string): Observable<Aevalidxpressdata> {
    const url = `${xpressdatavalidateserviceUrl}/${id},${xpressname},${xpresssku}`;
    return this.http.get<Aevalidxpressdata>(url, httpOptions);
  }

  registerXpressdata (xpressdata: any): Observable<Aexpressdatamodel> {
    const url = `${xpressdataserviceUrl}`;
    console.log('xpressdata ' + xpressdata );
    return this.http.post<Aexpressdatamodel>(url, xpressdata, httpOptions).pipe(
      tap((xpressdataResp: Aexpressdatamodel) => console.log(`registered xpressdata `)),
      catchError(this.handleError<Aexpressdatamodel>('registerxpressdata'))
    );
  }

  registerXpressdatadetail (xpressdetail: any): Observable<Aexpressdatadetailmodel> {
    const url = `${xpressdatadetailserviceUrl}`;
    console.log('xpressdetail ' + xpressdetail );
    return this.http.post<Aexpressdatadetailmodel>(url, xpressdetail, httpOptions).pipe(
      tap((xpressdetailResp: Aexpressdatadetailmodel) => console.log(`registered xpressdetail `)),
      catchError(this.handleError<Aexpressdatadetailmodel>('registerxpressdetail'))
    );
  }

  getAexpressdatadetaillist (xdataid: number): Observable<Aexpressdatadetaillist[]> {
    const url = `${xpressdatadetailserviceUrl}/${xdataid}`;
    return this.http.get<Aexpressdatadetaillist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aexpressdatadetail')),
        catchError(this.handleError('getAexpressdatadetail', []))
      );
  }

  getAexpressdatadetail (xdataid: number, id: number): Observable<Aexpressdatadetaillist> {
    const url = `${xpressdatadetailserviceUrl}/${xdataid},${id}`;
    return this.http.get<Aexpressdatadetaillist>(url, httpOptions).pipe(
        tap(_ => console.log(`fetched XpressDataDetail id=${id}`)),
        catchError(this.handleError<Aexpressdatadetaillist>(`getAexpressdatadetail id=${id}`))
      );
  }

  getAexpressdetailkeyvalue (): Observable<Aexpressdatakeyvalue[]> {
    const url = `${xpressdetailkeyvalueserviceUrl}`;
    return this.http.get<Aexpressdatakeyvalue[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aexpressdatakeyvalue')),
        catchError(this.handleError('getAexpressdatakeyvalue', []))
      );
  }

 
}
