import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Aebonusregisterlist } from './aebonusregister_class_aebonusregisterlist';
import { Aebonoscuponesprevios } from './aebonusregister_class_aebonoscuponesprevios';
import { Aebonusregistermodel } from './aebonusregister_class_aebonusregistermodel';
import { Aebonusxtralist } from './aebonusregister_class_aebonusxtralist';
import { Aecodigobonovalido } from './aebonusregister_class_codigobonovalido';
import { environment } from '../../../environments/environment';
import { Aebonosplusprevios } from './aebonusregister_class_bonosplusprevios';
import { Aebonuspreviouslist } from './aebonusregister_class_aebonuspreviouslist';
import { Aebonusprevioustotals } from './aebonusregister_class_aebonusprevioustotals';
import { Aebonusoperator } from './aebonusregister_class_aebonusoperator';
import { Aebonusoperatorxcycle } from './aebonusregister_class_aebonusoperatorxcycle';
import { Aebonusgroups } from './aebonusregister_class_aebonusgroups';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'dbconnection': 'us' })
};

const bonusgroupsserviceUrl = `${environment.apiServiceUrl}/aebonusgroups`;
const bonusoperatorserviceUrl = `${environment.apiServiceUrl}/aebonusoperator`;
const bonusxtralistserviceUrl = `${environment.apiServiceUrl}/aebonusxtralist`;
const bonoscuponespreviosserviceUrl = `${environment.apiServiceUrl}/aebonoscuponesprevios`;
const bonusmodelserviceUrl = `${environment.apiServiceUrl}/aebonusregister`;
const bonuspreviousserviceUrl = `${environment.apiServiceUrl}/aebonusprevious`;
const bonusprevioustotalsserviceUrl = `${environment.apiServiceUrl}/aebonusprevioustotals`;
const bonusregisterlistserviceUrl = `${environment.apiServiceUrl}/aebonusregisterlist`;
const bonuscodevalidateserviceUrl = `${environment.apiServiceUrl}/aebonuscodevalidate`;

@Injectable({
  providedIn: 'root'
})

export class Aebonusregisterservice {

  constructor(private http: HttpClient) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); 
      return of(result as T);
    };
  }

  getAecustomerbonus (customerdn: string, ciclosid: string): Observable<Aebonusregisterlist[]> {
    const url = `${bonusregisterlistserviceUrl}/${customerdn},${ciclosid},0`;
    return this.http.get<Aebonusregisterlist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aebonusregister')),
        catchError(this.handleError('getAebonusregister', []))
      );
  }
  getAecustomersinglebonus(selectedbono: number): Observable<Aebonusregistermodel> {
    const url = `${bonusmodelserviceUrl}/${selectedbono}`;
    return this.http.get<Aebonusregistermodel>(url, httpOptions).pipe(
      tap(_ => console.log(`fetched Aebonusregister selectedbono=${selectedbono}`)),
      catchError(this.handleError<Aebonusregistermodel>(`getAebonusregister selectedbono=${selectedbono}`))
    );
  }

  getAecustomerdevbonus (customerdn: string, ciclosid: string): Observable<Aebonusregisterlist[]> {
    const url = `${bonusregisterlistserviceUrl}/${customerdn},${ciclosid},1`;
    return this.http.get<Aebonusregisterlist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aedevbonusregister')),
        catchError(this.handleError('getAedevbonusregister', []))
      );
  }

  getAecustomerrepbonus (customerdn: string, ciclosid: string, dev: number): Observable<Aebonusregisterlist[]> {
    const url = `${bonusregisterlistserviceUrl}/${customerdn},${ciclosid},${dev}`;
    return this.http.get<Aebonusregisterlist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aerepbonusregister')),
        catchError(this.handleError('getAerepbonusregister', []))
      );
  }
  
  getBonusOperator (): Observable<Aebonusoperator[]> {
    const url = `${bonusoperatorserviceUrl}`;
    return this.http.get<Aebonusoperator[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched AeBonusOperator')),
        catchError(this.handleError('getBonusOperator', []))
      );
  }

  getBonusOperatorxCycle(ciclosid: string, customerdn: string): Observable<Aebonusoperatorxcycle> {
    const url = `${bonusoperatorserviceUrl}/${ciclosid},${customerdn}`;
    return this.http.get<Aebonusoperatorxcycle>(url, httpOptions);
  }

  getBonusGroups(ciclosid: string, customerdn: string): Observable<Aebonusgroups> {
    const url = `${bonusgroupsserviceUrl}/${ciclosid},${customerdn}`;
    return this.http.get<Aebonusgroups>(url, httpOptions);
  }

  getDevbonus (): Observable<Aebonusxtralist[]> {
    const url = `${bonusxtralistserviceUrl}/500`;
    return this.http.get<Aebonusxtralist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched AedevBonus')),
        catchError(this.handleError('getDevbonus', []))
      );
  }

  getBonosCuponesPrevios (customerdn: string, ciclosid: string , codigobono: number): Observable<Aebonoscuponesprevios[]> {
    const url = `${bonoscuponespreviosserviceUrl}/${customerdn},${ciclosid},${codigobono}`;
    return this.http.get<Aebonoscuponesprevios[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aebonoscuponesprevios')),
        catchError(this.handleError('getBonosCuponesPrevios', []))
      );
  }

  getRepbonus (): Observable<Aebonusxtralist[]> {
    const url = `${bonusxtralistserviceUrl}/100`;
    return this.http.get<Aebonusxtralist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched AerepBonus')),
        catchError(this.handleError('getRepbonus', []))
      );
  }

  deleteXtraBonus (id: number) {
    const url = `${bonusxtralistserviceUrl}/${id}`;
    return this.http.delete(url)
  }

  getCodigoBonoValido(customerdn: string, ciclosid: string, codigobono: number): Observable<Aecodigobonovalido> {
    const url = `${bonuscodevalidateserviceUrl}/${customerdn},${ciclosid},${codigobono}`;
    return this.http.get<Aecodigobonovalido>(url, httpOptions);
  }

  getBonosPlusPrevios(customerdn: string, ciclosid: string, codigobono: number): Observable<Aebonosplusprevios> {
    const url = `${bonusmodelserviceUrl}/${customerdn},${ciclosid},${codigobono}`;
    return this.http.get<Aebonosplusprevios>(url, httpOptions);
  }

  getBonuspreviouslist (customerdn: string, ciclosid: string, codigobono: number, filtervaluecustomer: string, filtervaluebono: string): Observable<Aebonuspreviouslist[]> {
    const url = `${bonuspreviousserviceUrl}/${customerdn},${ciclosid},${codigobono},${filtervaluecustomer},${filtervaluebono}`;
    return this.http.get<Aebonuspreviouslist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aebonuspreviouslist')),
        catchError(this.handleError('getbonuspreviouslist', []))
      );
  }

  getBonusprevioustotals (customerdn: string, ciclosid: string): Observable<Aebonusprevioustotals[]> {
    const url = `${bonusprevioustotalsserviceUrl}/${customerdn},${ciclosid}`;
    return this.http.get<Aebonusprevioustotals[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aebonusprevioustotals')),
        catchError(this.handleError('getbonusprevioustotalas', []))
      );
  } 
  
}
