import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';

import 'rxjs/add/operator/toPromise';

import { Aecustomeraddress } from '../aeshared/aecustomeraddress';
import { Aestoreproductslist } from './aestore_class_productslist';
import { Aestorelist } from './aestore_class_storelist';
import { Aestoreorderslist } from './aestore_class_orderslist';
import { Aestoreorderproductslist } from './aestore_class_orderproductslist';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};
const storeserviceUrl = `${environment.apiServiceUrl}/aestore`;
const storeproductserviceUrl = `${environment.apiServiceUrl}/aestoreproducts`;
const storeordersserviceUrl = `${environment.apiServiceUrl}/aestoreorders`;
const storeorderproductsserviceUrl = `${environment.apiServiceUrl}/aestoreorderproducts`;

@Injectable({
  providedIn: 'root'
})
export class AestoreService {
  constructor(private http: HttpClient) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getAestoreproducts(customerdn: string): Observable<Aestoreproductslist[]> {
    const url = `${storeproductserviceUrl}/${customerdn}`;
    console.log('url ' + url);
    return this.http.get<Aestoreproductslist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aestoreproducts')),
        catchError(this.handleError('getAestoreproducts', []))
      );
  }
  getAestoreorders(customerdn: string): Observable<Aestoreorderslist[]> {
    const url = `${storeordersserviceUrl}/${customerdn}`;
    console.log('url ' + url);
    return this.http.get<Aestoreorderslist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aestoreorders')),
        catchError(this.handleError('getAestoreorders', []))
      );
  }
  getAestore(customerdn: string): Observable<any> {
    const url = `${storeserviceUrl}/${customerdn}`;
    console.log('url ' + url);
    return this.http.get<Aestorelist>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aestoreorderheader')),
        catchError(this.handleError('getAestoreorderheader', []))
      );
  }  
  getAestoreorderdetail(customerdn: string, ordernumber: string): Observable<any> {
    const url = `${storeordersserviceUrl}/${customerdn},${ordernumber}`;
    console.log('url ' + url);
    return this.http.get<Aestoreorderslist>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aestoreorderheader')),
        catchError(this.handleError('getAestoreorderheader', []))
      );
  }
  getAestoreorderproducts(customerdn: string, ordernumber: string): Observable<Aestoreorderproductslist[]> {
    const url = `${storeorderproductsserviceUrl}/${customerdn},${ordernumber}`;
    console.log('url ' + url);
    return this.http.get<Aestoreorderproductslist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aestoreorderproducts')),
        catchError(this.handleError('getAestoreorderproducts', []))
      );
  }


}
