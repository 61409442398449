import { Component, AfterViewInit } from '@angular/core';
import { AewalletService } from './aewallet_service.service';
import { Aewalletlist } from './aewallet_class_list';
import { Router } from '@angular/router';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { AcdcNotificationsService } from 'acdc-notifications';
import { CsvExport } from '../aeshared/aeshared_class_csvexport';
import {AeWalletViewTableSettings} from './aewallet_settings_viewtable';


@Component({
  selector: 'ngx-aewallet-view',
  templateUrl: './aewallet_view.component.html',
  styleUrls: ['./aewallet_view_and_crud.component.scss']
})
export class Aewallet_View_Component implements AfterViewInit {
  customerDn = undefined;
  data: Aewalletlist[] = [];
  isLoadingResults = true;
  aewallets: Aewalletlist[];

  walletviewtablesettings = AeWalletViewTableSettings;

  

  constructor(private api: AewalletService,
    private router: Router, private acdcNotificationsService: AcdcNotificationsService,
    private localstorage: LocalStorageService,) {
      this.customerDn = localstorage.getItem("customerdn");

  }

  ngAfterViewInit() {
    this.aewallets = undefined;

    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Info',
      message: 'Please wait... Loading Wallet data',
      timeout: 60000,
      notificationLevel: 'Info'
    });
    this.api.getAewallets(this.customerDn, '00000').subscribe((data: Aewalletlist[]) => {
      this.aewallets = data;
      this.acdcNotificationsService.deleteToast(acdcid);
      if (this.aewallets.length == 0) {
        this.acdcNotificationsService.toast({
          title: 'Warning',
          message: 'No Wallet data',
          timeout: 3000,
          notificationLevel: 'Warn'
        });
      }
    });
  }

  back() {
    const url = '/aeuserdashboard';
    this.router.navigate([url]);
  }

  csvWallet() {
    let csvfile = 'WalletData_Dn_' + this.customerDn + '.csv';
    CsvExport.exportToCsv(csvfile, this.aewallets);
  }

}
