import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
    selector: 'ngx-bonusdelete',
    template: `<button (click)="onClick()" style="border: none">
    <img *ngIf="!chkassign"  src="../../../assets/images/delete.jpg">
    <img *ngIf="chkassign"  src="../../../assets/images/save.jpg"></button>`,
    })

    // template: `<a href="javascript:void(0)" (click)="onClick()">
    // <img src="../../../assets/images/delete.jpg"></a>`,

    export class BonusDeleteComponent implements OnInit {
        public renderValue: string;
    
        @Input() value: string | number;
        @Input() rowData: any;
      
        @Output() save: EventEmitter<any> = new EventEmitter();

        public chkassign: boolean;

        constructor() {
        }
    
        ngOnInit() {
            this.renderValue = this.value.toString().toUpperCase();
            if (this.rowData.reserved && this.rowData.esCicloAnterior) {this.chkassign = true;} else {this.chkassign = false;}
        }
    
        onClick() {
            var response = {
                id: this.rowData.id,
                checked:this.rowData.checked,
                codigobono: this.rowData.codigoBono,
                assignBono: this.chkassign,
             }
            this.save.emit(response);
        }
        
    }
    