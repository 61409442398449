import { Component, Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'gift-dialog',
  templateUrl: 'aegiftdialog.component.html',
})
export class GiftDialog {
  message1: string = ""
  message2: string = ""
  message3: string = ""
  message4: string = ""
  message5: string = ""
  message6: string = ""
  message7: string = ""
  message8: string = ""
  cancelButtonText = "Continue"
  confirmButtonText = "Change"
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<GiftDialog>) {
    if (data) {
      this.message1 = data.message1 || this.message1;
      this.message2 = data.message2 || this.message2;
      this.message3 = data.message3 || this.message3;
      this.message4 = data.message4 || this.message4;
      this.message5 = data.message5 || this.message5;
      this.message6 = data.message6 || this.message6;
      this.message7 = data.message7 || this.message7;
      this.message8 = data.message8 || this.message8;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}