import { Component, OnInit, AfterViewInit, ViewChild, OnChanges, ElementRef } from '@angular/core';
import { TreeComponent, TreeNode, TreeModel, TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from 'angular-tree-component';
import { Router, ActivatedRoute, } from '@angular/router';
import { AedownlineService } from './aedownline_service';
import { AcdcNotificationsService } from 'acdc-notifications';
import { environment } from '../../../environments/environment';
import { Aedownlinenode } from './aedownline_class_node';
const actionMapping: IActionMapping = {
  mouse: {
    contextMenu: (tree, node, $event) => {
      $event.preventDefault();
      alert(`context menu for ${node.data.name}`);
    },
    dblClick: (tree, node, $event) => {
      if (node.hasChildren) {
        TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
      }
    },
    click: (tree, node, $event) => {
      $event.shiftKey
        ? TREE_ACTIONS.TOGGLE_ACTIVE_MULTI(tree, node, $event)
        : TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event)
    }
  },
  keys: {
    [KEYS.ENTER]: (tree, node, $event) => alert(`This is ${node.data.name}`)
  }
};

const downlineserviceUrl = `${environment.apiServiceUrl}/aedownline`;


@Component({
  selector: 'ngx-asyncdownline',
  templateUrl: 'aedownline.component.html',
  styles: [],
})
export class AeasyncdownlineComponent {
  options: ITreeOptions = {
    getChildren: this.getChildren.bind(this)
  };

  getChildren(node: any) {
    var parentid = node.parent.id;
    console.log(parentid);
    alert(parentid);
    var childNodes: any[] = [];
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Downline data',
      timeout: 60000,
      notificationLevel: 'Info'
    });
    return this.downlineapi.getDownline(parentid, this.selectedCycle, 'n').toPromise();
    
    // .then((data: Aedownlinenode[]) => {
    //   console.log(data);
    //   let haschildren: boolean;
    //   data.forEach(element => {
    //     haschildren = false;
    //     if (element.hasChildren> 0 ) {haschildren = true;}
    //     let nodo =  { id: element.id, name: element.customer, hasChildren: haschildren, children: [] };
    //     childNodes.push(nodo);
    //     console.log(nodo.name);
    //   });
    //   this.acdcNotificationsService.deleteToast(acdcid);
    //   this.shownodes = true;
    // });
    // const newNodes = childNodes.map((c) => Object.assign({}, c));
    // return newNodes;
  }

  @ViewChild('tree', { static: false }) private treeref: TreeComponent;

  nodes: any[] = [];

  nodos: Aedownlinenode[];

  selectedCustomerDn = 'D3535';
  selectedCustomerId = 18178
  selectedCycle = '00007';
  shownodes: boolean = true;

  constructor(
    private acdcNotificationsService: AcdcNotificationsService,
    private downlineapi: AedownlineService,
  ) {
    this.getDownlineData(this.selectedCustomerId,'y');
  }

  ngAfterViewInit() {
    console.log(this.nodes);
    //    console.log(this.treecomponent.nodes[0].id);
  }

  getDownlineData(customerid:number, withParent:string) {
    let firstNode: boolean = true;
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Downline data',
      timeout: 60000,
      notificationLevel: 'Info'
    });
    this.downlineapi.getDownline(customerid, this.selectedCycle, withParent).toPromise().then((data: Aedownlinenode[]) => {
      console.log(data);
      let nodeLevel: number;
      let previousLevel: number;
      let haschildren: boolean;
      data.forEach(element => {
        haschildren = false;
        if (element.hasChildren> 0 ) {haschildren = true;}
        let nodo=  { id: element.id, name: element.customer, hasChildren: haschildren, children: [] };
        nodeLevel = element.depth;
        if (firstNode) {
          this.nodes.push(nodo);
          firstNode = false;
          previousLevel = element.depth;
          this.treeref.treeModel.update();
          console.log(element.id + ' added found');
        }
        else {
          var parentNode = this.nodes.find(x => x.id === element.sponsorId);
          if (parentNode !== undefined) {
            console.log(element.sponsorId + ' parent found');
            parentNode.children.push(nodo);
            this.treeref.treeModel.update();
          }

        }
      });
      this.acdcNotificationsService.deleteToast(acdcid);
      this.shownodes = true;
    });
  }

  addNode(tree: any) {
    this.nodes[0].children.push({

      id: 15, name: 'a new child'
    });
    console.log(this.nodes);
    tree.treeModel.update();
    tree.treeModel.getNodeById('15').setActiveAndVisible();

  }

  go($event: any) {
    $event.stopPropagation();
    alert('this method is on the app component');
  }

  childrenCount(node: TreeNode): string {
    return node && node.children ? `(${node.children.length})` : '';
  }


  onEvent(event: any) {
    console.log(event);
  }

  onInitialized(tree: any) {

    tree.treeModel.getNodeById('6').setActiveAndVisible();
  }
}

  





