import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Aebonus } from './aebonus_class_bonus';
import { Aebonusdetail } from './aebonus_class_detail';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const bonusserviceUrl = `${environment.apiServiceUrl}/aebonus`;
const bonusdetailserviceUrl = `${environment.apiServiceUrl}/aebonusdetail`;

@Injectable({
  providedIn: 'root'
})
export class AebonusService {


  constructor(private http: HttpClient) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getAebonus (customerdn: string, ciclosid: string): Observable<Aebonus[]> {
    const url = `${bonusserviceUrl}/${customerdn},${ciclosid}`;
    return this.http.get<Aebonus[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aebonus')),
        catchError(this.handleError('getAebonus', []))
      );
  }

  getAebonusdetail (customerdn: string, ciclosid: string): Observable<Aebonusdetail[]> {
    const url = `${bonusdetailserviceUrl}/${customerdn},${ciclosid}`;
    return this.http.get<Aebonusdetail[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aebonus Detalle')),
        catchError(this.handleError('getAebonusdetail', []))
      );
  }

  updateAebonus (fkciclo: string): Observable<any> {
    const url = `${bonusserviceUrl}/${fkciclo}`;
    return this.http.put(url, httpOptions).pipe(
      tap(_ => console.log(`updated AeBonus fkciclo=${fkciclo}`)),
      catchError(this.handleError<any>('updateAebonus'))
    );
  }


   
}
