import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AesharedModule } from '../aeshared/aeshared.module';

import { AepromosComponent } from './aepromos.component';
import { AepromodefinitionComponent } from './aepromo_definition.component';
import { AepromocomponentComponent } from './aepromo_component.component';
import { AepromorequirementComponent } from './aepromo_requirement.component';
import {PromoComponentRenderComponent} from './aepromo_render_promo_component.component'
import {PromoComponentRenderRequirement} from './aepromos_render_promo_requirement.component'

@NgModule({
  declarations: [
    AepromosComponent,
    AepromodefinitionComponent,
    AepromocomponentComponent,
    AepromorequirementComponent,
    PromoComponentRenderComponent,
    PromoComponentRenderRequirement,
  ],
  entryComponents: [
    PromoComponentRenderComponent,  
    PromoComponentRenderRequirement,    
  ],
  imports: [
    CommonModule,
    AesharedModule,
  ]
})
export class AepromotionsModule { }
