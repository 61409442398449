import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';



@Component({
  selector: 'ngx-aeuserdashbord',
  templateUrl: './aedisabledl.component.html',
  styleUrls: ['./aedisabledl.component.scss']
})
export class AedisabledlComponent implements OnInit {

  customerName = '';

  constructor(private localstorage: LocalStorageService, ) {

  }

  ngOnInit() {

    this.customerName = this.localstorage.getItem('customername');
  }
}
