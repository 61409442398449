import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';

import 'rxjs/add/operator/toPromise';

import { Aewarehouse } from './aewarehouse';
import { Aedistributioncenter } from '../aexorders/aexorder_class_distributioncenter';
import { Aedistributioncentersassigned } from '../aexorders/aexorder_class_distributioncentersassigned';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const aewarehouseserviceUrl = `${environment.apiServiceUrl}/aewarehousename`;
const aewarehouseshippingserviceUrl = `${environment.apiServiceUrl}/aewarehouseshipping`;
const aedistributioncenterserviceUrl = `${environment.apiServiceUrl}/aedistributioncenteraddress`;
const aedistributioncentersassignedserviceUrl = `${environment.apiServiceUrl}/aedistributioncentersassigned`;

@Injectable({
  providedIn: 'root'
})
export class AewarehouseService {
//  private url = 'api/orderRequests';

  aewarehousesJson: Aewarehouse[];
  aewarehouses = [];

  constructor(private http: HttpClient) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  getAewarehouses(): Observable<Aewarehouse[]> {
    const url = `${aewarehouseserviceUrl}`;
    return this.http.get<Aewarehouse[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aewarehouses')),
        catchError(this.handleError('getaewarehouses', []))
      );
  }

  getAewarehousesshipping(): Observable<Aewarehouse[]> {
    const url = `${aewarehouseshippingserviceUrl}`;
    return this.http.get<Aewarehouse[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aewarehousesshipping')),
        catchError(this.handleError('getaewarehousesshipping', []))
      );
  }

  getAedistributioncenters(xorderid: number): Observable<Aedistributioncenter[]> {
    const url = `${aedistributioncenterserviceUrl}/${xorderid}`; 
    return this.http.get<Aedistributioncenter[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aedistributioncenters')),
        catchError(this.handleError('getaedistributioncenters', []))
      );
  }

  getAedistributioncentersassigned(isinventory: boolean, warehouseid: number): Observable<Aedistributioncentersassigned[]> {
    const url = `${aedistributioncentersassignedserviceUrl}/${isinventory},${warehouseid}`; 
    return this.http.get<Aedistributioncentersassigned[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aedistributioncenters')),
        catchError(this.handleError('getaedistributioncenters', []))
      );
  }

  getAedistributioncentersgroupid(xorderid: number, groupid: number): Observable<Aedistributioncenter[]> {
    const url = `${aedistributioncenterserviceUrl}/${xorderid},${groupid}`; 
    return this.http.get<Aedistributioncenter[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aedistributioncenters')),
        catchError(this.handleError('getaedistributioncenters', []))
      );
  }
}
