import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AesharedModule } from '../../aeshared/aeshared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import {
  MatCommonModule,
  MatFormFieldModule,
} from '@angular/material';

import { AeformcantidadmesesComponent } from './aeformcantidadmeses.component';

@NgModule({
declarations: [AeformcantidadmesesComponent],
entryComponents: [AeformcantidadmesesComponent],
imports: [
	CommonModule,
	FormsModule,
	AesharedModule,
	ReactiveFormsModule,
	MatInputModule,
	MatButtonModule,
	MatIconModule,
	MatRadioModule,
	MatSelectModule,
	MatCommonModule,
  MatFormFieldModule,
	MatTableModule,
],
})
export class AeformcantidadmesesModule {}
