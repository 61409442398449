import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AeformcantidadinicioModel } from './aeformcantidadinicio.model';

@Component({
  selector: 'ngx-aeformcantidadinicio',
  templateUrl: './aeformcantidadinicio.component.html',
  styleUrls: ['./aeformcantidadinicio.component.scss']
})
export class AeformcantidadinicioComponent implements OnInit {

  static id = 100;

  aebonusForm: FormGroup;
  mode = 'create';

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
              private dialogRef: MatDialogRef<AeformcantidadinicioComponent>,
              private fb: FormBuilder) {
  }
 

  ngOnInit() {
    this.defaults = {} as AeformcantidadinicioModel;
    this.aebonusForm = this.fb.group({
      cantidad: this.fb.control(1, [Validators.required, Validators.min(1), Validators.max(1)]),
      inicio: this.fb.control(1, [Validators.required, Validators.min(1)]),
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave() {
    const bono = this.aebonusForm.value;
    this.dialogRef.close(bono);
  }


 }
