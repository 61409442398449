import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';

const serviceUrl = `${environment.apiServiceUrl}/aedesktop`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};


@Injectable({
  providedIn: 'root'
})
export class AedesktopService {


  constructor(private http: HttpClient, 
    private localstorage: LocalStorageService) {
  }
  desksessionid: number;
  current: string;
  isadmin: string;
  iscservice: string;
  ismasterdistribution: string;
  isdiseno: string;
  isdlenabled: string;
  isbonus: string;
  isejecutivo: string;
  ispartialorders: string;
  isoperadorcierre: string;
  isdcenter: string;
  isregistrado: string = "False";
  customerdn: string;
  sponsordn: string;
  impersonatordn: string;
  viewcreditlist: string;
  desktopversion: string;

  customerName: string;
  desktopguid: string;
  maintenancemode: any;

  urlExists(url): any {
    return fetch(url, { mode: "no-cors" })
      .then(res => true)
      .catch(err => false)
  }

  getaedesktop(origin: string): Promise<any> {
    this.desktopguid = this.getDesktopGuid();
    console.log('desktopguid ' + this.desktopguid);
    let currentuser = this.localstorage.getItem('current');
    if (currentuser === null || this.desktopguid.length > 0) {
      this.localstorage.clear();
      currentuser = null;
    }
    console.log("Loading here from the api");
    let url = `${serviceUrl}/${this.desktopguid}`;
    console.log(origin + ' URL: ' + url);
    const promise = this.http.get(url,httpOptions).toPromise();
    console.log(promise);
    promise.then((data) => {
      var dataaedesktop = JSON.stringify(data);
      JSON.parse(dataaedesktop, (key, value) => {
        if (key === "id") { this.desksessionid = value.toString(); }
        if (key === "aeDesktopCurrent") { this.current = value; }
        if (key === "aeDesktopIsAdmin") { this.isadmin = value; }
        if (key === "aeDesktopIsCService") { this.iscservice = value; }
        if (key === "aeDesktopIsMasterDistribution") { this.ismasterdistribution = value; }  
        if (key === "aeDesktopIsDiseno") { this.isdiseno = value; }        
        if (key === "aeDesktopIsDLEnabled") { this.isdlenabled = value; }
        if (key === "aeDesktopIsBonus") { this.isbonus = value; } 
        if (key === "aeDesktopIsEjecutivo") { this.isejecutivo = value; } 
        if (key === "aeDesktopIsPartialOrders") { this.ispartialorders = value; } 
        if (key === "aeDesktopIsOperadorCierre") { this.isoperadorcierre = value; } 
        if (key === "aeDesktopIsDCenter") { this.isdcenter = value; }    
        if (key === "aeDesktopIsRegistrado") { this.isregistrado = value; }                
        if (key === "aeDesktopCustomerDN") { this.customerdn = value; }
        if (key === "aeDesktopSponsorDN") { this.sponsordn = value; }
        if (key === "aeDesktopImpersonatorDN") { this.impersonatordn = value; }
        if (key === "aeDesktopCustomerName") { this.customerName = value; }
        if (key === "aeDesktopViewCreditList") { this.viewcreditlist = value; }
        if (key === "aeDesktopVersion") { this.desktopversion = value; }
      });
      console.log("Loaded from api");
      this.localstorage.setItem('desksessionid', this.desksessionid);
      this.localstorage.setItem('current', this.current);
      this.localstorage.setItem('isadmin', this.isadmin);
      this.localstorage.setItem('iscservice', this.iscservice);
      this.localstorage.setItem('ismasterdistribution', this.ismasterdistribution);
      this.localstorage.setItem('isdiseno', this.isdiseno);
      this.localstorage.setItem('isdlenabled', this.isdlenabled);
      this.localstorage.setItem('isbonus', this.isbonus); 
      this.localstorage.setItem('isejecutivo', this.isejecutivo);  
      this.localstorage.setItem('ispartialorders', this.ispartialorders);  
      this.localstorage.setItem('isoperadorcierre', this.isoperadorcierre);  
      this.localstorage.setItem('isdcenter', this.isdcenter);
      this.localstorage.setItem('isregistrado', this.isregistrado);            
      this.localstorage.setItem('customerdn', this.customerdn);
      this.localstorage.setItem('sponsordn', this.sponsordn);
      this.localstorage.setItem('impersonatordn', this.impersonatordn);
      this.localstorage.setItem('customername', this.customerName);
      this.localstorage.setItem('viewcreditlist', this.viewcreditlist);
      this.localstorage.setItem('desktopversion', this.desktopversion);
      this.localstorage.setItem('desktopguid', this.desktopguid);
      this.localstorage.setItem('exitindicator','false');
      this.localstorage.setItem('cleancustomerdn','');
      this.localstorage.setItem('aedesktopversion','2.4.3');
      this.localstorage.setItem('aedesktoporigin','mx');
    }).catch((error) => {
      console.log("Promise rejected with " + JSON.stringify(error));
    });
    return promise;
  }

  getDesktopGuid(): string {
    var desktopGuid = getQueryVariable("p");
    return desktopGuid;

    function getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return "";
    }
  }

  normalizeString(cadena: string): string {
    let cadenaOut = cadena.split("/").join("f0f0f0");
    cadenaOut = cadenaOut.split("#").join("g0g0g0");
    cadenaOut = cadenaOut.split("&").join("h0h0h0");
    cadenaOut = cadenaOut.split(",").join("");
    return cadenaOut;
  }
}



