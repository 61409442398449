import { Component, AfterViewInit, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AebonusService } from './aebonus_service.service';
import { Aebonus } from './aebonus_class_bonus';
import { Aebonusdetail } from './aebonus_class_detail';
import { Aebonuscustomerservice } from './aebonus_service_customer.service';
import { Abonuscustomer } from './aebonus_class_customer';
import { Router, ActivatedRoute } from '@angular/router';
import { AcdcNotificationsService } from 'acdc-notifications';
import { CsvExport } from '../aeshared/aeshared_class_csvexport';
import { Aeciclokeyvalue } from '../aeshared/aeciclokeyvalue';
import { AeBonusCustomerTableSettings } from './aebonus_settings_customertablesettings'
import { AeBonusTableSettings } from './aebonus_settings_tablesettings'
import { AeBonusDetailTableSettings } from './aebonus_settings_detailtablesettings'
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';

@Component({
  selector: 'ngx-aebonus',
  templateUrl: './aebonus.component.html',
  styleUrls: ['./aebonus.component.scss']
})
export class AebonusComponent implements AfterViewInit, OnInit {

  databonus: Aebonus[] = [];
  databonusdetail: Aebonusdetail[] = [];
  dataCustomer: Abonuscustomer[] = [];

  isLoadingResults = true;

  aecustomers: Abonuscustomer[];
  aebonus: Aebonus[] = undefined;
  aebonusdetail: Aebonusdetail[] = undefined;


  showbonus: boolean = false;
  showbonusdetail: boolean = false;
  showcustomer: boolean = true;

  aecycles: Aeciclokeyvalue[];
  selectedCycleFormControl = new FormControl();
  selectedCycle: string;
  selectedCycleChanged = true;
  selectedLabel: string;


  selectedCustomerDn = ''
  selectedDetalleCustomerDn = ''
  bonuscustomersettings = AeBonusCustomerTableSettings;
  bonustablesettings = AeBonusTableSettings;
  bonusdetailtablesettings = AeBonusDetailTableSettings;

  constructor(
    private bonusapi: AebonusService, private customerapi: Aebonuscustomerservice,
    private router: Router, private acdcNotificationsService: AcdcNotificationsService,
    private route: ActivatedRoute,
    private localstorage: LocalStorageService,) { }

  ngOnInit(): void {
    var localciclo = this.localstorage.getItem('aeciclobonus')
    this.aecycles = this.route.snapshot.data['aecicloRes'];
    if (localciclo == null) { this.selectedCycle = this.aecycles[0].key; }
    else { this.selectedCycle = localciclo; }
    this.selectedLabel = this.selectedCycle
    this.localstorage.setItem('aeciclobonus', this.selectedCycle)
  }

  ngAfterViewInit() {
    this.aecustomers = undefined;
    this.showBonusTable(1);
  }

  bonusEditDialog(event) {
    this.selectedDetalleCustomerDn = event.data.customerDn;
    this.aebonusdetail = undefined
    this.showBonusTable(3)
  }

  customerEditDialog(event) {
    this.aebonus = undefined
    this.aebonusdetail = undefined
    this.selectedCustomerDn = event.data.customerDn;
    this.showBonusTable(2)
  }

  showBonusTable(bshow: number) {
    if (bshow == 1) {
      this.showcustomer = true;
      this.showbonus = false;
      this.showbonusdetail = false;
      this.aecustomers = undefined;
      const acdcid: string = this.acdcNotificationsService.toast({
        title: 'Please wait... Loading Distributors data',
        timeout: 60000,
        notificationLevel: 'Info'
      });
      this.customerapi.getAebonusCustomers(this.selectedCycle).subscribe((data: Abonuscustomer[]) => {
        this.aecustomers = data;
        this.acdcNotificationsService.deleteToast(acdcid);
      });
    } else
      if (bshow == 2) {
        this.showcustomer = false;
        this.showbonus = true;
        this.showbonusdetail = false;
        if (this.aebonus == undefined || this.selectedCycleChanged) {
          if (this.selectedCycle != undefined) {
            const acdcid: string = this.acdcNotificationsService.toast({
              title: 'Please wait... Loading Bonus Summary data',
              timeout: 60000,
              notificationLevel: 'Info'
            });
            this.bonusapi.getAebonus(this.selectedCustomerDn, this.selectedCycle)
              .subscribe((data: Aebonus[]) => {
                this.aebonus = data;
                this.acdcNotificationsService.deleteToast(acdcid);
                if (this.aebonus.length == 0) {
                  this.acdcNotificationsService.toast({
                    title: 'Warning... No Bonus Summary data',
                    timeout: 3000,
                    notificationLevel: 'Warn'
                  });
                }
              });
            this.selectedCycleChanged = false;
          }
        }
      } else
        if (bshow == 3) {
          this.showcustomer = false;
          this.showbonus = false;
          this.showbonusdetail = true;
          const acdcid: string = this.acdcNotificationsService.toast({
            title: 'Please wait... Loading Bonus Detail data',
            timeout: 60000,
            notificationLevel: 'Info'
          });
          this.bonusapi.getAebonusdetail(this.selectedDetalleCustomerDn, this.selectedCycle)
            .subscribe((data: Aebonusdetail[]) => {
              this.aebonusdetail = data;
              this.acdcNotificationsService.deleteToast(acdcid);
              if (this.aebonusdetail.length == 0) {
                this.acdcNotificationsService.toast({
                  title: 'Warning... No Bonus Detail data',
                  timeout: 3000,
                  notificationLevel: 'Warn'
                });
              }
            });
        }
  }

  customerback() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  bonusback() {
    this.showBonusTable(1);
  }

  bonusdetailback() {
    this.showBonusTable(2);
  }

  csvBonus() {
    let csvfile = 'BonusSummary_Dn_' + this.selectedCustomerDn + '_Cycle_' + this.selectedCycle + '.csv';
    CsvExport.exportToCsv(csvfile, this.aebonus);
  }

  paytoWallet() {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Cycle must be Closed',
      message: 'To transfer bonus amounts',
      timeout: 4000,
      notificationLevel: 'Error'
    });

  }
  csvBonusDetail() {
    let csvfile = 'BonusDetail_Dn_' + this.selectedDetalleCustomerDn + '_Cycle_' + this.selectedCycle + '.csv';
    CsvExport.exportToCsv(csvfile, this.aebonusdetail);
  }

  onCycleChange(event) {
    this.selectedCycle = event.key;
    this.selectedCycleChanged = true;
    this.selectedLabel = this.selectedCycle
    this.localstorage.setItem('aeciclobonus', this.selectedCycle)
    this.showBonusTable(1);
  }
}
