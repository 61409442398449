export const AeWalletCrudTableSettings = {
  // rowClassFunction: (row) => {
  //   if (row.data.credit == "0.00") { return 'debitngrow'; }
  //   if (row.data.debit == "0.00") { return 'creditngrow'; }
  // },
  actions: {
    columnTitle: '',
    delete: false,
  },
  mode: 'external',
  hideHeader: true,
  noDataMessage: '',
  pager: {
    display: true,
    perPage: 20,
  },
  add: {
    addButtonContent: '<i class="ion-ios-plus-outline"></i>',
    confirmCreate: true,
  },
  edit: {
    editButtonContent: '<i class="ion-ios-eye-outline"></i>',
    confirmSave: true
  },
  columns: {
    id: {
      title: 'Serial',
      width: '5%',
      type: 'html',
      valuePrepareFunction: (cell, row) => {
        if (row.debit === '0.00') {
          return '<p class="cell_blue_center">' + cell + '</p>'; 
        } else {
          return '<p class="cell_red_center">' + cell + '</p>'; 
        }
      }
    },
    description: {
      title: 'Description',
      width: '30%'
    },
    credit: {
      title: 'Credit',
      width: '12%',
      align: 'right',
      type: 'html',
      valuePrepareFunction: (cell, row) => {
        if (row.debit === '0.00') {
          return '<p class="cell_blue_right">' + cell + '</p>'; 
        } else {
          return '<p class="cell_none_right">' + cell + '</p>'; 
        }
      }
    },
    debit: {
      title: 'Debit',
      width: '12%',
      align: 'right',
      type: 'html',
      valuePrepareFunction: (cell, row) => {
        if (row.debit === '0.00') {
          return '<p class="cell_none_right">' + cell + '</p>'; 
        } else {
          return '<p class="cell_red_right">' + cell + '</p>'; 

        }
      }
    },
    tdate: {
      title: 'Date',
      width: '12%',
      type: 'html',
      valuePrepareFunction: (cell) => {
         return '<p class="cell_none_center">' + cell + '</p>'; 
      }
    },
    balance: {
      title: 'Balance',
      width: '12%',
      type: 'html',
      valuePrepareFunction: (cell) => {
        if (cell.substring(0,1) === '(') {
          return '<p class="cell_red_right">' + cell + '</p>'; 
        } else {
          return '<p class="cell_blue_right">' + cell + '</p>'; 
        }
      }
    },
    originDn: {
      title: 'Related Dn',
      width: '12%',
      type: 'html',
      valuePrepareFunction: (cell) => {
         return '<p class="cell_none_center">' + cell + '</p>'; 
      }
    },
  }
};
