import { Component, OnInit } from '@angular/core';
import { AewentrytypeService } from './aewentrytype_service.service';
import { Aewentrytype } from './aewentrytype_class_record'
import {Router} from '@angular/router';
import { CsvExport } from '../aeshared/aeshared_class_csvexport';


@Component({
  selector: 'ngx-aewentrytype-crud',
  templateUrl: './aewentrytype_crud.component.html',
  styleUrls: ['./aewentrytype_crud.component.scss']
})
export class Aewentrytype_Crud_Component implements OnInit {

  data: Aewentrytype[] = [];
  isLoadingResults = true;
  aewentrytypes: Aewentrytype[];

  settings = {
    actions: {
    columnTitle: '',
    delete: false,
    },  
  mode: 'external',
  hideHeader: true,
  noDataMessage: '',
  pager: {
    display: true,
    perPage: 30,
  },
  add: {
    addButtonContent: '<i class="ion-ios-plus-outline"></i>',
    confirmCreate: true,
  },
  edit: {
    editButtonContent: '<i class="nb-edit"></i>',
    confirmSave: true
  },
    columns: {
      id: {
        title: 'Id',
        width: '15%'
      },
      name: {
        title: 'Name',
        width: '70%'
      },
      dc: {
        title: 'D/C',
        width: '15%'
      },
    }
  };

  constructor(private api: AewentrytypeService, private router: Router) { }

  ngOnInit() {
    this.api.getAewentrytypes().subscribe((data: Aewentrytype[]) => {
      this.aewentrytypes = data;
      console.log(this.aewentrytypes);
    });
  }

  openCreateDialog() {
    const url = '/aewentrytype';
   // alert(url);
    this.router.navigate([url]);
  }

  openEditDialog(event) {
   //alert(`Custom event '${event.action}' fired on row №: ${event.data.id}`);
   const url = '/aewentrytype/'+`${event.data.id}`;
  // alert(url);
   this.router.navigate([url]);
 }

 back() {
  const url = '/aeuserdashboard'
  this.router.navigate([url]);
}

csv()
{
  CsvExport.exportToCsv('EntryTypes.csv', this.aewentrytypes);
}

}
