import { Component, OnInit, AfterViewInit, ViewChild, OnChanges } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { Aeuplinecustomerservice } from './aeupline_service_customer.service';
import { Aeuplinecustomer } from './aeupline_class_customer';
import { AcdcNotificationsService } from 'acdc-notifications';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';

import { FormControl } from '@angular/forms';
import { Aeciclokeyvalue } from '../aeshared/aeciclokeyvalue';
import { AeUplineCustomerTableSettings } from './aeupline_settings_customertablesettings'


@Component({
  selector: 'ngx-aeupline',
  templateUrl: 'aeupline.component.html',
  styleUrls: ['aeupline.component.css'],
})
export class AeuplineComponent implements OnInit, AfterViewInit {
  aecustomers: Aeuplinecustomer[];
  upline: string = undefined;
  showupline: boolean = false;
  showcustomer: boolean = true;

  aecycles: Aeciclokeyvalue[];
  selectedCycleFormControl = new FormControl();
  selectedCycle: string;
  selectedCycleChanged = true;
  selectedLabel: string;

  aecustomertablesettings = AeUplineCustomerTableSettings;

  constructor(
    private router: Router,
    private customerapi: Aeuplinecustomerservice,
    private route: ActivatedRoute,
    private acdcNotificationsService: AcdcNotificationsService,
    private localstorage: LocalStorageService,
  ) {

  }


  ngOnInit(): void {
    var localciclo = this.localstorage.getItem('aecicloupline')
    this.aecycles = this.route.snapshot.data['aecicloRes'];
    if (localciclo == null) { this.selectedCycle = this.aecycles[0].key; }
    else { this.selectedCycle = localciclo; }
    this.selectedLabel = this.selectedCycle
    this.localstorage.setItem('aecicloupline', this.selectedCycle)
  }



  ngAfterViewInit() {
    this.getCustomerData();
    console.log('customers ' + this.aecustomers);
  }

  getCustomerData() {
    this.showCustomerTable(true);
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Customers data',
      timeout: 60000,
      notificationLevel: 'Info'
    });
    this.customerapi.getAeuplineCustomers(this.selectedCycle).toPromise().then((data: Aeuplinecustomer[]) => {
      this.aecustomers = data;
      this.acdcNotificationsService.deleteToast(acdcid);
    });

  }

  customerback() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  customerEditDialog(event) {
    this.localstorage.setItem("uplinesponsordn", event.data.sponsorDn);
    this.showCustomerTable(false);
    var customerDn = event.data.customerDn;
    var ciclosId = this.selectedCycle;
    console.log('aeUpline Parameters ' + customerDn + ',' + ciclosId);
    const url = '/aeuplineshow/' + `${customerDn},${ciclosId}`;
    this.router.navigate([url], {
      queryParams: { refresh: new Date().getTime() }
    });
  }

  showCustomerTable(bshow: boolean) {
    this.showcustomer = bshow;
    this.showupline = !bshow;
  }

  back() {
    this.showCustomerTable(true);
  }

  onCycleChange(event) {
    this.selectedCycle = event.key;
    this.selectedCycleChanged = true;
    this.selectedLabel = this.selectedCycle
    this.localstorage.setItem('aecicloupline', this.selectedCycle)
    this.getCustomerData();
  }


}

