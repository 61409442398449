import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import { AeciclovolumeService } from './aeciclovolume.service';


@Injectable({
    providedIn: 'root'
})


export class AeciclovolumeResolverService implements Resolve<any> {

    constructor(
        private ciclovolumeApi: AeciclovolumeService,
    ) {

    }

    resolve(): any {
        return this.ciclovolumeApi.getAeciclosvolume();
    }



}