
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';

const additionalorderproductsserviceUrl = `${environment.apiServiceUrl}/additionalorderproducts`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

@Component({
  template: `
  <nb-checkbox status="primary" [ngModel]="chk"  (change)="toggleInclude($event)"></nb-checkbox>
`,
})

export class AdditionalProductsRenderComponent implements ViewCell, OnInit {

  @Input() value: number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  public chk: boolean;
  currentGuid: string = null;
  constructor(
    private localstorage: LocalStorageService, private http: HttpClient,) {
    this.currentGuid = this.localstorage.getItem("currentguid");
  }

  ngOnInit() {
    if (this.value == 0) { this.chk = false; } else { this.chk = true; }
  }

  toggleInclude(event) {
    this.chk = event.target.checked;
    var data = {
      "orderitemId": this.rowData.orderitemId,
      "orderId": this.rowData.orderId,
      "productId": this.rowData.productId,
      "checked": this.chk,
      "currentGuid": this.currentGuid,
      "partialOrderId": 0
    };
    this.http.post<number>(additionalorderproductsserviceUrl, data, httpOptions).subscribe(
      res => {
        console.log('Result ' + res);
        this.save.emit(this.chk );
      });
  }
}