import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import { AecleancurrentguidService } from './aeshared_service_cleancurrentguid.service';


@Injectable({
    providedIn: 'root'
})


export class AecleancurrentguidResolverService implements Resolve<any> {

    constructor(
        private cleancurrentguidApi: AecleancurrentguidService,
    ) {

    }

    resolve(): any {
        return this.cleancurrentguidApi.cleanCurrentGuid();
    }
}