import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AesharedModule } from '../aeshared/aeshared.module';
import { AebonusregisterComponent } from './aebonusregister.component';
import { SelectedBonusRenderComponent } from './aebonusregister_render_selected.component';
import { OptionRenderComponent } from './aebonusregister_render_option.component';
import { ReservedBonusRenderComponent } from './aebonusregister_render_reserved.component';
import { BonusDeleteComponent } from './aebonusregister_render_deletebonus';
import { BonusImageRenderComponent } from '../aeshared/aeshared_render_bonusimage.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import {
  MatCommonModule,
  MatFormFieldModule,
} from '@angular/material';




@NgModule({
  declarations: [
    AebonusregisterComponent,
    SelectedBonusRenderComponent,
    OptionRenderComponent,
    ReservedBonusRenderComponent,
    BonusDeleteComponent,
    BonusImageRenderComponent,
  ],
  entryComponents: [
    SelectedBonusRenderComponent,
    OptionRenderComponent,
    ReservedBonusRenderComponent,    
    BonusDeleteComponent,
    BonusImageRenderComponent,
  ],
  exports: [
    AebonusregisterComponent,
  ],
  imports: [
    CommonModule,
    AesharedModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatCommonModule,
    MatFormFieldModule,
  ]
})
export class AebonusregisterModule { }
