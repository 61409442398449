import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import { AewarehouseService } from './aewarehouse.service';


@Injectable({
    providedIn: 'root'
})


export class AewarehouseshippingResolverService implements Resolve<any> {

    constructor(
        private warehouseApi: AewarehouseService,
    ) {

    }

    resolve(): any {
        return this.warehouseApi.getAewarehousesshipping();
    }

}