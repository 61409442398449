import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Aeinvoicelist } from './aeshipping_class_aeinvoicelist';
import { Aeshippingexcludelist } from './aeshipping_class_excludelist';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const shippingserviceUrl = `${environment.apiServiceUrl}/aeshippingorder`;
const excludeserviceUrl = `${environment.apiServiceUrl}/aeshippingexclude`;

@Injectable({
  providedIn: 'root'
})

export class Aeshippingservice {

  constructor(private http: HttpClient) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); 
      return of(result as T);
    };
  }

  getAeinvoices (warehouseid: number, categoryid: number, statusid:number, verify: boolean, cleanoption:number): Observable<Aeinvoicelist[]> {
    const url = `${shippingserviceUrl}/${warehouseid},${categoryid},${statusid},${verify},${cleanoption}`;
    return this.http.get<Aeinvoicelist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aeinvoices')),
        catchError(this.handleError('getAeinvoices', []))
      );
  }

  getAemiamiinvoices (): Observable<Aeinvoicelist[]> {
    const url = `${shippingserviceUrl}`;
    return this.http.get<Aeinvoicelist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aemiamiinvoices')),
        catchError(this.handleError('getAemiamiinvoices', []))
      );
  }

  getAeexclude (warehouseid: number): Observable<Aeshippingexcludelist[]> {
    const url = `${excludeserviceUrl}/${warehouseid}`;
    var excluded =  this.http.get<Aeshippingexcludelist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aeshippingexcludelist')),
        catchError(this.handleError('getAeshippingexcludelist', []))
      );
      console.log(excluded);
      return excluded
  }

  
}
