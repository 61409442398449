export const AeOrganizationCustomerTableSettings = {
  actions: {
    columnTitle: '',
    delete: false,
    add: false,
  },
  hideHeader: true,
  noDataMessage: '',
  pager: {
    display: true,
    perPage: 20,
  },

  mode: 'external',
  edit: {
    editButtonContent: '<i class="ion-ios-eye-outline"></i>',
  },
  columns: {
    customerDn: {
      title: 'DN',
      width: '15%'
    },
    customerName: {
      title: 'Name',
      width: '45%'
    },
    sponsorDn: {
      title: 'Sponsor',
      width: '15%'
    },    
    totalBonus: {
      title: 'Bonus',
      width: '15%'
    },    
  },
};