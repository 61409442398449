import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';

import 'rxjs/add/operator/toPromise';

import { Aexorder } from './aexorder_class_aexorder';
import { Aecustomeraddress } from '../aeshared/aecustomeraddress';
import { Aexorderproductslist } from './aexorder_class_productslist';
import { Aexorderproductsgiftlist } from './aexorder_class_productsgiftlist';
import { Aexorderdcproducts } from './aexorder_class_dcproducts';
import { Additionalorderproductslist } from './aexorder_class_additionalorderproductslist';
import { Transferproductslist } from './aexorder_class_transferproductslist';
import { Aexordertotals } from './aexorder_class_ordertotals';
import { Aeproductquantities } from './aexorder_class_productquantities';
import { Aeinvalidpromotions } from './aexorder_class_invalidpromotions';
import { Aevalidorderguid } from './aexorder_class_validorderguid';
import { Aeimageurl } from './aexorder_class_imageurl';
import { Aetaxjarresponse } from './aexorder_class_taxjarresponse';
import { Aeipaddress } from './aexorder_class_ipaddress';
// import { Aevalidtransfer } from './aexorder_class_validtransfer';
import { Aevalidzipcode } from './aexorder_class_validzipcode';
import { environment } from '../../../environments/environment';
// import { NumberFormatStyle } from '@angular/common';
import { Aeforeignshipping } from './aexorder_class_foreignshipping';
import { AedesktopService } from '../../pages/aeshared/aeshared_service_aedesktop.service'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const xorderserviceUrl = `${environment.apiServiceUrl}/aexorder`;
const xorderdcserviceUrl = `${environment.apiServiceUrl}/aexorderdc`;
const xordertotalsserviceUrl = `${environment.apiServiceUrl}/aexordertotals`;
const productquantitiesserviceUrl = `${environment.apiServiceUrl}/aeproductquantities`;
const xorderproductserviceUrl = `${environment.apiServiceUrl}/aexorderproducts`;
const xorderproductDCserviceUrl = `${environment.apiServiceUrl}/aexorderproductsDC`;
const xorderproductsgiftserviceUrl = `${environment.apiServiceUrl}/aexorderproductsgifts`;
const xorderdcproductsserviceUrl = `${environment.apiServiceUrl}/aexorderdcproducts`;
const xorderinvalidpromotionsserviceUrl = `${environment.apiServiceUrl}/aexorderinvalidpromotions`;
const xorderforeignshippingUrl = `${environment.apiServiceUrl}/aexorderforeignshipping`;
const additionalorderproductserviceUrl = `${environment.apiServiceUrl}/additionalorderproducts`;
const transferproductserviceUrl = `${environment.apiServiceUrl}/transferproducts`;
const taxjarvalidateserviceUrl = `${environment.apiServiceUrl}/aetaxjarvalidate`;
const zipcodevalidationserviceUrl = `${environment.apiServiceUrl}/aezipcodevalidate`;
const validorderguidserviceUrl = `${environment.apiServiceUrl}/aeorderguidvalidate`;
const imageserviceUrl = `${environment.apiServiceUrl}/aeimageurl`;

@Injectable({
  providedIn: 'root'
})
export class AexordersService {
  private url = 'api/orderRequests';

  aeaddressesJson: Aecustomeraddress[];  
  aeaddresses = [];

  constructor(
    private http: HttpClient,
    private aedesktopservice: AedesktopService,
    ) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getTransferProducts(customerdn: string): Observable<Transferproductslist[]> {
    const url = `${transferproductserviceUrl}/${customerdn}`;
    return this.http.get<Transferproductslist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Transferproductslist')),
        catchError(this.handleError('getTransferproductslist', []))
      );
  }

  getSelectedTransferProducts(customerdn: string): Observable<Transferproductslist[]> {
    const url = `${transferproductserviceUrl}/${customerdn},1`;
    return this.http.get<Transferproductslist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Transferproductslist')),
        catchError(this.handleError('getTransferproductslist', []))
      );
  }

  getAdditionalorderProducts(customerdn: string, xorderid: number, xproductid: number, currentguid: string, warehouseid: number, partialorderid: number): Observable<Additionalorderproductslist[]> {
    const url = `${additionalorderproductserviceUrl}/${customerdn},${xorderid},${xproductid},${currentguid},${warehouseid},${partialorderid}`;
    return this.http.get<Additionalorderproductslist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Additionalorderproductslist')),
        catchError(this.handleError('getAdditionalorderProducts', []))
      );
  }

  getAexorders(customerdn: string, warehouseid: number, esinventory: boolean, chkavailable: string): Observable<Aexorder[]> {
    const url = `${xorderserviceUrl}/${customerdn},00000,${warehouseid},${esinventory},${chkavailable}`;
    return this.http.get<Aexorder[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aexorder')),
        catchError(this.handleError('getAexorder', []))
      );
  }

  getAexordersDC(customerdn: string): Observable<Aexorder[]> {
    const url = `${xorderdcserviceUrl}/${customerdn},00000`;
    return this.http.get<Aexorder[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched AexorderDC')),
        catchError(this.handleError('getAexorderDC', []))
      );
  }

  // getAexmultiorderdetail(customerdn: string, currentguid: string, morderid: number): Observable<Aexorder[]> {
  //   const url = `${xmultiorderdetailserviceUrl}/${customerdn},00000,${currentguid},${morderid}`;
  //   return this.http.get<Aexorder[]>(url, httpOptions)
  //     .pipe(
  //       tap(heroes => console.log('fetched Aexmultiorderder')),
  //       catchError(this.handleError('getAexmultiorder', []))
  //     );
  // }

  getAexordertotals(xorderid: number, xproductid: number): Observable<Aexordertotals> {
    const url = `${xordertotalsserviceUrl}/${xorderid},${xproductid},0`;
    return this.http.get<Aexordertotals>(url, httpOptions);
  }

  getAeproductquantities(productid: number): Observable<Aeproductquantities> {
    const url = `${productquantitiesserviceUrl}/${productid}`;
    return this.http.get<Aeproductquantities>(url, httpOptions);
  }
 
  getIpaddress(): Observable<Aeipaddress> {
    const url = 'https://api.ipify.org?format=json';
    return this.http.get<Aeipaddress>(url, httpOptions);
  }

  getAexorderproductgifts(xorderid: number, xproductid: number, currentguid: string, customerdn: string){
    const url = `${xorderproductsgiftserviceUrl}/${xorderid},${xproductid},${currentguid},${customerdn}`;
    console.log('urlproductgifts ' + url);
    return this.http.get<Aexorderproductsgiftlist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aexordergiftproducts')),
        catchError(this.handleError('getAexordergiftproducts', []))
      );

  }

  getAexorderdcproducts(xorderid: number, xproductid: number, currentguid: string, customerdn: string, includesuperpacks: boolean){
    const url = `${xorderdcproductsserviceUrl}/${xorderid},${xproductid},${currentguid},${customerdn},${includesuperpacks}`;
    console.log('url ' + url);
    return this.http.get<Aexorderdcproducts[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aedcproducts')),
        catchError(this.handleError('getAedcproducts', []))
      );

  }

  
  getAexorderproductsDC(xorderid: number, xproductid: number, invoiceid: number, warehouseid: number, xproductquantity: number): Observable<Aexorderproductslist[]> {
    const url = `${xorderproductDCserviceUrl}/${xorderid},${xproductid},${invoiceid},${warehouseid},${xproductquantity}`;
    console.log('url ' + url);
    return this.http.get<Aexorderproductslist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aexorderproductsdc')),
        catchError(this.handleError('getAexorderproductsdc', []))
      );
  }



  getAexorderproducts(xorderid: number, xproductid: number, invoiceid: number, warehouseid: number): Observable<Aexorderproductslist[]> {
    const url = `${xorderproductserviceUrl}/${xorderid},${xproductid},${invoiceid},${warehouseid}`;
    console.log('url ' + url);
    return this.http.get<Aexorderproductslist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aexorderproducts')),
        catchError(this.handleError('getAexorderproducts', []))
      );
  }

  getInvalidpromotions(customerdninvoice: string, warehouseid: number): Observable<Aeinvalidpromotions> {
    const url = `${xorderinvalidpromotionsserviceUrl}/${customerdninvoice},${warehouseid}`;
    console.log('url ' + url);
    return this.http.get<Aeinvalidpromotions>(url, httpOptions);
  }

  getForeignshipping(xorderid: number, xproductid: number, countryid: number,): Observable<Aeforeignshipping> {
    const url = `${xorderforeignshippingUrl}/${xorderid},${xproductid},${countryid}`;
    console.log('url ' + url);
    return this.http.get<Aeforeignshipping>(url, httpOptions);
  }
  
  getTaxjarvalidation(zipcode: string): Observable<Aetaxjarresponse> {
    const url = `${taxjarvalidateserviceUrl}/${zipcode}`;
    return this.http.get<Aetaxjarresponse>(url, httpOptions);
  }

  getImageurl(imageSku: string): Observable<Aeimageurl> {
    const imagesku = this.aedesktopservice.normalizeString(imageSku);
    const url = `${imageserviceUrl}/${imagesku}`;
    return this.http.get<Aeimageurl>(url, httpOptions);
  }

  getZipcodevalidation(countryid: number, stateabbreviation: string, zipcode: string): Observable<Aevalidzipcode> {
    const url = `${zipcodevalidationserviceUrl}/${countryid},${stateabbreviation},${zipcode}`;
    return this.http.get<Aevalidzipcode>(url, httpOptions);
  }

  getValidorderguid(xorderid: number, xproductid: number, currentguid: string, invoicecustomerdn: string, desksessionid: string, partialorderid:number): Observable<Aevalidorderguid> {
    const url = `${validorderguidserviceUrl}/${xorderid},${xproductid},${currentguid},${invoicecustomerdn},${desksessionid},${partialorderid}`;
    console.log('url ' + url);
    return this.http.get<Aevalidorderguid>(url, httpOptions);
  }
}
