import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
@Component({
    selector: 'ngx-selectgroup',
    template: `<a href="javascript:void(0)" (click)="onClick()">
        <img *ngIf="selectedHeadType!=rowdataHeadType" width="30" height="30" src="../../../assets/images/unchecked.jpg">,
        <img *ngIf="selectedHeadType==rowdataHeadType" width="30" height="30" src="../../../assets/images/checked.jpg"></a>`,
    })
    export class SelectHeadTypeComponent implements OnInit {
        public renderValue: string;
        public selectedHeadType: number;
        public rowdataHeadType: number;
    
        @Input() value: string | number;
        @Input() rowData: any;
      
        @Output() save: EventEmitter<any> = new EventEmitter();

        constructor(
            private localstorage: LocalStorageService,
        ) {
            this.selectedHeadType = this.localstorage.getItem("selectedheadtype");
        }
    
        ngOnInit() {
            this.renderValue = this.value.toString();
            if (this.rowData.headType == 'noclub') {this.rowdataHeadType = 0}
            else if (this.rowData.headType == 'club24') {this.rowdataHeadType = 24}
            else if (this.rowData.headType == 'club24h') {this.rowdataHeadType = 25}
            else if (this.rowData.headType == 'club50') {this.rowdataHeadType = 50}
            else if (this.rowData.headType == 'club50h') {this.rowdataHeadType = 55}
        }
    
        onClick() {
            var response = {
                id: this.rowData.id,
             }
            this.save.emit(response);
        }
        
    }
    