import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { AcdcNotificationsService } from 'acdc-notifications';
import { Aedesktopversion } from '../../pages/aeshared/aedesktopversion';
import { MatDialog, } from '@angular/material';
import { ContinuationDialog } from '../aeshared/aecontinuationdialog.component';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const cleancurrentguidserviceUrl = `${environment.apiServiceUrl}/aecleancurrentguid/`;
const aedesktopversionUrl = `${environment.apiServiceUrl}/aedesktopversion/`;

@Injectable({
  providedIn: 'root'
})

export class AecleancurrentguidService {

  currentGuid: string = null;
  currentCustomerDn: string = null;
  sessionId: string = null;
  aedesktopVersion: string = '0';
  desktopVersion: string = '1';

  constructor(
    private http: HttpClient,
    private localstorage: LocalStorageService,
    private acdcNotificationsService: AcdcNotificationsService,
    private dialog: MatDialog,
  ) {
  }


  cleanCurrentGuid(): any {
    let result = 0;
    this.desktopVersion = "0";
    this.aedesktopVersion = this.localstorage.getItem("aedesktopversion");
    this.currentGuid = this.localstorage.getItem("currentguid");
    this.sessionId = this.localstorage.getItem("desksessionid");
    this.currentCustomerDn = this.localstorage.getItem("cleancustomerdn");
    this.getDesktopVersion().subscribe((datadev: Aedesktopversion) => {
      this.desktopVersion = datadev.desktopVersion;
      if (this.aedesktopVersion != this.desktopVersion) {
        const dialogRef = this.dialog.open(ContinuationDialog, {
          maxWidth: "500px",
          data: {
            message: "UserDesktop Version Outdated" +
              " \n Please close your windows and restart" ,
            message2: "Versión del UserDesktop ha vencido" +
            " \n Por favor cierre todas sus ventanas y renicie"  ,
          }
        });
        return result;
      }
      if (this.currentGuid != null) {
        var dataG = {
          "currentCustomerDn": this.currentCustomerDn,
          "currentGuid": this.currentGuid,
          "sessionId": this.sessionId
        };
        console.log('Cleaning ' + dataG);
        this.http.post<number>(cleancurrentguidserviceUrl, dataG, httpOptions).subscribe(
          res => {
            console.log('Cleaned Current Guid ' + res);
            result = res;
            return result
          });
      } else {
        console.log('Cleaning null CurrentGuid');
        return result;
      }
    });
  }

  getDesktopVersion(): Observable<Aedesktopversion> {
    const url = `${aedesktopversionUrl}`;
    return this.http.get<Aedesktopversion>(url, httpOptions);
  }

}
