import { Component, OnInit, AfterViewInit, ViewChild, OnChanges } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { AcdcNotificationsService } from 'acdc-notifications';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';

import { FormControl } from '@angular/forms';
import { Aeciclokeyvalue } from '../aeshared/aeciclokeyvalue';


@Component({
  selector: 'ngx-aeorganizationuser',
  templateUrl: 'aeorganizationuser.component.html',
  styleUrls: ['aeorganizationuser.component.css'],
})
export class AeorganizationuserComponent implements OnInit, AfterViewInit {
  organization: string = undefined;
  showdisabled: boolean = false;
  showcycle: boolean = true;

  aecycles: Aeciclokeyvalue[];
  selectedCycleFormControl = new FormControl();
  selectedCycle: string;
  selectedCycleChanged = true;
  selectedLabel: string;
  isdlenabled: string;
  customerDn: string;
  onlywithVolume: boolean = false;
  withVolume: string = 'n';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private acdcNotificationsService: AcdcNotificationsService,
    private localstorage: LocalStorageService,
  ) {

  }


  ngOnInit(): void {
    this.isdlenabled = this.localstorage.getItem("isdlenabled");    
    var localciclo = this.localstorage.getItem('aecicloorganization')
    this.aecycles = this.route.snapshot.data['aecicloRes'];
    if (localciclo == null) { this.selectedCycle = this.aecycles[0].key; }
    else { this.selectedCycle = localciclo; }
    this.selectedLabel = this.selectedCycle
    this.localstorage.setItem('aecicloorganization', this.selectedCycle)
  }



  ngAfterViewInit() {
    this.customerDn = this.localstorage.getItem("customerdn");
    var sponsorDn = this.localstorage.getItem("customerdn");
    this.localstorage.setItem("treesponsordn", sponsorDn);
   
  }

  customerback() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  back() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  onCycleChange(event) {
    this.selectedCycle = event.key;
    this.selectedCycleChanged = true;
    this.selectedLabel = this.selectedCycle
    this.localstorage.setItem('aecicloorganization', this.selectedCycle)
  
   }
   showOrganization() {
    const url = '/aetreeshow/' + `${this.customerDn},${this.selectedCycle},${this.withVolume},n`;
    this.router.navigate([url], {
      queryParams: { refresh: new Date().getTime() }
    });

   }

   onvolumeCheck(checked: boolean){
     this.onlywithVolume = checked;
     if (this.onlywithVolume) {this.withVolume = 'y'} else {this.withVolume = 'n'}
   }
}

