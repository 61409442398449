import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
    selector: 'ngx-showgroup',
    template: `<a href="javascript:void(0)" (click)="onClick()">
        <img *ngIf="headType==0"  width="25" height="25" src="../../../assets/images/unchecked.jpg">
        <img *ngIf="headType==24" width="40" height="40" src="../../../assets/images/club24.jpg">
        <img *ngIf="headType==25" width="40" height="40" src="../../../assets/images/club24h.jpg">
        <img *ngIf="headType==50" width="40" height="40" src="../../../assets/images/club50.jpg">
        <img *ngIf="headType==55" width="40" height="40" src="../../../assets/images/club50h.jpg">
        </a>`,
})
export class ShowHeadTypeComponent implements OnInit {
    public renderValue: string;
    public hasGroupHead: boolean = false;
    public headType: number = 0;
    public customerDn: string;
    public isGroupHead: boolean = false;

    @Input() value: string;
    @Input() rowData: any;

    @Output() save: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
        this.renderValue = this.value;
        this.customerDn = this.rowData.customerDn;
        this.headType = this.rowData.headType;
    }

    onClick() {
        if (this.headType == 55 || this.headType == 25) {this.isGroupHead = true;}
        var response = {
            customerDn: this.customerDn,
            headType: this.headType,
            isgroupHead: this.isGroupHead,
        }
        this.save.emit(response);

    }

}
