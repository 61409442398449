import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Aeshippingservice } from './aeshipping_service_data.service';

@Injectable({
    providedIn: 'root'
})


export class AeshippingexcludeResolverService implements Resolve<any> {

    constructor(
        private shippingserviceApi: Aeshippingservice,
    ) { }

    resolve(): any {
        return this.shippingserviceApi.getAeexclude(1);
    }
}