import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { LocalStorageService } from '../../aeshared/aeshared_service_localstorage.service';
import { Aebonusprevioustotals } from '../../aebonusregister/aebonusregister_class_aebonusprevioustotals';
import { Aebonusregisterservice } from '../../aebonusregister/aebonusregister_service_data.service';

import { CsvExport } from '../../aeshared/aeshared_class_csvexport';

@Component({
  selector: 'ngx-aeformcantidadtotals',
  templateUrl: './aeformcantidadtotals.component.html',
  styleUrls: ['./aeformcantidadtotals.component.scss']
})
export class AeformcantidadtotalsComponent implements OnInit {

  static id = 100;

  mode = 'create';
  customerDn = "";
  ciclosId = "";
  aebonusprevioustotals: Aebonusprevioustotals[];

  previoustotalssettings = {
    actions: {
      columnTitle: '',
      delete: false,
      add:false,
      edit:false,
      },  
    mode: 'external',
    hideHeader: true,
    pager: {
      display: true,
      perPage: 10,
    },
    columns: {
      codigoBono: {
        title: 'Code',
        width: '20%'
      },
      months: {
        title: 'Months',
        width: '20%'
      }, 
      quantity: {
        title: 'Quantity',
        width: '20%' 
      },
    }
  };

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<AeformcantidadtotalsComponent>,
    private localstorage: LocalStorageService,
    private bonusregisterapi: Aebonusregisterservice,
    ) {
  }


  ngOnInit() {

    this.customerDn = this.localstorage.getItem("bonuspluscustomerdn");
    this.ciclosId = this.localstorage.getItem("bonusplusciclosid");
    this.bonusregisterapi.getBonusprevioustotals(this.customerDn, this.ciclosId).subscribe((previoustotalsdata: Aebonusprevioustotals[]) => {
      this.aebonusprevioustotals = previoustotalsdata;
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  csvPreviousBonus() {
    let csvfile = 'BonusTotals_Dn_' + this.customerDn + '_Cycle_' + this.ciclosId + '.csv';
    CsvExport.exportToCsv(csvfile, this.aebonusprevioustotals);
  }


}




