import { Component, AfterViewInit, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AebonusService } from './aebonus_service.service';
import { Aebonus } from './aebonus_class_bonus';
import { Aebonusdetail } from './aebonus_class_detail';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { AcdcNotificationsService } from 'acdc-notifications';
import { CsvExport } from '../aeshared/aeshared_class_csvexport';
import { Aeciclokeyvalue } from '../aeshared/aeciclokeyvalue';
import { AeBonusTableSettings } from './aebonus_settings_tablesettings'
import { AeBonusDetailTableSettings } from './aebonus_settings_detailtablesettings'

@Component({
  selector: 'ngx-aebonususer',
  templateUrl: './aebonususer.component.html',
  styleUrls: ['./aebonus.component.scss']
})
export class AebonususerComponent implements AfterViewInit, OnInit {

  databonus: Aebonus[] = [];
  databonusdetail: Aebonusdetail[] = [];

  isLoadingResults = true;

  aebonus: Aebonus[] = undefined;
  aebonusdetail: Aebonusdetail[] = undefined;

  showbonus: boolean = true;
  showbonusdetail: boolean = false;


  aecycles: Aeciclokeyvalue[];
  selectedCycleFormControl = new FormControl();
  selectedCycle: string;
  selectedCycleChanged = true;
  selectedLabel: string;


  selectedCustomerDn = ''
  selectedDetalleCustomerDn = ''

  bonustablesettings = AeBonusTableSettings;
  bonusdetailtablesettings = AeBonusDetailTableSettings;
 

  constructor(private bonusapi: AebonusService,
    private router: Router, private localstorage: LocalStorageService,
    private acdcNotificationsService: AcdcNotificationsService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    var localciclo = this.localstorage.getItem('aeciclobonus')
    this.aecycles = this.route.snapshot.data['aecicloRes'];
    if (localciclo == null) { this.selectedCycle = this.aecycles[0].key; }
    else { this.selectedCycle = localciclo; }
    this.selectedLabel = this.selectedCycle
    this.localstorage.setItem('aeciclobonus', this.selectedCycle)
  }


  ngAfterViewInit() {
    this.showBonusTable(1);
  }

  bonusEditDialog(event) {
    this.localstorage.setItem('returnurl', this.router.url)
    this.selectedDetalleCustomerDn = event.data.customerDn;
    this.aebonusdetail = undefined
    this.showBonusTable(2)
  }

  showBonusTable(bshow: number) {
    if (bshow == 1) {
      this.showbonus = true;
      this.showbonusdetail = false;
      if (this.aebonus == undefined || this.selectedCycleChanged) {
        if (this.selectedCycle != undefined) {
          const acdcid: string = this.acdcNotificationsService.toast({
            title: 'Please wait... Loading Bonus Summary data',
            timeout: 60000,
            notificationLevel: 'Info'
          });
          this.selectedCustomerDn = this.localstorage.getItem("customerdn");
          if (this.selectedCustomerDn == 'D00001') {this.selectedCustomerDn = 'D999';}

          this.bonusapi.getAebonus(this.selectedCustomerDn, this.selectedCycle)
            .subscribe((data: Aebonus[]) => {
              this.aebonus = data;
              this.acdcNotificationsService.deleteToast(acdcid);
              if (this.aebonus.length == 0) {
                this.acdcNotificationsService.toast({
                  title: 'Warning... No Bonus Summary data',
                  timeout: 3000,
                  notificationLevel: 'Warn'
                });
              }
            });
          this.selectedCycleChanged = false;
        }
      }
    } else
      if (bshow == 2) {
        this.showbonus = false;
        this.showbonusdetail = true;
        const acdcid: string = this.acdcNotificationsService.toast({
          title: 'Please wait... Loading Bonus Detail data',
          timeout: 60000,
          notificationLevel: 'Info'
        });
        this.bonusapi.getAebonusdetail(this.selectedDetalleCustomerDn, this.selectedCycle)
          .subscribe((data: Aebonusdetail[]) => {
            this.aebonusdetail = data;
            this.acdcNotificationsService.deleteToast(acdcid);
            if (this.aebonusdetail.length == 0) {
              this.acdcNotificationsService.toast({
                title: 'Warning... No Bonus Detail data',
                timeout: 3000,
                notificationLevel: 'Warn'
              });
            }
          });
      }
  }

  bonusback() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  bonusdetailback() {
    this.showBonusTable(1);
  }

  csvBonus() {
    let csvfile = 'BonusSummary_Dn_' + this.selectedCustomerDn + '_Cycle_' + this.selectedCycle + '.csv';
    CsvExport.exportToCsv(csvfile, this.aebonus);
  }
  
  csvBonusDetail() {
    let csvfile = 'BonusDetail_Dn_' + this.selectedDetalleCustomerDn + '_Cycle_' + this.selectedCycle + '.csv';
    CsvExport.exportToCsv(csvfile, this.aebonusdetail);
  }

  onCycleChange(event) {
    this.selectedCycle = event.key;
    this.selectedCycleChanged = true;
    this.selectedLabel = this.selectedCycle 
    this.showBonusTable(1);
  }

}
