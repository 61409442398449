import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map, share } from 'rxjs/operators';
import { Aewalletcustomer } from './aewallet_class_customer';
import { Aewalletdownline } from './aewallet_class_downline';
import { environment } from '../../../environments/environment';
import { AcdcNotificationsService } from 'acdc-notifications';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const walletcustomerserviceUrl = `${environment.apiServiceUrl}/aecustomer/00000,00000,w`;
const walletdownlineserviceUrl = `${environment.apiServiceUrl}/aedownlinecreditlist`;

@Injectable({
  providedIn: 'root'
})

export class AewalletcustomerService {

  constructor(private http: HttpClient,
    private acdcNotificationsService: AcdcNotificationsService) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getAewalletCustomers (): Observable<Aewalletcustomer[]> {
    const url = `${walletcustomerserviceUrl}`;
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Customer Data',
      timeout: 6000,
      notificationLevel: 'Info'
    });
    return this.http.get<Aewalletcustomer[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aewalletcustomers')),
        catchError(this.handleError('getAewalletcustomers', [])),
        share()
      );
  }

  getAewalletCrudCustomers (): Observable<Aewalletcustomer[]> {
    const url = `${walletcustomerserviceUrl}`;
    return this.http.get<Aewalletcustomer[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aewalletcustomers')),
        catchError(this.handleError('getAewalletcustomers', [])),
        share()
      );
  }

  getAewalletDownline (customerdn: string, ciclosid:string): Observable<Aewalletdownline[]> {
    const url = `${walletdownlineserviceUrl}/${customerdn},${ciclosid}`;
    return this.http.get<Aewalletcustomer[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aewalletcustomerdownline')),
        catchError(this.handleError('getAewalletdownline', [])),
        share()
      );
  }

  
}
