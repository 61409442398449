
import { Component, Input, OnInit } from '@angular/core';
//import { ViewCell } from 'ng2-smart-table';


@Component({
  template: `
        <div class="nodelist" tooltip="{{rowData.productName}}">{{renderValue}}</div>
      `,
})

export class PriceRenderComponent implements OnInit {
  public renderValue;
  @Input() value;
  @Input() rowData: any;
  constructor() { }
  ngOnInit() {
    this.renderValue = this.value;
  }
}