import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Aedistributioncenterorders } from './aemasterdistribution_class_aedistributioncenterorders';
import { Aedistributioncenterlist } from './aemasterdistribution_class_aedistributioncenterlist';
import { Aedistributioncenteravailable } from './aemasterdistribution_class_aedistributioncenteravailable';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const ordersserviceUrl = `${environment.apiServiceUrl}/aedistributioncenterorders`;
const centersserviceUrl = `${environment.apiServiceUrl}/aedistributioncenterlist`;
const availableserviceUrl = `${environment.apiServiceUrl}/aedistributioncenteravailable`;


@Injectable({
  providedIn: 'root'
})

export class Aedistributioncenterordersservice {

  constructor(private http: HttpClient) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); 
      return of(result as T);
    };
  }

  getAedistributioncenterorders (): Observable<Aedistributioncenterorders[]> {
    const url = `${ordersserviceUrl}`;
    return this.http.get<Aedistributioncenterorders[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aedistributioncenterorders')),
        catchError(this.handleError('getAedistributioncenterorders', []))
      );
  }

  getAedistributioncenteravailable (orderid: number, distributioncenterid: number): Observable<Aedistributioncenteravailable[]> {
    const url = `${availableserviceUrl}/${orderid},${distributioncenterid}`;
    return this.http.get<Aedistributioncenteravailable[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aedistributioncenteravailable')),
        catchError(this.handleError('Aedistributioncenteravailable', []))
      );
  }
  
  getAedistributioncenterslist (orderid: number, position: string): Observable<Aedistributioncenterlist[]> {
    const url = `${centersserviceUrl}/${orderid},${position}`;
    return this.http.get<Aedistributioncenterlist[]>(url, httpOptions).pipe(
        tap(_ => console.log(`fetched distributioncenters customerdn=${orderid}`)),
        catchError(this.handleError<Aedistributioncenterlist[]>(`getAedistributioncenters customer=${orderid}`))
      );
  }




  
}
