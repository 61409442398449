import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AesharedModule } from '../aeshared/aeshared.module';
import { AemasterdistributionComponent } from './aemasterdistribution.component';
import { DefaultCenterRenderComponent } from './aemasterdistribution_render_defaultcenter.component';

@NgModule({
  declarations: [
    AemasterdistributionComponent,
    DefaultCenterRenderComponent,
  ],
  entryComponents: [
    DefaultCenterRenderComponent,
  ],
  exports: [
    AemasterdistributionComponent,

  ],
  imports: [
    CommonModule,
    AesharedModule,
  ]
})
export class AemasterdistributionModule { }
