
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { environment } from '../../../environments/environment';

const dcenterserviceUrl = `${environment.apiServiceUrl}/aexorderdcproducts`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

@Component({
  template: `
  <nb-checkbox status="primary" [ngModel]="chk"  (change)="toggleInclude($event)"></nb-checkbox>
`,
})

export class DistributionRenderComponent implements ViewCell, OnInit {

  @Input() value: number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  public chk: boolean;
  orderId: number = 0;
  groupId: number = 0;
  currentGuid: string = null;

  constructor(
    private localstorage: LocalStorageService, private http: HttpClient,) {
    this.currentGuid = this.localstorage.getItem("currentguid");
    this.orderId = this.localstorage.getItem("dcxorderid");
  }

  ngOnInit() {
    if (this.value == 0) { this.chk = false; } else { this.chk = true; }
    console.log(this.chk);
  }

  toggleInclude(event) {
    this.chk = event.target.checked;
    var data = {
      "orderId": this.orderId,
      "groupId": this.rowData.groupId,
      "pickupinDc": this.chk,
      "currentGuid": this.currentGuid
    };
    console.log(data);
    this.http.post<number>(dcenterserviceUrl, data, httpOptions).subscribe(
      res => {
        console.log('Checked ' + res);
        if (res > 0) { this.rowData.checked = this.chk;}
        this.save.emit(this.rowData);
      });
  }
}