import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AetreeshowService} from './aetreeshow.service';
import { Aebonusoperator } from './aetreeshow_class_aebonusoperator';

@Component({
  selector: 'ngx-operatordialog',
  templateUrl: './aeoperatordialog.component.html',
  styleUrls: ['./aeoperatordialog.component.scss']
})
export class AeoperatordialogComponent implements OnInit {

  form: FormGroup;
  aebonusoperator: Aebonusoperator[];

  constructor(private formBuilder: FormBuilder,
  public dialogRef: MatDialogRef<AeoperatordialogComponent>,
  private api: AetreeshowService,
  @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.api.getBonusOperator().subscribe((opedata: Aebonusoperator[]) => {
      this.aebonusoperator = opedata;
    });
    
    this.form = this.formBuilder.group({
      selectedOperator: []
    });
  }

  submit(form) { 
    this.dialogRef.close(form.value);
  }
}