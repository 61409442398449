import { Component, OnInit, AfterViewInit, ViewChild, OnChanges, ElementRef } from '@angular/core';
import { TreeComponent, TreeNode, TreeModel, TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from 'angular-tree-component';
import { Router, ActivatedRoute, } from '@angular/router';
import { AedownlineService } from './aedownline_service';
import { AcdcNotificationsService } from 'acdc-notifications';
import { environment } from '../../../environments/environment';
import { Aedownlinenode } from './aedownline_class_node';
const actionMapping: IActionMapping = {
  mouse: {
    contextMenu: (tree, node, $event) => {
      $event.preventDefault();
      alert(`context menu for ${node.data.name}`);
    },
    dblClick: (tree, node, $event) => {
      if (node.hasChildren) {
        TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
      }
    },
    click: (tree, node, $event) => {
      $event.shiftKey
        ? TREE_ACTIONS.TOGGLE_ACTIVE_MULTI(tree, node, $event)
        : TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event)
    }
  },
  keys: {
    [KEYS.ENTER]: (tree, node, $event) => alert(`This is ${node.data.name}`)
  }
};

const downlineserviceUrl = `${environment.apiServiceUrl}/aedownline`;
@Component({
  selector: 'ngx-downline',
  templateUrl: 'aedownline.component.html',
  styles: [],

})
export class AedownlineComponent implements AfterViewInit {

  @ViewChild('tree', { static: false }) private treeref: TreeComponent;


  nodos: Aedownlinenode[];
  nodes = [];
  selectedCustomerDn = 'D3535';
  selectedCycle = '00007';
  selectedCustomerId = 18178;
  shownodes: boolean = true;

  constructor(
    private acdcNotificationsService: AcdcNotificationsService,
    private downlineapi: AedownlineService,
  ) {
    this.getDownlineData();
  }

  ngAfterViewInit() {
    console.log(this.nodes);
    //    console.log(this.treecomponent.nodes[0].id);
  }

  getDownlineData() {
    let firstNode: boolean = true;
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Downline data',
      timeout: 60000,
      notificationLevel: 'Info'
    });
    this.downlineapi.getDownline(this.selectedCustomerId, this.selectedCycle, 'y').toPromise().then((data: Aedownlinenode[]) => {
      console.log(data);
      let nodeLevel: number;
      let previousLevel: number;
      data.forEach(element => {
        let nodo=  { id: element.id, name: element.customer, children: [] };
        nodeLevel = element.depth;
        if (firstNode) {
          this.nodes.push(nodo);
          firstNode = false;
          previousLevel = element.depth;
          this.treeref.treeModel.update();
          console.log(element.id + ' added found');
        }
        else {
          this.treeref.treeModel.doForAll((node) =>
           {
              node.children.forEach(childnode => {
               if (childnode.id == element.sponsorId)
               {
                  console.log(element.sponsorId + ' childnode found');
                  childnode.children.push(nodo);
                  this.treeref.treeModel.update();
                }

              })
           }
          );
        }

        //   var parentNode = this.nodes.find(x => x.id === element.sponsorId);
        //   if (parentNode !== undefined) {
        //     console.log(element.sponsorId + ' parent found');
        //     parentNode.children.push(nodo);
        //     this.treeref.treeModel.update();
        //   }
        //   else {
        //     var childNode = this.nodes[0].children.find(x => x.id === element.sponsorId);
        //     if (childNode !== undefined) {
        //       console.log(element.sponsorId + ' child found');
        //       childNode.children.push(nodo);
        //       this.treeref.treeModel.update();
        //     }
        //     else
        //       this.nodes[0].children.forEach(childnode => {
        //         var grandchild = childnode.children.find(x => x.id === element.sponsorId);
        //         if (grandchild !== undefined) {
        //           console.log(element.sponsorId + ' grand child found');
        //           grandchild.children.push(nodo);
        //           this.treeref.treeModel.update();
        //         }
        //       });
        //   }
      });
      this.acdcNotificationsService.deleteToast(acdcid);
      this.shownodes = true;
    });


  }

  addNode(tree: any) {
    this.nodes[0].children.push({

      id: 15, name: 'a new child'
    });
    console.log(this.nodes);
    tree.treeModel.update();
    tree.treeModel.getNodeById('15').setActiveAndVisible();

  }

  go($event: any) {
    $event.stopPropagation();
    alert('this method is on the app component');
  }

  childrenCount(node: TreeNode): string {
    return node && node.children ? `(${node.children.length})` : '';
  }


  onEvent(event: any) {
    console.log(event);
  }

  onInitialized(tree: any) {

    tree.treeModel.getNodeById('6').setActiveAndVisible();
  }
}
  //   {
  //     name: 'root1',
  //     children: [
  //       { name: 'child1' },
  //       { name: 'child2' }
  //     ]
  //   },
  //   {
  //     name: 'root2',
  //     children: [
  //       { name: 'child2.1', children: [] },
  //       { name: 'child2.2', children: [
  //         {name: 'grandchild2.2.1'}
  //       ] }
  //     ]
  //   },
  //   { name: 'root3' },
  //   { name: 'root4', children: [] },
  //   { name: 'root5', children: null }
  // ];
