import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { Aeciclo_Crud_Component } from './aeciclo_crud.component';
import { AecicloComponent } from './aeciclo.component';
import { AesharedModule } from '../aeshared/aeshared.module';


@NgModule
  (
    {
      imports: [
        CommonModule,
        AesharedModule,

      ],
      declarations: [
        Aeciclo_Crud_Component,
        AecicloComponent,
      ],
      exports: [
        Aeciclo_Crud_Component,
        AecicloComponent,
      ],
    }
  )
export class AeciclosModule { }
