export const AeCicloVolumeTableSettings = {
  actions: {
    columnTitle: '',
    delete: false,
    add: false,
    edit: false,
  },
  hideHeader: true,
  noDataMessage: '',
  pager: {
    display: false,
    perPage: 6,
  },
  columns: {
    descripCiclos: {
      title: 'Cycle',
      width: '30%'
    },
    vpersonal: {
      title: 'VP',
      width: '30%'
    },
    vgrupo: {
      title: 'VG',
      width: '30%'
    },
  },
};
