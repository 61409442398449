import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Aeupline } from './aeuplineshow_classupline'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CsvExport } from '../aeshared/aeshared_class_csvexport';
import { AcdcNotificationsService } from 'acdc-notifications';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';

@Component({
  selector: 'ngx-aeuplineshow',
  templateUrl: './aeuplineshow.component.html',
  styleUrls: ['./aeuplineshow.component.scss']
})

export class AeuplineshowComponent implements OnInit {

  data: Aeupline[] = [];
  isLoadingResults = true;
  aeupline: Aeupline[];
  customerDn: string;
  ciclosId: string;
  sponsorDn: string = undefined;
  uplineSponsorDn: string;

  aeuplinetablesettings = {
    actions: {
      add: false,
      delete: false,
      edit: false,
    },
    mode: 'external',
    hideHeader: true,
    pager: {
      display: true,
      perPage: 20,
    },
    columns: {
      nivel: {
        title: 'Depth',
        width: '15%'
      },      
      customerDn: {
        title: 'Dn',
        width: '10%'
      },
      customerName: {
        title: 'Distributor',
        width: '40%'
      },
      sponsorDn: {
        title: 'Sponsor',
        width: '40%'
      },      
      vp: {
        title: 'Vp',
        width: '15%'
      },
   
      vg: {
        title: 'Vg',
        width: '15%'
      },
    }
  };


  constructor(
    // private api: AeuplineshowService,
    private router: Router,  private route: ActivatedRoute,
    private acdcNotificationsService: AcdcNotificationsService,
    private localstorage: LocalStorageService,
  ) { }

  
  ngOnInit() {
    this.uplineSponsorDn = this.localstorage.getItem("uplinesponsordn");
    this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      const refresh = paramMap.get('refresh');
      if (refresh) {
        var parameters = this.route.snapshot.paramMap.get('aeuplineparam');
        this.customerDn = parameters.split(',',2)[0];
        this.ciclosId = parameters.split(',',2)[1];
        if (this.customerDn == undefined) {
          alert('customer undefined');
        }
        else {
          console.log('aeuplineshow constructor parameters ' + this.customerDn + ',' + this.ciclosId);
          this.aeupline = this.route.snapshot.data['aeuplineRes'];
        }
      }
    })
  }
  ngAfterViewInit() {
    this.sponsorDn = this.localstorage.getItem("uplinesponsordn");
  }

  showdownline(customerdn, members) {
    if (members == 0) {
      this.acdcNotificationsService.toast({
        title: 'Distributor doesn\'t have a downline',
        timeout: 3000,
        notificationLevel: 'Info'
      });
    }
    else if (customerdn == this.customerDn) {
      this.acdcNotificationsService.toast({
        title: 'Already showing downline for distributor ' + customerdn,
        timeout: 3000,
        notificationLevel: 'Info'
      });
    }

    else {
      const url = '/aeuplineshow/' + `${customerdn},${this.ciclosId}`;
      this.router.navigate([url]
        , {
          queryParams: { refresh: new Date().getTime() }
        }
      );
    }
  }

  back() {
      const url = '/aeupline'
      this.router.navigate([url]);
  }

  csv() {
    CsvExport.exportToCsv('Upline.csv', this.aeupline);
  }

  rowselect(event) {
    if (event.data.downline > 0) {
      this.sponsorDn = event.data.sponsorDn;
    }
  }



}
