import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';

import 'rxjs/add/operator/toPromise';

import { Aeciclokeyvalue } from './aeciclokeyvalue';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const aecicloserviceUrl = `${environment.apiServiceUrl}/aeciclo`;

@Injectable({
  providedIn: 'root'
})
export class AeciclokeyvalueService {

  aecyclesJson: Aeciclokeyvalue[];
  aecycles = [];

  constructor(private http: HttpClient,private localstorage: LocalStorageService,) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  getAeciclos(): Observable<Aeciclokeyvalue[]> {
    let isadmin = this.localstorage.getItem('isadmin');
    let iscservice = this.localstorage.getItem('iscservice');
    const url =  `${aecicloserviceUrl}/${isadmin},${iscservice}`;
    return this.http.get<Aeciclokeyvalue[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aeciclos')),
        catchError(this.handleError('getaeciclos', []))
      );
  }

  getAeciclosAll(): Observable<Aeciclokeyvalue[]> {
    let isadmin = this.localstorage.getItem('isadmin');
    let iscservice = this.localstorage.getItem('iscservice');
    const url =  `${aecicloserviceUrl}/True,True`;
    return this.http.get<Aeciclokeyvalue[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aeciclos')),
        catchError(this.handleError('getaeciclos', []))
      );
  }

  getAeciclosData(): any {
    return this.getAeciclos().subscribe((data: Aeciclokeyvalue[]) => {
      data.forEach(element => { this.aecycles.push(element); });
    })
  }


}
