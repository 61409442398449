import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AepromosService } from './aepromos_service.service';
import { Aepromotions } from './aepromos_class_record'
import { Aepromocomponentlist } from './aepromo_component_class_list'
import { Aepromorequirementlist } from './aepromo_requirement_class_list'
import {PromoComponentRenderComponent} from './aepromo_render_promo_component.component'
import {PromoComponentRenderRequirement} from './aepromos_render_promo_requirement.component'
import { Router } from '@angular/router';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { CsvExport } from '../aeshared/aeshared_class_csvexport';
@Component({
  selector: 'ngx-aepromos',
  templateUrl: './aepromos.component.html',
  styleUrls: ['./aepromos.component.scss']
})
export class AepromosComponent implements OnInit, AfterViewInit {

  data: Aepromotions[] = [];
  aepromotions: Aepromotions[];
  datacomponent: Aepromocomponentlist[] = [];
  aepromocomponents: Aepromocomponentlist[];
  aepromorequirements: Aepromorequirementlist[];
  isLoadingResults = true;

  showpromotions: boolean = true;
  showcomponents: boolean = false;
  showrequirements: boolean = false;

  selectedPromotion = null;
  selectedPromotionName = null;
  backFromComponent = null;
  backFromRequirement = null;

  promotionsettings = {
    actions: {
      columnTitle: '',
      delete: false,
      add: false,
    },
    mode: 'external',
    hideHeader: true,
    noDataMessage: '',
    pager: {
      display: true,
      perPage: 30,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      confirmSave: true
    },
    columns: {
      promotionSku: {
        title: 'Sku',
        width: '10%'
      },
      promotionName: {
        title: 'Promotion',
        width: '70%'
      },
      ciclo: {
        title: 'Cycle',
        width: '10%'
      },
      promoComponent: {
        title: 'Components',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: PromoComponentRenderComponent,
        valuePrepareFunction: (row) => row,
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(rowdata => {
            this.selectedPromotion = rowdata.promotionId;
            this.selectedPromotionName = rowdata.promotionName;
            this.localstorage.setItem('promotionid', this.selectedPromotion);
            this.localstorage.setItem('promotionname', this.selectedPromotionName);    
            this.localstorage.setItem('requierementtype', 'X');         
            this.getComponentsData(); 
          });
        },
      },
      requirementComponent: {
        title: 'Requirements',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: PromoComponentRenderRequirement,
        valuePrepareFunction: (row) => row,
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(rowdata => {
            this.selectedPromotion = rowdata.promotionId;
            this.selectedPromotionName = rowdata.promotionName;
            this.localstorage.setItem('promotionid', this.selectedPromotion);
            this.localstorage.setItem('promotionname', this.selectedPromotionName); 
            this.localstorage.setItem('requierementtype', 'X'); 
            this.getRequirementsData(); 
          });
        },
      },      
    }
  };

  promocomponentsettings = {
    actions: {
      columnTitle: '',
      delete: false,

    },
    mode: 'external',
    hideHeader: true,
    noDataMessage: '',
    pager: {
      display: true,
      perPage: 30,
    },
    add: {
      addButtonContent: '<i class="ion-ios-plus-outline"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      confirmSave: true
    },
    columns: {
      componentTypeName: {
        title: 'Type',
        width: '10%'
      },
      sku: {
        title: 'Sku',
        width: '10%'
      },
      productName: {
        title: 'Component',
        width: '60%'
      },
      quantity: {
        title: 'Quantity',
        width: '10%'
      },
      id: {
        title: 'Serial',
        width: '10%'
      },
    }
  };

  promorequirementsettings = {
    actions: {
      columnTitle: '',
      delete: false,

    },
    mode: 'external',
    hideHeader: true,
    noDataMessage: '',
    pager: {
      display: true,
      perPage: 30,
    },
    add: {
      addButtonContent: '<i class="ion-ios-plus-outline"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      confirmSave: true
    },
    columns: {
      requirementTypeName: {
        title: 'Type',
        width: '10%'
      },
      requirementName: {
        title: 'Requirement',
        width: '50%'
      },
      requirementQuantity: {
        title: 'Quantity',
        width: '10%'
      },
      requirementQuantityType: {
        title: 'Quantity Type',
        width: '10%'
      },      
      id: {
        title: 'Serial',
        width: '10%'
      },
    }
  };


  constructor(private api: AepromosService, private router: Router,
    private localstorage: LocalStorageService,) { }

  ngOnInit() {
    //   this.getPromotionsData
  }

  ngAfterViewInit() {
    this.backFromComponent = this.backFromRequirement = null;
    this.backFromComponent = this.localstorage.getItem("backfromcomponent");
    this.backFromRequirement = this.localstorage.getItem("backfromrequirement");
    this.selectedPromotion = this.localstorage.getItem("promotionid");
    this.selectedPromotionName = this.localstorage.getItem("promotionname");    
    if (this.backFromComponent == "true") {
      this.getComponentsData();
      this.backFromComponent = null;
    } else if (this.backFromRequirement == "true") {
      this.getRequirementsData();
      this.backFromRequirement = null;
    } else {
      this.getPromotionsData();
    }
  }

  getPromotionsData() {
    this.showPromotionsTable("P");
    this.api.getAepromotions().subscribe((data: Aepromotions[]) => {
      this.aepromotions = data;
      console.log('got promotions data');
      this.showPromotionsTable("P");
    });

  }

  getComponentsData() {
    this.showPromotionsTable("C");
    this.api.getAepromotioncomponents(this.selectedPromotion).subscribe((data: Aepromocomponentlist[]) => {
      this.aepromocomponents = data;
      console.log('got components data');
      this.showPromotionsTable("C");
    });
  }

  getRequirementsData() {
    this.showPromotionsTable("R");
    this.api.getAepromotionrequirements(this.selectedPromotion).subscribe((data: Aepromorequirementlist[]) => {
      this.aepromorequirements = data;
      console.log('got requirements sdata');
      this.showPromotionsTable("R");
    });

  }

  showPromotionsTable(bshow: string) {
    if (bshow == "P") {
      this.showpromotions = true;
      this.showcomponents = false;
      this.showrequirements = false;
    }
    else if (bshow == "C") {
      this.showpromotions = false;
      this.showcomponents = true;
      this.showrequirements = false;
    }
    else if (bshow == "R") {
      this.showpromotions = false;
      this.showcomponents = false;
      this.showrequirements = true;
    }
  }

  promotionEditDialog(event) {
    this.selectedPromotion = event.data.promotionId;
    this.selectedPromotionName = event.data.promotionName;
    this.localstorage.setItem('promotionid', this.selectedPromotion);
    this.localstorage.setItem('promotionname',this.selectedPromotionName);
    this.localstorage.setItem('requierementtype', 'X');    
    const url = '/aepromodefinition/' + `${this.selectedPromotion}`;
    this.router.navigate([url]);
  }

  componentEditDialog(event) {
    const url = '/aepromocomponents/' + `${event.data.id}`;
    this.router.navigate([url]);
  }

  componentCreateDialog() {
    const url = '/aepromocomponents';
    this.router.navigate([url]);
  }

  requirementEditDialog(event) {
    this.localstorage.setItem('requirementtype', event.data.requirementType);
    const url = '/aepromorequirements/' + `${event.data.id}`;
    this.router.navigate([url]);
  }

  requirementCreateDialog() {
    const url = '/aepromorequirements';
    this.router.navigate([url]);
  }

  backtoPromotions() {
    this.localstorage.setItem('backfromcomponent', "false")
    this.localstorage.setItem('backfromrequirement', "false")
    this.getPromotionsData();
  }

  promotionsBack() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  csv() {
    CsvExport.exportToCsv('Promotions.csv', this.aepromotions);
  }

}
