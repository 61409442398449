import { Component, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { AexordersService } from './aexorders.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Aexorder } from './aexorder_class_aexorder';
import { Aexorderproductslist } from './aexorder_class_productslist';
import { Aexorderproductsgiftlist } from './aexorder_class_productsgiftlist';
import { Aexorderproductsgiftmodel } from './aexorder_class_productsgiftmodel';
import { Router, ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ImageRenderComponent } from './aexorders_render_imagerender.component';
import { PriceRenderComponent } from './aexorders_render_pricerender.component';
import { AdditionalProductsRenderComponent } from './aexorders_render_additionalproducts.component';
import { DistributionRenderComponent } from './aexorders_render_distributionrender.component';
import { Aexordertotals } from './aexorder_class_ordertotals';
import { Aeipaddress } from './aexorder_class_ipaddress';
import { AcdcNotificationsService } from 'acdc-notifications';
import { Aestateprovincecountry } from '../aeshared/aestateprovincecountry';
import { AewarehouseService } from '../aeshared/aewarehouse.service'
import { Aewarehouse } from '../aeshared/aewarehouse';
import { Aetaxjarresponse } from './aexorder_class_taxjarresponse';
import { MatDialog, } from '@angular/material';
import { GiftDialog } from '../aeshared/aegiftdialog.component';
import { ContinuationDialog } from '../aeshared/aecontinuationdialog.component';
import { ImageDialog } from '../aeshared/aeimagedialog.component';
import { Aexordermodeldc } from './aexorder_class_modeldc';
import { Additionalorderproductslist } from './aexorder_class_additionalorderproductslist';
import { Aeinvoiceresponse } from './aexorder_class_invoiceresponse';
import { Aexorderdcproducts } from './aexorder_class_dcproducts';
import { Aedistributioncenter } from './aexorder_class_distributioncenter';
import { AexordercustomerService } from './aexorder_service_customer.service';
import { Aeimageurl } from './aexorder_class_imageurl';
import { v1 as uuid } from 'uuid';

const xordermodeldcserviceUrl = `${environment.apiServiceUrl}/aexorderdc`;
const giftsserviceUrl = `${environment.apiServiceUrl}/giftproducts`;
const orderproductwarehouseUrl = `${environment.apiServiceUrl}/aeorderproductwarehouse`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

@Component({
  selector: 'ngx-aexorders_super',
  templateUrl: './aexorders_super.component.html',
  styleUrls: ['./aexorders_super.component.scss']
})
export class AexordersSuperComponent implements OnInit, AfterViewInit {
  deskSessionId = '0';
  customerAddressForm: FormGroup;
  distributionCentersForm: FormGroup;
  customerDn = '';
  currentGuid: string = undefined;
  impersonatorDn = '';
  currentOperator = '';
  isLoadingResults = true;
  aexorders: Aexorder[];
  aeadditionalproducts: Additionalorderproductslist[];
  aexorderproducts: Aexorderproductslist[] = undefined;
  availablemessage: boolean = false;
  aexorderproductsgifts: Aexorderproductsgiftlist[] = undefined;
  aexorderdcproducts: Aexorderdcproducts[];


  backfromCart: boolean = false;
  recompute: boolean = false;
  showxorders: boolean = true;
  showaddproducts: boolean = false;
  showcustomers: boolean = false;
  showxcart: boolean = false;
  showcenters: boolean = false;
  showinvoice: boolean = false;
  showfaxnumber: boolean = false;
  showform: boolean = true;
  showadditionalproducts: boolean = false;
  showdcproducts: boolean = false;

  fromcenters: boolean = false;


  productValidationMessage = '';
  orderValidationMessage = '';

  phoneNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  zipCodePattern = "^[0-9]{5}(?:-[0-9]{4})?$";


  get ngselectedCycle() { return this.customerAddressForm.get('ngselectedCycle'); }
  get ngselectedAddress() { return this.customerAddressForm.get('ngselectedAddress'); }
  get saveAddress() { return this.customerAddressForm.get('saveAddress'); }
  // get pickupinStore() { return this.customerAddressForm.get('pickupinStore'); }
  get ngselectedDistributionCenter() { return this.customerAddressForm.get('ngselectedDistributionCenter'); }
  get pickupinDC() { return this.customerAddressForm.get('pickupinDC'); }
  get firstName() { return this.customerAddressForm.get('firstName'); }
  get lastName() { return this.customerAddressForm.get('lastName'); }
  get email() { return this.customerAddressForm.get('email'); }
  get city() { return this.customerAddressForm.get('city'); }
  get zipCode() { return this.customerAddressForm.get('zipCode'); }
  get ngselectedState() { return this.customerAddressForm.get('ngselectedState'); }
  get address1() { return this.customerAddressForm.get('address1'); }
  get address2() { return this.customerAddressForm.get('address2'); }
  get phoneNumber() { return this.customerAddressForm.get('phoneNumber'); }
  get faxNumber() { return this.customerAddressForm.get('faxNumber'); }
  get ngselectedWEDC() { return this.distributionCentersForm.get('ngselectedWEDC'); }

  labelContent = '';
  invoiceNumberLabel = '0';
  totalCredit = 0.00;
  availableCredit = 0.00;
  currentTotal = 0.00;
  previousTotal = 0.00;
  availableCreditLabel = '0.00'
  currentTotalLabel = '0.00'
  previousTotalLabel = '0.00'
  continueLabel = 'Checkout'
  tolerance = 0.00;
  customerIp = '000.000.000.000';

  aexorderModel: Aexordermodeldc;
  taxJarRate: number = 0;
  invalidPromotions = "";
  foreignShippingAmount = "0.00";
  foreignWalletRecharge = "0.00";

  invoiceId: string = "";
  invoiceNumber: string = '';
  customerAddress = '';

  selectedXOrderId = 0;
  selectedXOrderNumber = '';
  selectedXProductId = 0;
  selectedFreeProductId = 0;
  selectedXProductQuantity = 0;
  chkAvailable: boolean = true;
  confirmarDireccion: boolean = false;
  soloMexico: boolean = false;

  selectedCycle = undefined;
  selectedState = 47;
  selectedStateCode = "CA";
  selectedCountry = 1;
  countryLabel = "US United States"
  selectedAddress = 0;
  selectedWarehouse = 1;
  selectedPreviousWarehouse = 0;
  selectedDc = 0;
  selectedPickupInDC: boolean = false;
  selectedGroupId = 0;
  selectedGroupName = '';

  aewarehouses = [];
  aewarehousesJson: Aewarehouse[];

  totalGifts = "0";
  totalGiftsN = 0;
  pickupinDCWE384 = false;
  pickupinDCWE67 = false;
  pickupinDCWE56 = false;
  pickupinDCWE400 = false;
  pickupinDCWE406 = false;
  pickupinDCWE407 = false;
  enablepickup: boolean = false;
  enablewarehouse: boolean = true;

  aecycles = [];
  aestates = [];
  aestatescountry = [];

  aeaddresses = [];
  pickupChecked = false;

  aepickupaddresses = [];
  aecustomeraddresses = [];
  aeWEdc: Aedistributioncenter[];

  xorderdcproductssettings = {
    actions: {
      position: 'right',
      columnTitle: '',
      delete: false,
      add: false,
      edit: false,
    },
    pager: {
      display: false,
    },
    mode: 'external',
    hideHeader: true,
    noDataMessage: '',
    columns: {
      checked: {
        title: 'Process',
        width: '10%',
        editable: false,
        type: 'custom',
        renderComponent: DistributionRenderComponent,
        valuePrepareFunction: (cell, row) => {
          return cell;
        },
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(rowdata => {
            if (rowdata.checked == 1) {
              this.selectedPickupInDC = true;
              this.selectedGroupId = rowdata.groupId
              this.selectedGroupName = rowdata.groupName;
              const acdcid: string = this.acdcNotificationsService.toast({
                title: 'Please wait... Loading Centers data',
                timeout: 60000,
                notificationLevel: 'Info'
              });
              this.warehousesApi.getAedistributioncentersgroupid(this.selectedXOrderId, this.selectedGroupId)
                .subscribe((dataWEdc: Aedistributioncenter[]) => {
                  this.aeWEdc = dataWEdc;
                  var closestWEdc = this.aeWEdc.find(x => x.closest === '*' || x.warehouseId === 1004)
                  this.selectedDc = closestWEdc.warehouseId;
                  this.acdcNotificationsService.deleteToast(acdcid);
                  this.showxorderTable("showcenters");
                });
            }
            else {
              this.selectedPickupInDC = false;
              this.selectedDc = 1004;
            }
          });
        },
      },
      groupName: {
        title: 'Product',
        width: '15%',
        editable: false,
      },
      groupId: {
        title: 'Serial',
        width: '15%',
        editable: false,
      },
    },
  };

  xordersettings = {
    actions: {
      columnTitle: '',
      delete: false,
      add: false,
      edit: true,
    },
    pager: {
      display: true,
      perPage: 20,
    },
    mode: 'external',
    hideHeader: true,
    noDataMessage: '',
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
    },
    columns: {
      customerDn: {
        title: 'DN',
        width: '5%'
      },
      orderNumber: {
        title: 'Order',
        width: '5%'
      },
      productName: {
        title: 'Product',
        width: '30%'
      },
      quantity: {
        title: 'Quantity',
        width: '10%'
      },
      totalCredit: {
        title: 'Credit',
        width: '10%'
      },
      totalCurrent: {
        title: 'Current',
        width: '10%'
      },
      totalPrevious: {
        title: 'Previous',
        width: '10%'
      },
      availableCredit: {
        title: 'Available',
        width: '10%'
      },
    },
  };

  xorderproductssettings = {
    actions: {
      columnTitle: '',
      delete: false,
      add: false,
      edit: false,
    },
    pager: {
      display: true,
      perPage: 200,
    },

    mode: 'external',
    hideHeader: true,
    noDataMessage: '',
    columns: {
      sku: {
        title: 'Sku',
        width: '10%',
        editable: false,
      },
      categoryName: {
        title: 'Category',
        width: '30%',
        editable: false,
      },
      productImg: {
        title: 'Image',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: ImageRenderComponent,
      },
      productName: {
        title: 'Product',
        width: '15%',
        editable: false,
      },
      availableMessage: {
        title: 'Available',
        width: '5%',
        editable: false,
      },
      productQuantity: {
        title: 'Quantity',
        width: '10%'
      },
      previousQuantity: {
        title: 'Previous',
        width: '10%',
        editable: false,
      },
      productPrice: {
        title: 'Price',
        width: '5%',
        type: 'custom',
        editable: false,
        renderComponent: PriceRenderComponent,
      },
      productTotal: {
        title: 'Total',
        width: '5%',
        editable: false,
      },
      id: {
        title: 'Serial',
        width: '5%',
        editable: false,
      },
    },
  };
  additionalproductssettings = {
    actions: {
      position: 'right',
      columnTitle: '',
      delete: false,
      add: false,
      edit: true,
    },
    pager: {
      display: false,
    },
    mode: 'external',
    edit: {
      editButtonContent: '<i class="ion-ios-eye-outline"></i>',
    },
    hideHeader: true,
    noDataMessage: '',
    columns: {
      checked: {
        title: 'Process',
        width: '10%',
        editable: false,
        type: 'custom',
        renderComponent: AdditionalProductsRenderComponent,
        valuePrepareFunction: (cell, row) => {
          return cell;
        },
        onComponentInitFunction: (instance) => {
        },
      },
      customerDn: {
        title: 'Distributor',
        width: '5%'
      },
      orderNumber: {
        title: 'Order',
        width: '5%'
      },
      productSku: {
        title: 'Sku',
        width: '5%'
      },
      productName: {
        title: 'Name',
        width: '40%'
      },
      quantity: {
        title: 'Quantity        Image',
        width: '15%'
      },
    },
  };

  xinvoicesettings = {
    actions: {
      columnTitle: '',
      delete: false,
      add: false,
      edit: false,
    },
    pager: {
      display: true,
      perPage: 200,
    },


    hideHeader: true,

    noDataMessage: '',

    columns: {
      sku: {
        title: 'Sku',
        width: '10%',
        editable: false,
      },
      categoryName: {
        title: 'Category',
        width: '30%',
        editable: false,
      },
      productImg: {
        title: 'Image',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: ImageRenderComponent,
      },
      productName: {
        title: 'Product',
        width: '30%',
        editable: false,
      },
      productQuantity: {
        title: 'Quantity',
        width: '5%'
      },
      previousQuantity: {
        title: 'Previous',
        width: '5%'
      },
      productPrice: {
        title: 'Price',
        width: '5%',
        type: 'custom',
        renderComponent: PriceRenderComponent,
      },
      productTotal: {
        title: 'Total',
        width: '5%',
        editable: false,
      },
      id: {
        title: 'Serial',
        width: '5%',
        editable: false,
      },
    },
  };

  xorderproductgiftsettings = {
    actions: {
      columnTitle: '',
      delete: false,
      add: false,
      edit: true,
    },
    pager: {
      display: true,
      perPage: 200,
    },

    mode: 'inline',
    hideHeader: true,
    noDataMessage: '',

    edit: {
      editButtonContent: '<i class="ion-ios-plus-outline"></i>',
      saveButtonContent: '<i class="nb-checkmark-circle"></i>',
      cancelButtonContent: '<i class="nb-close-circled"></i>',
      confirmSave: true,
    },
    columns: {
      sku: {
        title: 'Sku',
        width: '10%',
        editable: false,
      },
      productName: {
        title: 'Product',
        width: '15%',
        editable: false,
      },
      productQuantity: {
        title: 'Quantity',
        width: '10%',
        editable: true,
      },
      id: {
        title: 'Serial',
        width: '5%',
        editable: false,
      },
    },
  };

  constructor(
    private api: AexordersService, private localstorage: LocalStorageService,
    private router: Router, private http: HttpClient,
    private route: ActivatedRoute,
    private acdcNotificationsService: AcdcNotificationsService,
    private dialog: MatDialog,
    private warehousesApi: AewarehouseService,
    private customerapi: AexordercustomerService,

    private fb: FormBuilder,
    private fbdc: FormBuilder) {
    this.customerAddressForm = this.fb.group({
      ngselectedCycle: this.fb.control(0),
      ngselectedAddress: this.fb.control(''),
      saveAddress: this.fb.control(false),
      // pickupinStore: this.fb.control(false),
      firstName: this.fb.control('', Validators.required),
      lastName: this.fb.control('', Validators.required),
      email: this.fb.control('', [Validators.required, Validators.pattern(this.emailPattern)]),
      city: this.fb.control('', Validators.required),
      zipCode: this.fb.control('', [Validators.required, Validators.pattern(this.zipCodePattern)]),
      ngselectedState: this.fb.control(47, Validators.required),
      address1: this.fb.control('', Validators.required),
      address2: this.fb.control(' ', Validators.required),
      phoneNumber: this.fb.control('', [Validators.required, Validators.pattern(this.phoneNumberPattern)]),
      faxNumber: this.fb.control(' ', [Validators.required]),
    });
    this.distributionCentersForm = this.fbdc.group({
      ngselectedWEDC: this.fbdc.control(''),
    });
    this.customerDn = localstorage.getItem("customerdn");
    this.impersonatorDn = localstorage.getItem("impersonatordn");
    this.currentOperator = localstorage.getItem("current");
    this.deskSessionId = localstorage.getItem("desksessionid");

    this.getwarehousesData().subscribe((data: Aewarehouse[]) => {
      data.forEach(element => {
        console.log(element);
        this.aewarehouses.push(element);
      });
    });
    this.selectedWarehouse = 1
    this.selectedCountry = 1;
    this.getStates();
    this.selectedState = 47;
    this.selectedStateCode = 'CA';
  }

  ngOnInit(): void {
    this.aecustomeraddresses = this.route.snapshot.data['aecustomeraddressRes'];
    this.aepickupaddresses = this.route.snapshot.data['aepickupaddressRes'];
    this.aestatescountry = this.route.snapshot.data['aestatescountryRes'];
    this.aecycles = this.route.snapshot.data['aecicloRes'];
    this.selectedCycle = this.aecycles[0].key;
    this.aeaddresses = this.aecustomeraddresses;
    this.selectedAddress = this.aeaddresses[0].addressId;
    const addressIndex = this.aeaddresses.findIndex(element => element.addressId == this.selectedAddress);
    this.setaddressValue(addressIndex);
  }

  getStates() {
    this.aestates = [];
    this.selectedState = 0;
    this.aestatescountry.forEach((element: Aestateprovincecountry) => {
      if (element.countryId == this.selectedCountry) {
        var stateprovince = {
          key: element.key,
          value: element.value
        }
        this.aestates.push(stateprovince);
        //       console.log('state ' + element.key + ' ' + element.value);
      }
    });
  }

  getwarehousesData() {
    return this.warehousesApi.getAewarehouses().pipe(tap(data => {
      this.aewarehousesJson = data;
    }));
  }

  ngAfterViewInit() {
    this.showxorderTable("showxorders");
  }

  showxorderTable(bshow: string) {
    if (bshow == "showxorders") {
      this.showxorders = true;
      this.showaddproducts = false;
      this.showxcart = false;
      this.showinvoice = false;
      this.showcenters = false;
      this.showadditionalproducts = false;
      this.aexorders = undefined;
      const acdcid: string = this.acdcNotificationsService.toast({
        title: 'Please wait... Loading available Super Xpress Orders',
        timeout: 120000,
        notificationLevel: 'Info'
      });
      this.api.getAexordersDC(this.customerDn)
        .subscribe((data: Aexorder[]) => {
          this.aexorders = data;
          this.acdcNotificationsService.deleteToast(acdcid);
          if (this.aexorders.length == 0) {
            this.acdcNotificationsService.toast({
              title: 'Warning... No available Super XPress Orders',
              timeout: 3000,
              notificationLevel: 'Warn'
            })
          }
        });
    } else
      if (bshow == "showaddproducts") {
        this.aexorderproducts = [];
        this.labelContent = 'Selected Products'
        this.showxorders = false;
        this.showaddproducts = true;
        this.showcenters = false;
        this.showinvoice = false;
        this.invoiceId = "0";
        this.showxcart = false;

        this.showadditionalproducts = false;
        const acdcid: string = this.acdcNotificationsService.toast({
          title: 'Please wait... Loading Product data',
          timeout: 60000,
          notificationLevel: 'Info'
        });
        this.api.getAdditionalorderProducts(this.customerDn, this.selectedXOrderId, this.selectedXProductId, this.currentGuid, this.selectedWarehouse,0)
          .subscribe((dataa: Additionalorderproductslist[]) => {
            this.aeadditionalproducts = dataa;
            console.log(this.aeadditionalproducts);
            this.selectedPreviousWarehouse = this.selectedWarehouse;
            if (this.aeadditionalproducts.length > 0) {
              this.continueLabel = 'Continue'
            }
            this.aexorderproducts = undefined;
            this.api.getAexorderproductsDC(this.selectedXOrderId, this.selectedXProductId, 0, this.selectedWarehouse, this.selectedXProductQuantity)
              .subscribe((data: Aexorderproductslist[]) => {
                this.aexorderproducts = data;
                if (this.aexorderproducts.length == 0) {
                  this.acdcNotificationsService.deleteToast(acdcid);
                  this.acdcNotificationsService.toast({
                    title: 'Warning... No Product data',
                    timeout: 3000,
                    notificationLevel: 'Warn'
                  });
                }
                else {
                  this.aexorderproductsgifts = undefined;
                  this.api.getAexorderproductgifts(this.selectedXOrderId, this.selectedXProductId, this.currentGuid, this.customerDn)
                    .subscribe((dataf: Aexorderproductsgiftlist[]) => {
                      this.aexorderproductsgifts = dataf;
                      console.log('gifts ' + dataf);
                    })
                  this.aexorderdcproducts = undefined;
                  this.api.getAexorderdcproducts(this.selectedXOrderId, this.selectedXProductId, this.currentGuid, this.customerDn, false)
                    .subscribe((datag: Aexorderdcproducts[]) => {
                      this.aexorderdcproducts = datag;
                      console.log('dcproducts ' + this.aexorderdcproducts);
                    })
                  this.api.getAexordertotals(this.selectedXOrderId, this.selectedXProductId)
                    .subscribe((data: Aexordertotals) => {
                      this.currentTotal = data.currentTotal;
                      this.previousTotal = data.previousTotal;
                      this.tolerance = data.tolerance;
                      this.availableCredit = (this.totalCredit - this.currentTotal - this.previousTotal);
                      this.currentTotalLabel = this.currentTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                      this.previousTotalLabel = this.previousTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                      this.availableCreditLabel = this.availableCredit.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                      this.acdcNotificationsService.deleteToast(acdcid);
                    });
                }
              });
          });
      } else
        if (bshow == "showcenters") {
          this.showxorders = false;
          this.showaddproducts = false;
          this.showinvoice = false;
          this.showxcart = false;
          this.showcenters = true;
          this.showadditionalproducts = false;
          this.fromcenters = true;

        } else
          if (bshow == "showxcart") {
            if (this.aexorderdcproducts.length > 0) {
              this.enablepickup = true;
              this.togglePickup();
            }
            else {
              this.enablepickup = false;
            }
            this.labelContent = 'Order Information'
            this.showxorders = false;
            this.showaddproducts = false;
            this.showcenters = false;
            this.showinvoice = false;
            this.showxcart = true;
            this.showadditionalproducts = false;
          } else
            if (bshow == "showadditionalproducts") {
              this.labelContent = 'Order Information'
              this.showxorders = false;
              this.showaddproducts = false;
              this.showcenters = false;
              this.showinvoice = false;
              this.showxcart = false;
              this.showadditionalproducts = true;
            } else
              if (bshow == "showinvoice") {
                const ordermessage = 'Order ' + this.invoiceNumber + ' Registered'
                this.acdcNotificationsService.toast({
                  title: ordermessage,
                  timeout: 6000,
                  notificationLevel: 'Success'
                });
                this.labelContent = 'Invoice Information'
                this.showxorders = false;
                this.showaddproducts = false;
                this.showcenters = false;
                this.showinvoice = true;
                this.invoiceNumberLabel = this.invoiceNumber;
                this.showxcart = false;
                this.showadditionalproducts = false;
                const acdcid: string = this.acdcNotificationsService.toast({
                  title: 'Please wait... Loading Product data',
                  timeout: 60000,
                  notificationLevel: 'Info'
                });
                this.aexorderproducts = undefined;
                this.api.getAexorderproductsDC(this.selectedXOrderId, this.selectedXProductId, 1, this.selectedWarehouse, this.selectedXProductQuantity)
                  .subscribe((data: Aexorderproductslist[]) => {
                    this.aexorderproducts = data;
                    if (this.aexorderproducts.length == 0) {
                      this.acdcNotificationsService.toast({
                        title: 'Warning... No Product data',
                        timeout: 3000,
                        notificationLevel: 'Warn'

                      });
                    }
                    this.api.getAexordertotals(this.selectedXOrderId, this.selectedXProductId)
                      .subscribe((data: Aexordertotals) => {
                        this.currentTotal = data.currentTotal;
                        this.previousTotal = data.previousTotal;
                        this.tolerance = data.tolerance;
                        this.availableCredit = (this.totalCredit - this.currentTotal - this.previousTotal);
                        this.currentTotalLabel = this.currentTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                        this.previousTotalLabel = this.previousTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                        this.availableCreditLabel = this.availableCredit.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                        this.acdcNotificationsService.deleteToast(acdcid);
                      });
                  });
              }

  }

  xorderEditDialog(event) {
    var selectedorder = this.aexorders.find(x => x.orderNumber === event.data.orderNumber && x.productName === event.data.productName)
    this.selectedXOrderId = selectedorder.xorderId;
    this.selectedXProductId = selectedorder.productId;
    this.confirmarDireccion = selectedorder.confirmarDireccion;
    this.soloMexico = selectedorder.soloMexico;
    this.confirmarDireccion = false; // por si acaso cambian de opinion y si hay que verficar algo
    // let sufixI = " and must";
    // let sufixE = " y debe";
    let sufixI = " and should";
    let sufixE = " y debería";
    // if (this.impersonatorDn.length > 0) {
    //   sufixI = " and should";
    //   sufixE = " y debería";
    // }

    var previousindex = this.aexorders.findIndex(x => x.xorderId < selectedorder.xorderId && x.availableCreditD >= 50)
    if (previousindex != -1) {
      const dialogRef = this.dialog.open(ContinuationDialog, {
        maxWidth: "500px",
        data: {
          message: "Order " + this.aexorders[previousindex].orderNumber + " is prior to" +
            " \n Order " + this.selectedXOrderNumber + sufixI +
            " \n be processed first",
          message2: "La Orden " + this.aexorders[previousindex].orderNumber + " es anterior" +
            " \n a la Orden " + this.selectedXOrderNumber + sufixE +
            " \n ser procesada primero",
        }
      });
      // if (this.impersonatorDn.length == 0) {
      //   this.showxorderTable("showxorders");
      // }
      // else { this.xorderEdit(selectedorder) }
    }
    // else {
    //   this.xorderEdit(selectedorder)
    // }
    this.xorderEdit(selectedorder)
  }

  xorderEdit(selectedorder: Aexorder) {


    if (this.confirmarDireccion) {
      this.acdcNotificationsService.toast({
        message: 'Must confirm Order Address',
        timeout: 3000,
        notificationLevel: 'Warn'
      });
    }
    if (this.currentGuid == undefined) {
      this.currentGuid = uuid();
      this.localstorage.setItem('currentguid', this.currentGuid);
      this.localstorage.setItem('cleancustomerdn', this.customerDn);
      this.localstorage.setItem('dcxorderid', this.selectedXOrderId);
    }
    let orderGuidValid: boolean = true;
    this.api.getValidorderguid(this.selectedXOrderId, this.selectedXProductId, this.currentGuid, this.customerDn, this.deskSessionId,0).subscribe({
      next: dataz => {
        if (dataz.validOrderGuid != this.currentGuid) {
          orderGuidValid = false;
          this.acdcNotificationsService.toast({
            message: dataz.validOrderGuid,
            timeout: 3000,
            notificationLevel: 'Error'
          });
        }
        else {
          if (this.soloMexico) { this.selectedCountry = 145; this.enablewarehouse = false; }
          else { this.selectedCountry = selectedorder.countryId; }
          this.getStates();
          var selectedwarehouse = this.aewarehouses.find(x => x.countryId === this.selectedCountry)
          this.selectedWarehouse = selectedwarehouse.id
          this.countryLabel = selectedwarehouse.countryName;
          if (this.selectedCountry == 1) {
            this.showfaxnumber = false;
            this.customerAddressForm.controls['faxNumber'].setValue(' ');
            this.customerAddressForm.controls['address2'].setValue(' ');
            this.selectedState = 47;
            var selectedstate = this.aestates.find(x => x.key === this.selectedState);
            this.selectedStateCode = selectedstate.value.substring(0, 2);
          }
          else if (this.selectedCountry == 145) {
            this.showfaxnumber = true;
            this.customerAddressForm.controls['faxNumber'].setValue('');
            this.customerAddressForm.controls['address2'].setValue('');
            this.selectedState = 86;
            var selectedstate = this.aestates.find(x => x.key === this.selectedState);
            this.selectedStateCode = selectedstate.value.substring(0, 2);
          } else {
            this.showfaxnumber = true;
            this.customerAddressForm.controls['faxNumber'].setValue('');
            this.customerAddressForm.controls['address2'].setValue('');
            this.aestates = [{ key: 77, value: "N/A" }];
            this.selectedState = 77
            this.selectedStateCode = 'NA';
          }
          console.log('selected ware ' + this.selectedWarehouse);
          this.selectedXOrderId = selectedorder.xorderId;
          this.selectedXProductId = selectedorder.productId;
          this.selectedFreeProductId = selectedorder.freeProductId;
          this.selectedXProductQuantity = selectedorder.quantity,
            this.totalCredit = parseFloat(selectedorder.totalCredit.replace(',', ''));
          this.api.getIpaddress()
            .subscribe((data: Aeipaddress) => {
              this.customerIp = data.ip;
            });
          this.showxorderTable("showaddproducts");
        }
      }
    });

  }

  convertToAexorder(value: any): Aexordermodeldc {
    const aexorderrecord: Aexordermodeldc = value;
    return aexorderrecord;
  }

  noporderValidation() {
    this.aexorderModel = this.convertToAexorder(this.customerAddressForm.value);
    console.log('model name ' + this.aexorderModel.firstName.toString());
    var selectedstate = this.aestates.find(x => x.key === this.selectedState);
    this.selectedStateCode = selectedstate.value.substring(0, 2);
    this.taxJarRate = 0;

    this.api.getInvalidpromotions(this.customerDn, this.selectedWarehouse).subscribe({
      next: data => {
        this.invalidPromotions = data.invalidPromotionsList;
        if (this.invalidPromotions != 'ok') {
          this.acdcNotificationsService.toast({
            title: this.invalidPromotions,
            timeout: 3000,
            notificationLevel: 'Error'
          });
        }
        else {
          if (this.selectedCountry == 1) {
            let zipCodeValid: boolean = true;
            this.api.getZipcodevalidation(this.selectedCountry, this.selectedStateCode, this.aexorderModel.zipCode)
              .subscribe({
                next: dataz => {
                  if (dataz.validZipcode == 'ko') {
                    this.api.getTaxjarvalidation(this.aexorderModel.zipCode)
                      .subscribe((dataR: Aetaxjarresponse) => {
                        if (dataR.error.length == 0) {
                          if (dataR.state != this.selectedStateCode) {
                            zipCodeValid = false;
                            this.acdcNotificationsService.toast({
                              title: "Entered state doesn't match entered ZipCode",
                              timeout: 3000,
                              notificationLevel: 'Error'
                            });
                          }
                          else {
                            this.taxJarRate = dataR.combinedR;
                            this.noporderRegister();
                          }
                        }
                        else {
                          this.acdcNotificationsService.toast({
                            title: "ZipCode " + dataR.error,
                            timeout: 3000,
                            notificationLevel: 'Error'
                          });
                        }
                      });
                  }
                  else {
                    this.taxJarRate = 0;
                    this.noporderRegister();
                  }
                }
              });
          }
          else if (this.selectedCountry == 145) {
            let zipCodeValid: boolean = true;
            this.api.getZipcodevalidation(this.selectedCountry, this.selectedStateCode, this.aexorderModel.zipCode)
              .subscribe({
                next: dataz => {
                  if (dataz.validZipcode == 'ko') {
                    zipCodeValid = false;
                    this.acdcNotificationsService.toast({
                      title: "Entered state doesn't match entered ZipCode",
                      timeout: 3000,
                      notificationLevel: 'Error'
                    });
                  }
                  else {
                    this.taxJarRate = 0;
                    this.noporderRegister();
                  }
                }
              });
          }
          else {
            this.taxJarRate = 0;
            this.noporderRegister();
          }
        }
      },
      error: err => console.log(`Oops... ${err}`),
      complete: () => console.log(`Complete!`),
    });
  }

  noporderRegister() {
    let bsaveaddress = false;
    // let bpickupinStore = false;
    let bpickupinDC = false;
    if (this.aexorderModel.saveAddress !== null) { bsaveaddress = this.aexorderModel.saveAddress; }
    // if (this.aexorderModel.pickupinStore !== null) { bpickupinStore = this.aexorderModel.pickupinStore; }
    if (this.aexorderModel.pickupinDC !== null) { bpickupinDC = this.aexorderModel.pickupinDC; }
    var dataI = {
      "xorderId": this.selectedXOrderId,
      "xproductId": this.selectedXProductId,
      "ciclosId": this.selectedCycle,
      "customerIp": this.customerIp,
      "taxRate": 0,
      "orderTotal": this.currentTotal,
      "firstName": this.aexorderModel.firstName,
      "lastName": this.aexorderModel.lastName,
      "email": this.aexorderModel.email,
      "countryId": this.selectedCountry,
      "stateProvinceId": this.selectedState,
      "city": this.aexorderModel.city,
      "address1": this.aexorderModel.address1,
      "address2": this.aexorderModel.address2,
      "zipCode": this.aexorderModel.zipCode,
      "phoneNumber": this.aexorderModel.phoneNumber,
      "faxNumber": this.aexorderModel.faxNumber,
      "saveAddress": bsaveaddress,
      "pickupinstore": false,
      "impersonatordn": this.impersonatorDn,
      "currentoperator": this.currentOperator,
      "currentGuid": this.currentGuid,
      "pickupinDC": bpickupinDC,
      "distributionCenterId": this.selectedWarehouse,
      "warehouseId": this.selectedWarehouse,
      "includeSuperPacks": true,
      "partialOrderId": 0
    };

    dataI.taxRate = this.taxJarRate;
    console.log('data tax' + dataI.taxRate.toString());

    this.api.getForeignshipping(this.selectedXOrderId, this.selectedXProductId, this.selectedCountry).subscribe({
      next: dataS => {
        this.foreignShippingAmount = dataS.foreignShippingAmount;
        this.foreignWalletRecharge = dataS.foreignWalletRecharge;
        if (this.foreignShippingAmount != "0.00") {
          if (this.foreignWalletRecharge != "0.00") {
            const dialogRef = this.dialog.open(ContinuationDialog, {
              maxWidth: "400px",
              data: {
                message: "You must recharge your wallet with US$" + this.foreignWalletRecharge +
                  " \n for this order to be shipped",
              }
            });
          } else {
            const dialogRef = this.dialog.open(ContinuationDialog, {
              maxWidth: "400px",
              data: {
                message: "Your Wallet will be charged with US$" + this.foreignShippingAmount +
                  " \n for this order to be shipped",
              }
            });
          }
        }
        const acdcid: string = this.acdcNotificationsService.toast({
          title: 'Please wait... Processing Order',
          timeout: 60000,
          notificationLevel: 'Info'
        });
        this.invoiceNumber = '';
        this.http.post<Aeinvoiceresponse>(xordermodeldcserviceUrl, dataI, httpOptions).subscribe(
          res => {
            this.acdcNotificationsService.deleteToast(acdcid);
            let ordermessage = '';
            console.log('res val ' + res.invoiceNumber);
            if (res.invoiceNumber.startsWith('Error') || res.invoiceNumber.length == 0) {
              ordermessage = `Order Processing Error  ${res} 
                    Your order was NOT placed`
              this.acdcNotificationsService.toast({
                title: ordermessage,
                timeout: 3000,
                notificationLevel: 'Error'
              });
            }
            else if (res.invoiceNumber.startsWith('I')) {
              this.invoiceId = res.invoiceNumber;
              this.invoiceNumber = res.invoiceNumber;
              this.showxorderTable("showinvoice");
            }
            else { this.showxorderTable("showxorders"); }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log("Client-side error occured");
              this.acdcNotificationsService.toast({
                title: 'Client-side error occured. Please retry',
                timeout: 3000,
                notificationLevel: 'Error'
              });
            } else {
              console.log("Server-side error occured");
              this.acdcNotificationsService.toast({
                title: 'Server-side error occured. Please retry',
                timeout: 4000,
                notificationLevel: 'Error'
              });
            }
          }
        );
      }
    });
  }

  back() {
    if (this.showxcart) { this.backfromCart = true; this.showxorderTable("showaddproducts"); }
    else { this.showxorderTable("showxorders"); }
  }

  ordersBack() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  gotoCart() {
    if (this.currentTotal == 0) {
      this.acdcNotificationsService.toast({
        title: "Order total = 0; Please select Products",
        timeout: 3000,
        notificationLevel: 'Warn'
      });
    }
    else {
      this.showxorderTable("showxcart");
    }
  }

  onCycleChange(event) {
    this.selectedCycle = event.key;
  }

  onStateChange(event) {
    this.selectedState = event.key;
    this.selectedStateCode = event.value.substring(0, 2);
  }

  onPickupChange(event) {
    if (event.target.checked == true) {
      this.aeaddresses = [...this.aepickupaddresses];
      this.pickupChecked = true;
      this.customerAddressForm.get('saveAddress').disable();
    } else {
      this.aeaddresses = [...this.aecustomeraddresses];
      this.customerAddressForm.get('saveAddress').enable();
      this.pickupChecked = false
    }
  }

  onAddressChange(event) {
    const selectedaddress = event.addressId;
    const addressIndex = this.aeaddresses.findIndex(element => element.addressId == selectedaddress);
    const selectedcountry = this.aeaddresses[addressIndex].countryId;
    if (selectedcountry == this.selectedCountry) {
      this.selectedAddress = selectedaddress;
      this.setaddressValue(addressIndex);
    }
    else {
      this.customerAddressForm.controls['ngselectedAddress'].setValue('');
      this.selectedAddress = null;
      this.acdcNotificationsService.toast({
        title: "Address must be in Selected Country or create a new one",
        timeout: 3000,
        notificationLevel: 'Warn'
      });
    }
  }


  setaddressValue(addressIndex: number) {
    this.selectedState = this.aeaddresses[addressIndex].stateProvinceId;
    if (this.selectedState == 0) {
      if (this.selectedCountry == 1) { this.selectedState = 47; this.selectedStateCode = "CA"; }
      else if (this.selectedCountry == 145) { this.selectedState = 76; this.selectedStateCode = "NA"; }
      else { this.selectedState = 77; this.selectedStateCode = "NA"; }
    }

    this.customerAddressForm.controls['firstName'].setValue(this.aeaddresses[addressIndex].firstName);
    this.customerAddressForm.controls['lastName'].setValue(this.aeaddresses[addressIndex].lastName);
    this.customerAddressForm.controls['email'].setValue(this.aeaddresses[addressIndex].email);
    this.customerAddressForm.controls['city'].setValue(this.aeaddresses[addressIndex].city);
    this.customerAddressForm.controls['zipCode'].setValue(this.aeaddresses[addressIndex].zipCode);
    this.customerAddressForm.controls['address1'].setValue(this.aeaddresses[addressIndex].address1);
    this.customerAddressForm.controls['phoneNumber'].setValue(this.aeaddresses[addressIndex].phoneNumber);
    if (this.selectedCountry != 1) {
      this.customerAddressForm.controls['address2'].setValue(this.aeaddresses[addressIndex].address2);
      this.customerAddressForm.controls['faxNumber'].setValue(this.aeaddresses[addressIndex].faxNumber);
    }
    else {
      this.customerAddressForm.controls['address2'].setValue(' ');
      this.customerAddressForm.controls['faxNumber'].setValue(' ');
    }
    this.selectedCycle = this.aecycles[0].key;
  }

  additionalproductsEditDialog(event) {
    const imageSku = event.data.productSku;
    this.api.getImageurl(imageSku)
      .subscribe((data: Aeimageurl) => {
        var dataimageurl = data.imageUrl;
        console.log(data.imageUrl);
        const imagedialogRef = this.dialog.open(ImageDialog, {
          maxWidth: "900px",
          data: {
            imageurl: dataimageurl,

          }
        });
      });
  }


  additionalProducts() {
    if (this.currentTotal == 0) {
      this.acdcNotificationsService.toast({
        title: "Order total = 0; Please select Products",
        timeout: 3000,
        notificationLevel: 'Warn'
      });
    }
    else {
      if (this.additionalProducts != undefined && this.aeadditionalproducts.length > 0) {
        this.showxorderTable("showadditionalproducts");
      }
      else {
        this.gotoCart();
      }
    }
  }


  additionalproductsBack() {
    this.showxorderTable("showaddproducts");
  }

  onWECenterChange(event) {
    this.selectedDc = event.warehouseId;
  }

  confirmDc() {
    this.processDC();
    this.showxorderTable("showxcart");
  }


  toggleAvailable(event) {
    this.chkAvailable = event.target.checked;
    this.showxorderTable("showxorders");
  }

  backfromcenters() {
    this.showxorderTable("showxcart");

  }

  backfromgifts() {
    this.showform = true;
    this.showdcproducts = false;
  }

  processDC() {
    var data = {
      "xorderId": this.selectedXOrderId,
      "xproductId": this.selectedXProductId,
      "groupId": this.selectedGroupId,
      "pickupInDc": this.selectedXProductId,
      "warehouseId": this.selectedDc,
      "currentGuid": this.currentGuid,
      "porderId": 0
    };

    console.log(data);
    this.http.post<number>(orderproductwarehouseUrl, data, httpOptions).subscribe(
      res => {
        console.log('updated pickups ' + res);
        this.acdcNotificationsService.toast({
          title: 'Options Registered',
          timeout: 3000,
          notificationLevel: 'Success'
        });
      });
  }

  togglePickup() {
    if (this.aexorderproducts.length > 0) {
      this.aexorderproductsgifts = undefined;
      this.api.getAexorderproductgifts(this.selectedXOrderId, this.selectedXProductId, this.currentGuid, this.customerDn)
        .subscribe((dataf: Aexorderproductsgiftlist[]) => {
          this.aexorderproductsgifts = dataf;
          console.log('gifts ' + dataf);
          if (!this.fromcenters) {
            const dialogRef = this.dialog.open(GiftDialog, {
              maxWidth: "400px",
              data: {
                message1: "                                Pickup on Site",
                message3: "IMPORTANT: You must wait for phone confirmation from management before picking up your products.",
                message4: "",

                message5: "                                Retirar en Sitio",
                message7: "IMPORTANTE: Debe esperar la confirmación telefónica de la gerencia antes de ir a recoger sus productos.",
                message8: "",
              }
            }).afterClosed().subscribe(confirmed => {
              if (confirmed) {

              }
            })
          }
        })
    }
    this.showform = false;
    this.showdcproducts = true;
  }



}

