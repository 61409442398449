import { Component, Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'continuation-dialog',
  templateUrl: 'aecontinuationdialog.component.html',
})
export class ContinuationDialog {
  message: string = "Are you sure?"
  message2: string = ""
  confirmButtonText = "Confirm"
  cancelButtonText = "Continue"
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ContinuationDialog>) {
    if (data) {
      this.message = data.message || this.message;
      this.message2 = data.message2 || this.message;
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}