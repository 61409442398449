import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';

import { Aeciclomodel } from './aeciclo_class_model';
import { Aeciclolist } from './aeciclo_class_list';

import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const serviceUrl = `${environment.apiServiceUrl}/aeciclo`;
const listserviceUrl = `${environment.apiServiceUrl}/aeciclolist/all`;

@Injectable({
  providedIn: 'root'
})
export class AeciclosService {

  constructor(private http: HttpClient) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getAeciclos (): Observable<Aeciclolist[]> {
    const url = `${listserviceUrl}`;
    return this.http.get<Aeciclolist[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aeciclos')),
        catchError(this.handleError('getAeciclos', []))
      );
  }

  getAeciclo(id: number): Observable<Aeciclomodel> {
    const url = `${serviceUrl}/${id}`;
    
    return this.http.get<Aeciclomodel>(url, httpOptions).pipe(
      tap(_ => console.log(`fetched Aeciclo id=${id}`)),
      catchError(this.handleError<Aeciclomodel>(`getAeciclo id=${id}`))
    );
  }

  addAeciclo (aeciclo: any): Observable<Aeciclomodel> {
    const url = `${serviceUrl}`;
    console.log('descripCiclos ' + aeciclo.descripCiclos);
    console.log(aeciclo);
    return this.http.post<Aeciclomodel>(url, aeciclo, httpOptions)
    .pipe(
      tap((aecicloResP: Aeciclomodel) => console.log(`added Aeciclo ${aecicloResP} `)),
      catchError(this.handleError<Aeciclomodel>('addAeciclo'))
    );
  }

  updateAeciclo (id: number, aeciclo: any): Observable<any> {
    const url = `${serviceUrl}/${id}`;
    return this.http.put(url, aeciclo, httpOptions).pipe(
      tap(_ => console.log(`updated Aeciclo id=${id}`)),
      catchError(this.handleError<any>('updateAeciclo'))
    );
  }

  deleteAeciclo (id: number): Observable<Aeciclomodel> {
    const url = `${serviceUrl}/${id}`;
    return this.http.delete<Aeciclomodel>(url, httpOptions).pipe(
      tap(_ => console.log(`deleted Aeciclo id=${id}`)),
      catchError(this.handleError<Aeciclomodel>('deleteAeciclo'))
    );
  }
}
