import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AesharedModule } from '../aeshared/aeshared.module';
import { AeuplineshowComponent } from './aeuplineshow.component'
import { AeuplineshowuserComponent } from './aeuplineshowuser.component'

@NgModule({
  declarations: [
    AeuplineshowComponent,
    AeuplineshowuserComponent,
    ],
  entryComponents:[
  ],
  providers:[

  ],
  exports: [
    AeuplineshowComponent,
    AeuplineshowuserComponent,
  ],
  imports: [
  CommonModule,
  AesharedModule,
   
  ]
})
export class AeuplineshowModule { }
