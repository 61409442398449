import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot} from '@angular/router';
import { AewalletcustomerService } from './aewallet_service_customer.service';



@Injectable({
    providedIn: 'root'
})


export class AewalletcustomerResolverService implements Resolve<any> {

    constructor(
        private walletcustomerApi: AewalletcustomerService,
    ) {

    }

    resolve(route: ActivatedRouteSnapshot): any {
        return this.walletcustomerApi.getAewalletCustomers();
    }



}