import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AcdcNotificationsService } from 'acdc-notifications';
import { AedesktopService } from '../../pages/aeshared/aeshared_service_aedesktop.service'

import { Aexpressdataservice } from './aexpressdata_service_data.service';
import { Aexpressdatalist } from './aexpressdata_class_aexpressdatalist';
import { Aexpressdatakeyvalue } from './aexpressdata_class_aexpressdatalkeyvalue';
import { Aexpressdatamodel } from './aexpressdata_class_aexpressdatamodel';
import { Aexpressdatadetailmodel } from './aexpressdata_class_aexpressdatadetailmodel';
import { FixedProductsListRenderComponent } from './aexpressdata_render_fixedproductslist.component';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Aexpressdatadetaillist } from './aexpressdata_class_aexpressdatadetaillist';


const xpressdetailserviceUrl = `${environment.apiServiceUrl}/aexpressdatadetail`;
const xpressdataserviceUrl = `${environment.apiServiceUrl}/aexpressdata`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

export enum xpressDataFormMode {
  VIEW = 'View',
  EDIT = 'Edit',
  ADD = 'Add',
}

export enum xpressDetailFormMode {
  VIEW = 'View',
  EDIT = 'Edit',
  ADD = 'Add',
}

@Component({
  selector: 'ngx-aexpressdata',
  templateUrl: 'aexpressdata.component.html',
  styleUrls: ['aexpressdata.component.css'],
})
export class AexpressdataComponent implements OnInit, AfterViewInit, OnDestroy {

  public setdisable: boolean = false;
  aexpressdata: Aexpressdatalist[];
  aexpressdetail: Aexpressdatadetaillist[];


  aexpressdatasettings = {

    actions: {
      columnTitle: '',
      delete: true,
      add: false,
      edit: true,
    },
    pager: {
      display: true,
      perPage: 20,
    },

    mode: 'external',
    hideHeader: true,
    noDataMessage: '',

    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
    },
    delete: {
      deleteButtonContent: '<img src="../../../assets/images/treeicon.jpg">',
    },
    columns: {
      sku: {
        title: 'SKU',
        width: '5%',
        editable: false,
      },
      name: {
        title: 'Product',
        width: '25%',
        editable: false,
      },
      puntoProductos: {
        title: 'Points',
        width: '5%',
        editable: false,
      },
      creditoXpress: {
        title: 'Credit',
        width: '5%',
        editable: false,
      },
      bonoPrimerSponsorActivo: {
        title: '1er Bono',
        width: '5%',
        editable: false,
      },
      bonoSegundoSponsorActivo: {
        title: '2do Bono',
        width: '5%',
        editable: false,
      },
      mexicoCharge: {
        title: 'Mexico Ship',
        width: '5%',
        editable: false,
      },
      // id: {
      //   hideHeader: true,
      //   hideSubHeader: true,
      //   hide: true,
      // },
      fixedProductsList: {
        title: 'Super',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: FixedProductsListRenderComponent,
        valuePrepareFunction: (cell) => {
          return cell;
        },
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(res => {
            if (res < 0) {
              this.acdcNotificationsService.toast({
                title: "Can't modifify because there are orders for this package",
                timeout: 5000,
                notificationLevel: 'Error'
              });
            }
            this.getXpressData();
          });
        },
      },
    },
  };

  aexpressdetailsettings = {
    actions: {
      columnTitle: '',
      add: true,
      edit: true,
      delete: false,
    },
    pager: {
      display: true,
      perPage: 20,
    },

    mode: 'external',
    hideHeader: true,
    noDataMessage: '',

    add: {
      addButtonContent: '<i class="ion-ios-plus-outline"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
    },

    columns: {
      sku: {
        title: 'SKU',
        width: '5%',
        editable: false,
      },
      name: {
        title: 'Product',
        width: '25%',
        editable: false,
      },
      quantity: {
        title: 'Quantity',
        width: '5%',
        editable: true,
      },
      id: {
        title: 'Serial',
        width: '5%',
        editable: false,
      },
    },
  };

  showForm: boolean = false;
  showDetailForm: boolean = false;
  showxpressData: boolean = true;
  showxpressDetail: boolean = false;
  showCopy: boolean = false;

  aeproducts = [];
  aedetailproducts: Aexpressdatakeyvalue[];



  id: number = undefined;
  detailid: number = undefined;

  aexpressDataForm: FormGroup;
  aexpressDetailForm: FormGroup;

  protected readonly unsubscribedata$ = new Subject<void>();
  protected readonly unsubscribedetail$ = new Subject<void>();

  get xpressName() { return this.aexpressDataForm.get('xpressName'); }
  get xpressSku() { return this.aexpressDataForm.get('xpressSku'); }
  get puntoProductos() { return this.aexpressDataForm.get('puntoProductos'); }
  get creditoXpress() { return this.aexpressDataForm.get('creditoXpress'); }
  get bonoPrimerSponsorActivo() { return this.aexpressDataForm.get('bonoPrimerSponsorActivo'); }
  get bonoSegundoSponsorActivo() { return this.aexpressDataForm.get('bonoSegundoSponsorActivo'); }
  get xpressTolerance() { return this.aexpressDataForm.get('xpressTolerance'); }
  get ngselectedFreeProductSku() { return this.aexpressDataForm.get('ngselectedFreeProductSku'); }
  get freeProductQuantity() { return this.aexpressDataForm.get('freeProductQuantity'); }
  get mexicoCharge() { return this.aexpressDataForm.get('mexicoCharge'); }

  get ngselectedSku() { return this.aexpressDetailForm.get('ngselectedSku'); }
  get quantity() { return this.aexpressDetailForm.get('quantity'); }

  mxpressName = '';
  mxpressSku = '';
  mpuntoProductos = 0;
  mcreditoXpress = 0;
  mbonoPrimerSponsorActivo = 0;
  mbonoSegundoSponsorActivo = 0;
  mxpressTolerance = 0;
  selectedFreeProductSku: string = '';
  mfreeProductQuantity = 0;
  mmexicoCharge = 0;

  selectedSku: string = '';
  selectedCopytoSku: string = '';
  mquantity: number = 0;

  labelContent = 'Editing Express Packages';
  productName = '';
  productSku = '';

  modeData: xpressDataFormMode;
  modeDetail: xpressDetailFormMode;
  modoData = "";
  modoDetail = "";


  setViewDataMode(viewMode: xpressDataFormMode) {
    this.modeData = viewMode;
    if (this.modeData == xpressDataFormMode.VIEW) { this.modoData = "Ver"; }
    else if (this.modeData == xpressDataFormMode.ADD) { this.modoData = "Agregar"; }
    else { this.modoData = "Editar"; }
  }

  setViewDetailMode(viewMode: xpressDetailFormMode) {
    this.modeDetail = viewMode;
    if (this.modeDetail == xpressDetailFormMode.VIEW) { this.modoDetail = "Ver"; }
    else if (this.modeDetail == xpressDetailFormMode.ADD) { this.modoDetail = "Agregar"; }
    else { this.modoDetail = "Editar"; }
  }



  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private acdcNotificationsService: AcdcNotificationsService,
    private http: HttpClient,
    private aedesktopservice: AedesktopService,
    private api: Aexpressdataservice,
    private fb: FormBuilder,
    private fbDetail: FormBuilder) {

    this.aexpressDataForm = this.fb.group({
      id: this.fb.control(''),
      xpressName: this.fb.control('', Validators.required),
      xpressSku: this.fb.control('', Validators.required),
      puntoProductos: this.fb.control(0, Validators.required),
      creditoXpress: this.fb.control(0, Validators.required),
      bonoPrimerSponsorActivo: this.fb.control(0, Validators.required),
      bonoSegundoSponsorActivo: this.fb.control(0, Validators.required),
      xpressTolerance: this.fb.control(0, Validators.required),
      ngselectedFreeProductSku: this.fb.control(''),
      freeProductQuantity: this.fb.control(0),
      mexicoCharge: this.fb.control(0, Validators.required),
    });

    this.aexpressDetailForm = this.fbDetail.group({
      id: this.fb.control(''),
      ngselectedSku: this.fbDetail.control(''),
      quantity: this.fbDetail.control(0),
    });
  }

  ngOnInit(): void {
    this.getXpressDetailKeyValue();
    // this.initaexpressDataForm();
    // this.initaexpressDetailForm();

  }

  ngAfterViewInit() {
    this.getXpressData();
  }

  initaexpressDataForm() {
    this.aexpressDataForm.controls['xpressName'].setValue(this.productName);
    this.aexpressDataForm.controls['xpressSku'].setValue(this.productSku);
    this.aexpressDataForm.controls['puntoProductos'].setValue(0);
    this.aexpressDataForm.controls['creditoXpress'].setValue(0);
    this.aexpressDataForm.controls['bonoPrimerSponsorActivo'].setValue(0);
    this.aexpressDataForm.controls['bonoSegundoSponsorActivo'].setValue(0);
    this.aexpressDataForm.controls['xpressTolerance'].setValue(0);
    this.aexpressDataForm.controls['ngselectedFreeProductSku'].setValue('');
    this.aexpressDataForm.controls['freeProductQuantity'].setValue(0);
    this.aexpressDataForm.controls['mexicoCharge'].setValue(0);
    this.aexpressDataForm.controls['puntoProductos'].setErrors({ 'incorrect': true });
    this.aexpressDataForm.controls['creditoXpress'].setErrors({ 'incorrect': true });
    this.aexpressDataForm.controls['bonoPrimerSponsorActivo'].setErrors({ 'incorrect': true });
    this.aexpressDataForm.controls['bonoSegundoSponsorActivo'].setErrors({ 'incorrect': true });
    this.aexpressDataForm.controls['xpressTolerance'].setErrors({ 'incorrect': true });
    this.aexpressDataForm.controls['ngselectedFreeProductSku'].setErrors({ 'incorrect': true });
    this.selectedFreeProductSku = undefined;
    this.aexpressDataForm.controls['freeProductQuantity'].setErrors({ 'incorrect': true });
    this.aexpressDataForm.controls['mexicoCharge'].setErrors({ 'incorrect': true });
  }
  initaexpressDetailForm() {
    this.aexpressDetailForm.controls['ngselectedSku'].setValue('');
    this.aexpressDetailForm.controls['quantity'].setValue(0);
    this.aexpressDetailForm.controls['ngselectedSku'].setErrors({ 'incorrect': true });
    this.selectedSku = undefined;
    this.aexpressDetailForm.controls['quantity'].setErrors({ 'incorrect': true });
  }

  loadxpressDetail() {
    if (this.detailid) {
      this.setViewDetailMode(xpressDetailFormMode.VIEW);
      this.loadxpressDetailForm(this.detailid);
    } else {
      this.initaexpressDetailForm();
      this.setdisable = false;
      this.setViewDetailMode(xpressDetailFormMode.ADD);
    }
  }

  loadxpressDetailForm(detailid) {
    const loadxpressdetail = this.api.getAexpressdatadetail(this.id, detailid);
    loadxpressdetail
      .subscribe((xpressdetail) => {
        console.log(xpressdetail);
        this.aexpressDetailForm.setValue({
          id: this.detailid,
          ngselectedSku: xpressdetail.sku ? xpressdetail.sku : '',
          quantity: xpressdetail.quantity ? xpressdetail.quantity : 0,
        });
        // this.selectedSku = xpressdetail.sku ? xpressdetail.sku : '';
        this.mquantity = xpressdetail.quantity ? xpressdetail.quantity : 0;
      });
  }

  loadxpressData() {
    if (this.id) {
      this.setViewDataMode(xpressDataFormMode.VIEW);
      this.loadxpressDataForm(this.id);
    } else {
      this.setdisable = false;
      this.setViewDataMode(xpressDataFormMode.ADD);
    }
  }


  loadxpressDataForm(id) {
    const loadxpressdata = this.api.getAexpressdatarecord(id);
    loadxpressdata
      .subscribe((xpressdata) => {
        console.log(xpressdata);
        this.aexpressDataForm.setValue({
          id: this.id,
          xpressName: this.productName.replace(",", ""),
          xpressSku: this.productSku,
          puntoProductos: xpressdata.puntoProductos ? xpressdata.puntoProductos : '',
          creditoXpress: xpressdata.creditoXpress ? xpressdata.creditoXpress : 0,
          bonoPrimerSponsorActivo: xpressdata.bonoPrimerSponsorActivo ? xpressdata.bonoPrimerSponsorActivo : 0,
          bonoSegundoSponsorActivo: xpressdata.bonoSegundoSponsorActivo ? xpressdata.bonoSegundoSponsorActivo : 0,
          xpressTolerance: xpressdata.xpressTolerance ? xpressdata.xpressTolerance : 0,
          ngselectedFreeProductSku: xpressdata.freeProductSku ? xpressdata.freeProductSku : '',
          freeProductQuantity: xpressdata.freeProductQuantity ? xpressdata.freeProductQuantity : 0,
          mexicoCharge: xpressdata.mexicoCharge ? xpressdata.mexicoCharge : 0,
        });
        this.mxpressName = xpressdata.name ? xpressdata.name : '';
        this.mxpressSku = xpressdata.sku ? xpressdata.sku : '';
        this.mpuntoProductos = xpressdata.puntoProductos ? xpressdata.puntoProductos : 0;
        this.mcreditoXpress = xpressdata.creditoXpress ? xpressdata.creditoXpress : 0;
        this.mbonoPrimerSponsorActivo = xpressdata.bonoPrimerSponsorActivo ? xpressdata.bonoPrimerSponsorActivo : 0;
        this.mbonoSegundoSponsorActivo = xpressdata.bonoSegundoSponsorActivo ? xpressdata.bonoSegundoSponsorActivo : 0;
        this.mxpressTolerance = xpressdata.xpressTolerance ? xpressdata.xpressTolerance : 0;
        this.mfreeProductQuantity = xpressdata.freeProductQuantity ? xpressdata.freeProductQuantity : 0;
        this.mmexicoCharge = xpressdata.mexicoCharge ? xpressdata.mexicoCharge : 0;
        this.selectedFreeProductSku = xpressdata.freeProductSku ? xpressdata.freeProductSku : '';
      });

  }

  save() {
    let xpressname = this.aedesktopservice.normalizeString(this.productName);
    let xpresssku = this.aedesktopservice.normalizeString(this.productSku);
    var data: Aexpressdatamodel = {
      id: this.id,
      copy: false,
      xpressName: xpressname,
      xpressSku: xpresssku,
      puntoProductos: this.mpuntoProductos,
      creditoXpress: this.mcreditoXpress,
      bonoPrimerSponsorActivo: this.mbonoPrimerSponsorActivo,
      bonoSegundoSponsorActivo: this.mbonoSegundoSponsorActivo,
      xpressTolerance: this.mxpressTolerance,
      freeProductSku: this.selectedFreeProductSku,
      freeProductQuantity: this.mfreeProductQuantity,
      mexicoCharge: this.mmexicoCharge,
    };
    console.log('id ' + data.id);
    console.log('xpressName ' + data.xpressName);
    console.log('xpressSku ' + data.xpressSku);

    this.http.post<number>(xpressdataserviceUrl, data, httpOptions).subscribe(
      res => {
        console.log('Xpress products ' + res);
        this.acdcNotificationsService.toast({
          title: 'Registro',
          message: 'Item updated',
          timeout: 3000,
          notificationLevel: 'Success'
        });
        this.getXpressData();
        this.back();
      });
  }

  saveCopy() {
    let xpressname = this.aedesktopservice.normalizeString(this.mxpressName);
    let xpresssku = this.aedesktopservice.normalizeString(this.mxpressSku);
    this.api.getValidXpressdata(
      this.id, xpressname, xpresssku)
      .subscribe({
        next: dataz => {
          if (dataz.validResult == 'ko'
            || this.productName == this.mxpressName
            || this.productSku == this.mxpressSku) {
            if (dataz.validResult == 'ko') {
              this.acdcNotificationsService.toast({
                title: 'Product Name or SKU already in database',
                timeout: 3000,
                notificationLevel: 'Error'
              });
            } else {
              this.acdcNotificationsService.toast({
                title: 'Must change product Name and/or SKU',
                timeout: 3000,
                notificationLevel: 'Error'
              });
            }
          }
          else {
            var data: Aexpressdatamodel = {
              id: this.id,
              copy: true,
              xpressName: xpressname,
              xpressSku: xpresssku,
              puntoProductos: this.mpuntoProductos,
              creditoXpress: this.mcreditoXpress,
              bonoPrimerSponsorActivo: this.mbonoPrimerSponsorActivo,
              bonoSegundoSponsorActivo: this.mbonoSegundoSponsorActivo,
              xpressTolerance: this.mxpressTolerance,
              freeProductSku: this.selectedFreeProductSku,
              freeProductQuantity: this.mfreeProductQuantity,
              mexicoCharge: this.mmexicoCharge,
            };
            this.http.post<number>(xpressdataserviceUrl, data, httpOptions).subscribe(
              res => {
                console.log('Xpress products ' + res);
                this.acdcNotificationsService.toast({
                  title: 'Registro',
                  message: 'Item updated',
                  timeout: 3000,
                  notificationLevel: 'Success'
                });
                this.getXpressData();
                this.back();
              });
          }
        }
      })
  }

  saveDetail() {
    var datadetail: Aexpressdatadetailmodel = {
      id: this.detailid,
      xdataId: this.id,
      sku: this.selectedSku,
      quantity: this.mquantity,
    }
    console.log('id ' + datadetail.id);
    console.log('xdataId ' + datadetail.xdataId);
    console.log('sku ' + datadetail.sku);
    console.log('quantity ' + datadetail.quantity);
    this.http.post<number>(xpressdetailserviceUrl, datadetail, httpOptions).subscribe(
      res => {
        console.log('Xpress products ' + res);
        this.acdcNotificationsService.toast({
          title: 'Registro',
          message: 'Item updated',
          timeout: 3000,
          notificationLevel: 'Success'
        });
        this.getXpressDetail();
        this.backdetail();
      });
  }

  getXpressData() {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Xpress data',
      timeout: 6000,
      notificationLevel: 'Info'
    });
    this.api.getAexpressdata().toPromise().then((data: Aexpressdatalist[]) => {
      this.aexpressdata = data;
      this.acdcNotificationsService.deleteToast(acdcid);
      //      console.log('xpressdata ' + this.aexpressdata);
    });
  }

  getXpressDetail() {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Xpress detail',
      timeout: 6000,
      notificationLevel: 'Info'
    });
    this.api.getAexpressdatadetaillist(this.id).toPromise().then((data: Aexpressdatadetaillist[]) => {
      this.aexpressdetail = data;
      this.acdcNotificationsService.deleteToast(acdcid);
      console.log('xpressdetail ' + this.aexpressdetail);
    });
  }
  getXpressDetailKeyValue() {
    this.api.getAexpressdetailkeyvalue().toPromise().then((data: Aexpressdatakeyvalue[]) => {
      this.aeproducts = data;
      this.aedetailproducts = [...this.aeproducts];
      this.aedetailproducts.shift();
      console.log('xpressdatakeyvalue ' + this.aeproducts[0]);
      this.initaexpressDataForm();
      this.initaexpressDetailForm();
    });
  }

  xpressdataback() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  back() {
    this.showxpressData = true;
    this.showxpressDetail = false;
    this.showForm = false;
    this.showDetailForm = false;

  }

  backdetail() {
    this.showxpressData = false;
    this.showxpressDetail = true;
    this.showForm = false;
    this.showDetailForm = false;
  }

  xpressNameChange(event) {
    this.mxpressName = event.target.value;
  }
  xpressSkuChange(event) {
    this.mxpressSku = event.target.value;
  }
  puntoProductosChange(event) {
    this.mpuntoProductos = event.target.value.replace("$", "").replace(",", "");
  }
  creditoXpressChange(event) {
    this.mcreditoXpress = event.target.value.replace("$", "").replace(",", "");
  }
  bonoPrimerSponsorActivoChange(event) {
    this.mbonoPrimerSponsorActivo = event.target.value.replace("$", "").replace(",", "");
  }
  bonoSegundoSponsorActivoChange(event) {
    this.mbonoSegundoSponsorActivo = event.target.value.replace("$", "").replace(",", "");
  }
  xpressToleranceChange(event) {
    this.mxpressTolerance = event.target.value.replace("$", "").replace(",", "");
  }
  onFreeProductSkuChange(event) {
    this.selectedFreeProductSku = event.key;
    if (this.selectedFreeProductSku.length == 0) {
      this.mfreeProductQuantity = 0;
      this.aexpressDataForm.controls['freeProductQuantity'].setValue(0);
    }
  }
  freeProductQuantityChange(event) {
    this.mfreeProductQuantity = event.target.value.replace("$", "").replace(",", "");
    if (this.selectedFreeProductSku.length == 0) {
      this.mfreeProductQuantity = 0;
      this.aexpressDataForm.controls['freeProductQuantity'].setValue(0);
    }
  }
  mexicoChargeChange(event) {
    this.mmexicoCharge = event.target.value.replace("$", "").replace(",", "");
  }

  onSkuChange(event) {
    this.selectedSku = event.key;
  }

  quantityChange(event) {
    this.mquantity = event.target.value.replace("$", "").replace(",", "");
  }

  xpressdataEditDialog(event) {
    var selectedxpressdata = event.data.id;
    this.id = selectedxpressdata;
    this.productName = event.data.name;
    this.productSku = event.data.sku;
    this.loadxpressData();
    this.showForm = true;
    this.showCopy = true;
    this.showxpressData = false;
    this.showxpressDetail = false;
    this.labelContent = 'Editing Express Packages';
  }

  xpressdetailEditDialog(event) {
    var selectedxpressdetail = event.data.id
    this.detailid = selectedxpressdetail;
    this.loadxpressDetail()
    this.showForm = false;
    this.showCopy = false;
    this.showDetailForm = true;
    this.showxpressData = false;
    this.showxpressDetail = false;
  }

  xpressdetailCreateDialog() {
    this.detailid = 0;
    this.loadxpressDetail()
    this.showForm = false;
    this.showCopy = false;
    this.showDetailForm = true;
    this.showxpressData = false;
    this.showxpressDetail = false;
  }

  xpressCopy() {
    this.detailid = 0;
    this.loadxpressDetail()
    this.showForm = true;
    this.showCopy = false;
    this.showDetailForm = false;
    this.showxpressData = false;
    this.showxpressDetail = false;
    this.labelContent = 'Copying Express Packages';
  }

  xpressdataDeleteDialog(event) {
    var selectedxpressdata = this.aexpressdata.find(x => x.id === event.data.id)
    this.id = selectedxpressdata.id;
    var fixedproductlist = selectedxpressdata.fixedProductsList;
    console.log(event.data);
    if (fixedproductlist == false) {
      this.acdcNotificationsService.toast({
        title: 'Not a Super Express Package',
        timeout: 3000,
        notificationLevel: 'Error'
      });
    }
    else {
      this.getXpressDetail();
      this.showForm = false;
      this.showCopy = false;
      this.showDetailForm = false;
      this.showxpressData = false;
      this.showxpressDetail = true;
    }

  }
  ngOnDestroy(): void {
    this.unsubscribedata$.next();
    this.unsubscribedata$.complete();
    this.unsubscribedetail$.next();
    this.unsubscribedetail$.complete();
  }


}

