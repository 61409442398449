import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse,  } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map, retry } from 'rxjs/operators';
import { Aeorganizationtree } from './aetreeshow_class_organizationtree';
import { Aebonusoperator } from './aetreeshow_class_aebonusoperator';
import { Aebonusgroup } from './aetreeshow_class_aebonusgroup';

const bonusgroupserviceUrl = `${environment.apiServiceUrl}/aebonusgroup`;
const bonusoperatorserviceUrl = `${environment.apiServiceUrl}/aebonusoperator`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const organizationserviceUrl = `${environment.apiServiceUrl}/aeorganizationtree`;

@Injectable({
  providedIn: 'root'
})

export class AetreeshowService {

  constructor(private http: HttpClient) { }

 
  getOrganization (customerdn, ciclosid,withvolume,withbonus): Observable<Aeorganizationtree[]> {
    const url = `${organizationserviceUrl}/${customerdn},${ciclosid},${withvolume},${withbonus}`;
    return this.http.get<Aeorganizationtree[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aeorganization')),
        catchError(this.handleError('getOrganization',[]))
      );
  }

  getBonusOperator (): Observable<Aebonusoperator[]> {
    const url = `${bonusoperatorserviceUrl}`;
    return this.http.get<Aebonusoperator[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched AeBonusOperator')),
        catchError(this.handleError('getBonusOperator', []))
      );
  }

  getBonusGroup(ciclosid: string, customerdn: string): Observable<Aebonusgroup> {
    const url = `${bonusgroupserviceUrl}/${ciclosid},${customerdn}`;
    return this.http.get<Aebonusgroup>(url, httpOptions);
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); 
      return of(result as T);
    };
  }

}
