import { Component, OnInit } from '@angular/core';
import { AeciclosService } from './aeciclo_service.service';
import { Aeciclolist } from './aeciclo_class_list';
import {Router} from '@angular/router';
import { CsvExport } from '../aeshared/aeshared_class_csvexport';


@Component({
  selector: 'ngx-aeciclo-crud',
  templateUrl: './aeciclo_crud.component.html',
  styleUrls: ['./aeciclo_crud.component.scss']
})
export class Aeciclo_Crud_Component implements OnInit {

  data: Aeciclolist[] = [];
  isLoadingResults = true;
  aeciclos: Aeciclolist[];

    settings = {
    actions: {
      columnTitle: '',
      delete: false,
      },  
    mode: 'external',
    hideHeader: true,
    pager: {
      display: true,
      perPage: 5,
    },
    add: {
      addButtonContent: '<i class="ion-ios-plus-outline"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
    },
    columns: {
      ciclosId: {
        title: 'Cycle',
        width: '10%'
      },
      descripCiclos: {
        title: 'Description',
        width: '30%'
      }, 
      status: {
        title: 'Status',
        width: '10%' 
      },
      adminStatus: {
        title: 'Admin Status',
        width: '10%' 
      },
      startDate: {
        title: 'From',
        width: '15%' 
      } ,
      finishDate: {
        title: 'To',
        width: '15%' 
      } ,
      id: {
        title: 'Serial',
        width: '10%'
      }, 
    }
  };

  constructor(private api: AeciclosService, private router: Router) { }

  ngOnInit() {
    this.api.getAeciclos().subscribe((data: Aeciclolist[]) => {
      this.aeciclos = data;
    });
  }

  openCreateDialog() {
    const url = '/aeciclo';
    this.router.navigate([url]);
  }

  openEditDialog(event) {
   const url = '/aeciclo/'+`${event.data.id}`;
   this.router.navigate([url]);
 }
 back() {
  const url = '/aeuserdashboard'
  this.router.navigate([url]);
}

csv()
{
  CsvExport.exportToCsv('Cycles.csv', this.aeciclos);
}

}
