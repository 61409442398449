
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

const xpressdetailkeyvalueserviceUrl = `${environment.apiServiceUrl}/aexpressdetailkeyvalue`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

@Component({
  template: `
  <nb-checkbox status="primary" [ngModel]="selectedcenter"  (change)="toggleInclude($event)"></nb-checkbox>
`,
})

export class DefaultCenterRenderComponent implements ViewCell, OnInit {

  @Input() value: number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  public selectedcenter: boolean;
  constructor(private http: HttpClient,) { }

  ngOnInit() {
    if (this.value == 0) { this.selectedcenter = false; } else { this.selectedcenter = true; }
    console.log(this.selectedcenter);
  }

  toggleInclude(event) {
    this.selectedcenter = event.target.checked;
    var data = {
      "id": this.rowData.id,
      "checked": this.selectedcenter
    };
    console.log(data);
    this.http.post<number>(xpressdetailkeyvalueserviceUrl, data, httpOptions).subscribe(
      res => {
        console.log('Result ' + res);
        this.save.emit(res);
      });
  }
}