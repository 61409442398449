import { Component, OnInit, AfterViewInit, ViewChild, OnChanges } from '@angular/core';
import { AcdcNotificationsService } from 'acdc-notifications';
import { Aebonusregisterservice } from './aebonusregister_service_data.service';
import { SelectedBonusRenderComponent } from './aebonusregister_render_selected.component';
import { OptionRenderComponent } from './aebonusregister_render_option.component';
import { ReservedBonusRenderComponent } from './aebonusregister_render_reserved.component';
import { BonusDeleteComponent } from './aebonusregister_render_deletebonus';
import { CsvExport } from '../aeshared/aeshared_class_csvexport';
import { Aebonusregisterlist } from './aebonusregister_class_aebonusregisterlist';
import { Aebonoscuponesprevios } from './aebonusregister_class_aebonoscuponesprevios';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
import { MatDialog } from '@angular/material';
import { ConfirmationDialog } from '../aeshared/aedialog.component';
import { AeformcantidadmesesComponent } from '../aebonusforms/aeformcantidadmeses/aeformcantidadmeses.component';
import { AeformcantidadlibreComponent } from '../aebonusforms/aeformcantidadlibre/aeformcantidadlibre.component';
import { AeformcantidadComponent } from '../aebonusforms/aeformcantidad/aeformcantidad.component';
import { AeformimporteComponent } from '../aebonusforms/aeformimporte/aeformimporte.component';
import { AeformcantidadreservarComponent } from '../aebonusforms/aeformcantidadreservar/aeformcantidadreservar.component';

import { Aebonusoperator } from './aebonusregister_class_aebonusoperator';
import { Aebonusgroups } from './aebonusregister_class_aebonusgroups';
import { Aebonusregistercustomer } from './aebonusregister_class_customer';
import { Aebonusxtralist } from './aebonusregister_class_aebonusxtralist';
import { AebonusregistercustomerService } from './aebonusregister_service_customer.service';
import { Aeciclokeyvalue } from '../aeshared/aeciclokeyvalue';

const bonusregisterwalletUrl = `${environment.apiServiceUrl}/aebonusregisterwallet`;
const bonusserviceUrl = `${environment.apiServiceUrl}/aebonusregister`;
const bonusassignwalletUrl = `${environment.apiServiceUrl}/aebonusassignwallet`;
const bonusjuniorserviceUrl = `${environment.apiServiceUrl}/aebonusjuniorregister`;
const bonusxtralistserviceUrl = `${environment.apiServiceUrl}/aebonusxtralist`;
const bonusrepeatserviceUrl = `${environment.apiServiceUrl}/aebonusrepeat`;
const bonusoperatorserviceUrl = `${environment.apiServiceUrl}/aebonusoperator`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'dbconnection': 'us' })
};



@Component({
  selector: 'ngx-aebonusregister',
  templateUrl: 'aebonusregister.component.html',
  styleUrls: ['aebonusregister.component.scss'],
})
export class AebonusregisterComponent implements OnInit, AfterViewInit {

  cantidad: number;
  meses: number;





  aebonusForm: FormGroup;
  aedevbonusForm: FormGroup;
  aerepbonusForm: FormGroup;

  showbonuslist: boolean = true;
  showdevbonuslist: boolean = false;
  showrepbonuslist: boolean = false;
  showrepbonusform: boolean = false;
  showrepbonustable: boolean = false;
  showpreviousbonuslist: boolean = false
  disableOperatorSelector: boolean = false;


  aebonus: Aebonusregisterlist[];
  aebonusoperator: Aebonusoperator[];
  aebonusgroups: Aebonusgroups[];
  aedevbonus: Aebonusregisterlist[];
  aerepbonus: Aebonusregisterlist[];
  aebonusdevlist: Aebonusxtralist[];
  aebonusreplist: Aebonusxtralist[];
  aecustomers: Aebonusregistercustomer[];
  aedownlines: Aebonusregistercustomer[];
  aebonoscuponesprevios: Aebonoscuponesprevios[];
  aecycles: Aeciclokeyvalue[];
  public chk: boolean = false;
  selectedCycle = null;
  selectedCustomerDn = null;
  selectedBonusOperator = null;
  selectedGroupHead = null;
  selectedBonusOperatorLabel = null;
  selectedGroupHeadLabel = null;
  selectedDevBonus = 0;
  selectedRepBonus = 0;


  selectedDownlineDn = null;
  selectedDevBonusImporte = 0;
  selectedRepBonusImporte = 0;
  selectedRepBonusInicio = 0;
  selectedRepBonusFin = 0;
  selectedRepBonusFactor = 0;
  currentOperator: string = '';
  isAdmin: string = '';
  vlimit: number = 2000;

  selectedVpersonal = 0;
  selectedVgrupo = 0;
  selectedHasta = 0;
  selectedVpersonalstr = '0';
  selectedVgrupostr = '0';

  customerBuffer = [];
  bufferSize = 50;
  numberOfItemsFromEndBeforeFetchingMore = 10;
  loading = false;
  protected readonly unsubscribe$ = new Subject<void>();

  get ngselectedCustomerDn() { return this.aebonusForm.get('ngselectedCustomerDn'); }
  get ngselectedCycle() { return this.aebonusForm.get('ngselectedCycle'); }
  get ngselectedBonusOperator() { return this.aebonusForm.get('ngselectedBonusOperator'); }
  get ngselectedGroupHead() { return this.aebonusForm.get('ngselectedGroupHead'); }

  get ngselectedDownlineDn() { return this.aedevbonusForm.get('ngselectedDownlineDn'); }
  get ngselectedDevBonus() { return this.aedevbonusForm.get('ngselectedDevBonus'); }

  get ngselectedRepBonus() { return this.aerepbonusForm.get('ngselectedRepBonus'); }
  get ngselectedRepBonusInicio() { return this.aerepbonusForm.get('ngselectedRepBonusInicio'); }
  get ngselectedRepBonusFin() { return this.aerepbonusForm.get('ngselectedRepBonusFin'); }


  aebonusregistersettings = {


    actions: {
      columnTitle: '',
      add: false,
      delete: false,
      edit: true,
    },
    pager: {
      display: false,
    },

    mode: 'external',
    hideHeader: true,
    noDataMessage: '',
    edit: {
      editButtonContent: '<i class="ion-ios-plus-outline"></i>',
    },
    columns: {
      checked: {
        title: 'Selected',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: SelectedBonusRenderComponent,
        valuePrepareFunction: (cell, row) => {
          return cell;
        },
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(schecked => {
            if (schecked > 0) { this.getBonusData(); }
          });
        },
      },

      ciclosName: {
        title: 'Cycle',
        width: '10%'
      },
      codigoBono: {
        title: 'Code',
        width: '10%',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          if (row.codigoBono >= 107 && row.codigoBono <= 140 && row.codigoBono % 2 == 0) {
            return '<p class="cell_blue_left">' + cell + '</p>';
          }
          else if (row.codigoBono >= 107 && row.codigoBono <= 140 && row.codigoBono % 2 == 1) {
            return '<p class="cell_green_left">' + cell + '</p>';
          }
          else {
            return '<p class="cell_none_left">' + cell + '</p>';
          }
        }
      },
      bono: {
        title: 'Bonus Type',
        width: '20%',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          if (row.codigoBono >= 107 && row.codigoBono <= 140 && row.codigoBono % 2 == 0) {
            return '<p class="cell_blue_left">' + cell + '</p>';
          }
          else if (row.codigoBono >= 107 && row.codigoBono <= 140 && row.codigoBono % 2 == 1) {
            return '<p class="cell_green_left">' + cell + '</p>';
          }
          else {
            return '<p class="cell_none_left">' + cell + '</p>';
          }
        }
      },
      importe: {
        title: 'Amount',
        width: '10%'
      },
      cantidad: {
        title: 'Quantity',
        width: '8%'
      },
      reserved: {
        title: 'Reserved',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: ReservedBonusRenderComponent,
        valuePrepareFunction: (cell, row) => {
          return cell;
        },
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(schecked => {
            // if (schecked > 0) { this.getBonusData(); }
          });
        },
      },
      meses: {
        title: 'Months',
        width: '8%'
      },
      vgrupo: {
        title: 'VGroup',
        width: '8%'
      },
      strUtc: {
        title: 'Updated',
        width: '8%'
      },
      asignados: {
        title: 'Assigned',
        width: '8%',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          if (row.asignados > 0) {
            return '<p class="cell_green_back">' + cell + '</p>';
          }
          else {
            return '<p class="cell_none_center">' + cell + '</p>';
          }
        }
      },
      cupones: {
        title: 'Coupons',
        width: '8%',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          if (row.cupones > 0) {
            return '<p class="cell_green_back">' + cell + '</p>';
          }
          else {
            return '<p class="cell_none_center">' + cell + '</p>';
          }
        }
      },
      cuponesDn: {
        title: 'Dn Coup.',
        width: '8%',
        type: 'html',
        valuePrepareFunction: (cell, row) => {
          if (row.cuponesDn > 0) {
            return '<p class="cell_green_back">' + cell + '</p>';
          }
          else {
            return '<p class="cell_none_center">' + cell + '</p>';
          }
        }
      },

      fin: {
        title: 'Detail',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: OptionRenderComponent,
        valuePrepareFunction: (cell, row) => {
          return cell;
        },
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(response => {
            if (response.id > 0) { this.showDetail(response.codigoBono, response.asignados, response.cupones); }
          });
        },
      },
      id: {
        title: 'Options',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: BonusDeleteComponent,
        valuePrepareFunction: (cell, row) => {
          return cell;
        },
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(response => {
//            alert(response.assignBono);
            console.log('aqui ' + response);
            if (response.id > 0 && !response.assignBono) { this.processSelectedBonus(response.id, true); }
            else if (response.id > 0 && response.assignBono) { this.bonusAssign(response.id); }
          });
        },
      },
    },
  };

  devbonusregistersettings = {

    actions: {
      columnTitle: '',
      add: false,
      delete: false,
      edit: false,
    },
    pager: {
      display: false,
    },

    mode: 'external',
    hideHeader: true,
    noDataMessage: '',
    delete: {
      deleteButtonContent: '<img src="../../../assets/images/delete.jpg">',
    },
    columns: {
      ciclosName: {
        title: 'Cycle',
        width: '10%'
      },
      codigoBono: {
        title: 'Code',
        width: '10%'
      },
      bono: {
        title: 'Bonus Type',
        width: '40%'
      },
      importe: {
        title: 'Amount',
        width: '10%'
      },
      cantidad: {
        title: 'Quantity',
        width: '8%'
      },
      downlineDn: {
        title: 'DownLine',
        width: '8%'
      },
      vgrupo: {
        title: 'VGroup',
        width: '8%'
      },
      strUtc: {
        title: 'Updated',
        width: '8%'
      },
      Asignados: {
        title: 'Assigned',
        width: '8%'
      },
      id: {
        title: 'Delete',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: BonusDeleteComponent,
        valuePrepareFunction: (cell, row) => {
          return cell;
        },
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(response => {
            if (response.id > 0) { this.processSelectedBonus(response.id, true); }
          });
        },
      },
    },
  };

  repbonusregistersettings = {

    actions: {
      columnTitle: '',
      add: false,
      delete: false,
      edit: false,
    },
    pager: {
      display: false,
    },

    mode: 'external',
    hideHeader: true,
    noDataMessage: '',
    delete: {
      deleteButtonContent: '<img src="../../../assets/images/delete.jpg">',
    },
    columns: {
      ciclosName: {
        title: 'Cycle',
        width: '10%'
      },
      codigoBono: {
        title: 'Code',
        width: '10%'
      },
      bono: {
        title: 'Bonus Type',
        width: '40%'
      },
      importe: {
        title: 'Amount',
        width: '8%'
      },
      cantidad: {
        title: 'Quantity',
        width: '8%'
      },
      inicio: {
        title: 'Inicio',
        width: '8%'
      },
      strUtc: {
        title: 'Updated',
        width: '8%'
      },
      asignados: {
        title: 'Assigned',
        width: '8%'
      },
      id: {
        title: 'Delete',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: BonusDeleteComponent,
        valuePrepareFunction: (cell, row) => {
          return cell;
        },
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(response => {
            if (response.id > 0) { this.processSelectedBonus(response.id, true); }
          });
        },
      },
    },
  };

  bonoscuponespreviossettings = {

    actions: {
      columnTitle: '',
      add: false,
      delete: false,
      edit: false,
    },
    pager: {
      display: false,
    },

    mode: 'external',
    hideHeader: true,
    noDataMessage: '',
    columns: {
      customerDn: {
        title: 'Dn',
        width: '10%'
      },
      customerName: {
        title: 'Distributor',
        width: '40%'
      },
      bonosPrevios: {
        title: 'Assigned',
        width: '10%'
      },

      cuponesPrevios: {
        title: 'Coupons',
        width: '10%'
      },
    },
  };
  constructor(
    private router: Router, private route: ActivatedRoute,
    private acdcNotificationsService: AcdcNotificationsService,
    private bonusregisterapi: Aebonusregisterservice, private http: HttpClient,
    private localstorage: LocalStorageService,
    public dialog: MatDialog,
    private customerapi: AebonusregistercustomerService,
    private fb: FormBuilder,
    private devfb: FormBuilder,
    private repfb: FormBuilder
  ) {
    this.aebonusForm = this.fb.group({
      id: this.fb.control(''),
      ngselectCustomer: this.fb.control('', Validators.required),
      ngselectCycle: this.fb.control('', Validators.required),
      ngselectBonusOperator: this.fb.control('', Validators.required),
      ngselectGroupHead: this.fb.control('', Validators.required),
    });
    this.aedevbonusForm = this.devfb.group({
      id: this.fb.control(''),
      ngselectDownlineDn: this.devfb.control('', Validators.required),
      ngselectDevBonus: this.devfb.control('', Validators.required),
    });
    this.aerepbonusForm = this.repfb.group({
      id: this.fb.control(''),
      ngselectRepBonus: this.repfb.control('', Validators.required),
      ngselectedRepBonusInicio: this.repfb.control(0, Validators.required),
      ngselectedRepBonusFin: this.repfb.control(0, Validators.required),
    });
    this.currentOperator = this.localstorage.getItem("current");
    this.isAdmin = this.localstorage.getItem("isadmin");
    if (this.currentOperator == 'admin' || this.currentOperator == 'oschult') { this.vlimit = 0; }
  }

  ngOnInit(): void {
    this.aecycles = this.route.snapshot.data['aecicloRes'];
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Bonus Data',
      timeout: 3000,
      notificationLevel: 'Info'
    });
    this.bonusregisterapi.getBonusOperator().subscribe((opedata: Aebonusoperator[]) => {
      this.aebonusoperator = opedata;
    });
    this.bonusregisterapi.getDevbonus().subscribe((devdata: Aebonusxtralist[]) => {
      this.aebonusdevlist = devdata;
    });
    this.bonusregisterapi.getRepbonus().subscribe((repdata: Aebonusxtralist[]) => {
      this.aebonusreplist = repdata;
    });
    this.selectedCycle = null;
    this.selectedCustomerDn = null;
    this.initAebonusForm();
    this.initAedevbonusForm();
    this.initAerepbonusForm();
  }

  initAebonusForm() {
    this.selectedCustomerDn = null;
    this.aebonus = null;
    this.aedevbonus = null;
    this.aerepbonus = null;
  }
  initAedevbonusForm() {
    this.selectedDownlineDn = null;
    this.selectedDevBonus = null;
  }
  initAerepbonusForm() {
    this.selectedRepBonus = null;
    this.selectedRepBonusInicio = 0;
    this.selectedRepBonusFin = 0;
  }

  ngAfterViewInit() {
    // this.disableOperatorSelector = true;
  }

  getBonusData() {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Bonus data',
      timeout: 6000,
      notificationLevel: 'Info'
    });
    this.bonusregisterapi.getAecustomerdevbonus(this.selectedCustomerDn, this.selectedCycle,)
      .subscribe((datadev: Aebonusregisterlist[]) => {
        this.aedevbonus = datadev;
      });
    this.bonusregisterapi.getAecustomerrepbonus(this.selectedCustomerDn, this.selectedCycle, 2)
      .subscribe((datarep: Aebonusregisterlist[]) => {
        this.aerepbonus = datarep;
      });
    this.bonusregisterapi.getAecustomerbonus(this.selectedCustomerDn, this.selectedCycle,)
      .subscribe((data: Aebonusregisterlist[]) => {
        this.aebonus = data;
        this.acdcNotificationsService.deleteToast(acdcid);
      });

  }

  backToDashboard() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  bonusRegister() {
    var data = {
      "ciclosId": this.selectedCycle,
      "bonusId": 0,
      "currentOperator": this.currentOperator
    };
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Processing Wallets',
      timeout: 5000,
      notificationLevel: 'Info'
    });
    this.http.post<number>(bonusregisterwalletUrl, data, httpOptions).subscribe(
      res => {
        console.log('Result ' + res);
        this.getBonusData();
        this.acdcNotificationsService.deleteToast(acdcid);
      });
  }

  bonusAssign(bonusid) {
    //alert(bonusid);
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: '350px',
      height: '350px',
      data: {
        message: "Confirm Assigning Selected Bonus",
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        var data = {
          "ciclosId": this.selectedCycle,
          "bonusId": bonusid,
          "currentOperator": this.currentOperator
        };
        const acdcid: string = this.acdcNotificationsService.toast({
          title: 'Please wait... Assigning Bonus',
          timeout: 5000,
          notificationLevel: 'Info'
        });
        this.http.post<number>(bonusassignwalletUrl, data, httpOptions).subscribe(
          res => {
            console.log('Result ' + res);
            this.getBonusData();
            this.acdcNotificationsService.deleteToast(acdcid);
          });
      }
    });
  }

  csvDownload() {
    let today = new Date().toISOString().slice(0, 10)
    let csvfile = 'bonusSummary_' + today + '.csv';
    CsvExport.exportToCsv(csvfile, this.aebonus);
  }

  onCycleChange(event) {
    this.aebonus = null;
    this.selectedCycle = event.key;
    this.disableOperatorSelector = false;
    this.selectedBonusOperator = null;
    this.selectedGroupHead = null;
    this.selectedBonusOperatorLabel = null
    this.aebonusForm.controls['ngselectBonusOperator'].setValue(null);
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Customer Data',
      timeout: 20000,
      notificationLevel: 'Info'
    });
    this.customerapi.getAebonusregisterCustomers(this.selectedCycle, this.vlimit)
      .subscribe((data: Aebonusregistercustomer[]) => {
        this.aecustomers = data;
        this.initAebonusForm();
        this.initAedevbonusForm();
        this.initAerepbonusForm();
        this.acdcNotificationsService.deleteToast(acdcid);
      });
  }

  onBonusOperatorChange(event) {
    if (this.selectedCycle != null && this.selectedCustomerDn != null) {
      this.selectedBonusOperator = event.operatorDn;
      this.selectedBonusOperatorLabel = event.operator;
      var data = {
        "ciclosId": this.selectedCycle,
        "customerDn": this.selectedCustomerDn,
        "operadorDn": this.selectedBonusOperator
      };
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        width: '350px',
        height: '350px',
        data: {
          message: "Confirm Operator for selected Cycle",
        }
      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult == true) {
          this.http.post<number>(bonusoperatorserviceUrl, data, httpOptions).subscribe(
            res => {
              this.acdcNotificationsService.toast({
                title: 'Registered',
                timeout: 3000,
                notificationLevel: 'Info'
              });
            });
        }
        else {
          this.selectedBonusOperator = null
          this.selectedBonusOperatorLabel = null
          this.aebonusForm.controls['ngselectBonusOperator'].setValue(null);
        }
      });
    }
    else {
      this.acdcNotificationsService.toast({
        title: 'Please select Cycle and Customer',
        timeout: 4000,
        notificationLevel: 'Warn'
      });
      this.selectedBonusOperator = null
      this.selectedBonusOperatorLabel = null
      this.aebonusForm.controls['ngselectBonusOperator'].setValue(null);
    }
  }

  onCustomerChange(event) {
    this.initAebonusForm();
    this.initAedevbonusForm();
    this.initAerepbonusForm();
    this.selectedCustomerDn = event.customerDn;
    this.selectedVpersonal = event.vPersonal;
    this.selectedVgrupo = event.vGrupo;
    this.selectedVpersonalstr = event.vPersonalstr;
    this.selectedVgrupostr = event.vGrupostr;
    this.bonusregisterapi.getBonusOperatorxCycle(this.selectedCycle, this.selectedCustomerDn).subscribe({
      next: datab => {
        this.selectedBonusOperator = datab.operadorDn;
        this.selectedBonusOperatorLabel = datab.operator;
        this.aebonusForm.controls['ngselectBonusOperator'].setValue(datab.operadorDn);
        if (this.selectedBonusOperator == null || this.currentOperator == 'admin' || this.currentOperator == 'oschult') { this.disableOperatorSelector = false; }
        else { this.disableOperatorSelector = true; }
      }
    });
    this.bonusregisterapi.getBonusGroups(this.selectedCycle, this.selectedCustomerDn).subscribe({
      next: datab => {
        this.selectedGroupHead = datab.groupHeadDn;
        this.selectedGroupHeadLabel = datab.groupHead;
        this.aebonusForm.controls['ngselectedGroupHead'].setValue(datab.groupHeadDn);
      }
    });
    this.getBonusData();
  }

  onScrollToEnd() {
    this.fetchMore();
  }

  onScroll(end) {
    if (this.loading || this.aecustomers.length <= this.customerBuffer.length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.customerBuffer.length) {
      this.fetchMore();
    }
  }

  private fetchMore() {
    const len = this.customerBuffer.length;
    const more = this.aecustomers.slice(len, this.bufferSize + len);
    this.loading = true;
    // using timeout here to simulate backend API delay
    setTimeout(() => {
      this.loading = false;
      this.customerBuffer = this.customerBuffer.concat(more);
    }, 200)
  }





  processSelectedBonus(selectedBono: number, deleteBono: boolean) {
    var data = {
      "id": selectedBono,
      "cantidad": 0,
      "meses": 0,
      "inicio": 0,
      "downlineDn": '',
      "checked": false,
      "importe": 0,
      "borrar": deleteBono,
      "currentOperator": this.currentOperator,
      "reserved": false,
    };

    const loadregistrobono = this.bonusregisterapi.getAecustomersinglebonus(selectedBono);
    loadregistrobono
      .subscribe((aebono) => {
        if (aebono.cantidad == 0 && !deleteBono) {
          let codigoBonoValid: boolean = true;
          this.bonusregisterapi.getCodigoBonoValido(this.selectedCustomerDn, this.selectedCycle, aebono.codigoBono).subscribe({
            next: dataz => {
              if (dataz.validResult != "ok") {
                codigoBonoValid = false;
                this.acdcNotificationsService.toast({
                  message: dataz.validResult,
                  timeout: 3000,
                  notificationLevel: 'Error'
                });
              } else {
                if (aebono.codigoBono == 101 || (aebono.codigoBono >= 107 && aebono.codigoBono <= 140 && aebono.codigoBono != 117 && aebono.codigoBono != 121)) {
                  let bonosprevios = "0";
                  this.bonusregisterapi.getBonosPlusPrevios(this.selectedCustomerDn, this.selectedCycle, aebono.codigoBono).subscribe({
                    next: datap => {
                      bonosprevios = datap.bonosPrevios;
                      this.localstorage.setItem('bonusplusprevios', bonosprevios);
                      let dialogRef = this.dialog.open(AeformcantidadlibreComponent, {
                        width: '350px',
                        height: '350px',
                        data: { cantidad: aebono.cantidad, meses: aebono.meses }
                      });
                      dialogRef.afterClosed().subscribe(result => {
                        data.cantidad = result.cantidad;
                        data.meses = result.meses;
                        data.inicio = 0;
                        data.downlineDn = '';
                        data.checked = true;
                        data.importe = 0;
                        this.http.post<number>(bonusserviceUrl, data, httpOptions).subscribe(
                          res => {
                            this.getBonusData();
                          });
                      });
                    }
                  });
                }
                if (aebono.codigoBono == 117 || aebono.codigoBono == 121) {
                  let bonosprevios = "0";
                  this.bonusregisterapi.getBonosPlusPrevios(this.selectedCustomerDn, this.selectedCycle, aebono.codigoBono).subscribe({
                    next: datap => {
                      bonosprevios = datap.bonosPrevios;
                      this.localstorage.setItem('bonusplusprevios', bonosprevios);
                      let dialogRef = this.dialog.open(AeformcantidadreservarComponent, {
                        width: '350px',
                        height: '500px',
                        data: { cantidad: aebono.cantidad, meses: aebono.meses, reserve: false, }
                      });
                      dialogRef.afterClosed().subscribe(result => {
                        data.cantidad = result.cantidad;
                        data.meses = result.meses;
                        data.reserved = result.reserve;
                        data.inicio = 0;
                        data.downlineDn = '';
                        data.checked = true;
                        data.importe = 0;
                        this.http.post<number>(bonusserviceUrl, data, httpOptions).subscribe(
                          res => {
                            this.getBonusData();
                          });
                      });
                    }
                  });
                }
                if (aebono.codigoBono >= 201 && aebono.codigoBono <= 299) {
                  let bonosprevios = "0";
                  let bonosprevios2 = "0";
                  this.bonusregisterapi.getBonosPlusPrevios(this.selectedCustomerDn, this.selectedCycle, aebono.codigoBono).subscribe({
                    next: datap => {
                      bonosprevios = datap.bonosPrevios;
                      bonosprevios2 = datap.bonosPrevios2;
                      this.localstorage.setItem('bonusplusprevios', bonosprevios);
                      this.localstorage.setItem('bonusplusprevios2', bonosprevios2);
                      this.localstorage.setItem('bonuspluscustomerdn', this.selectedCustomerDn);
                      this.localstorage.setItem('bonusplusciclosid', this.selectedCycle);
                      let dialogRef = this.dialog.open(AeformcantidadmesesComponent, {
                        width: '700px',
                        height: '550px',
                        data: { cantidad: aebono.cantidad, meses: aebono.meses }
                      });
                      dialogRef.afterClosed().subscribe(result => {
                        data.cantidad = result.cantidad;
                        data.meses = result.meses;
                        data.inicio = 0;
                        data.downlineDn = '';
                        data.checked = true;
                        data.importe = 0;
                        this.http.post<number>(bonusserviceUrl, data, httpOptions).subscribe(
                          res => {
                            this.getBonusData();
                          });
                      });
                    }
                  });
                }
                if (
                  (aebono.codigoBono >= 102 && aebono.codigoBono <= 104) ||
                  (aebono.codigoBono >= 301 && aebono.codigoBono <= 499)
                ) {
                  let bonosprevios = "0";
                  this.bonusregisterapi.getBonosPlusPrevios(this.selectedCustomerDn, this.selectedCycle, aebono.codigoBono).subscribe({
                    next: datap => {
                      bonosprevios = datap.bonosPrevios;
                      this.localstorage.setItem('bonusplusprevios', bonosprevios);
                      let dialogRef = this.dialog.open(AeformcantidadComponent, {
                        width: '350px',
                        height: '350px',
                        data: { cantidad: aebono.cantidad }
                      });
                      dialogRef.afterClosed().subscribe(result => {
                        data.cantidad = result.cantidad;
                        data.meses = 0;
                        data.inicio = 0;
                        data.downlineDn = '';
                        data.checked = true;
                        data.importe = 0;
                        this.http.post<number>(bonusserviceUrl, data, httpOptions).subscribe(
                          res => {
                            this.getBonusData();
                          });
                      });
                    }
                  });
                }
                if (aebono.codigoBono >= 601 && aebono.codigoBono <= 699) {
                  let bonosprevios = "0";
                  this.bonusregisterapi.getBonosPlusPrevios(this.selectedCustomerDn, this.selectedCycle, aebono.codigoBono).subscribe({
                    next: datap => {
                      bonosprevios = datap.bonosPrevios;
                      this.localstorage.setItem('bonusplusprevios', bonosprevios);
                      let dialogRef = this.dialog.open(AeformimporteComponent, {
                        width: '700px',
                        height: '550px',
                        data: { cantidad: aebono.cantidad, importe: aebono.importe }
                      });
                      dialogRef.afterClosed().subscribe(result => {
                        data.cantidad = result.cantidad;
                        data.meses = 0;
                        data.inicio = 0;
                        data.downlineDn = '';
                        data.checked = true;
                        data.importe = result.importe;
                        this.http.post<number>(bonusserviceUrl, data, httpOptions).subscribe(
                          res => {
                            this.getBonusData();
                          });
                      });
                    }
                  });
                }
              }
            }
          });
        }
        else if (aebono.cantidad > 0 && deleteBono) {
          const dialogRef = this.dialog.open(ConfirmationDialog, {
            width: '350px',
            height: '350px',
            data: {
              message: "Confirm Deleting Selected Bonus",
            }
          });
          dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult == true) {
              data.cantidad = aebono.cantidad;
              this.http.post<number>(bonusserviceUrl, data, httpOptions).subscribe(
                res => {
                  this.getBonusData();
                });

            }
          });
        }
      });
  }

  devBonusBack() {
    this.showbonuslist = true;
    this.showdevbonuslist = false;
    this.showrepbonuslist = false;

  }

  repBonusBack() {
    this.showbonuslist = true;
    this.showdevbonuslist = false;
    this.showrepbonuslist = false;

  }

  bonusEditDialog(event) {
    this.processSelectedBonus(event.data.id, false);

  }

  devbonusCreateDialog() {
    if (this.selectedCustomerDn != null && this.selectedCycle != null) {
      if (this.selectedCustomerDn != 'D1070442') {
        this.showbonuslist = false;
        this.showdevbonuslist = true;
        this.showrepbonuslist = false;
      } else {
        this.acdcNotificationsService.toast({
          title: 'Customer disabled for Dev Bonus"',
          timeout: 3000,
          notificationLevel: 'Error'
        });
      }
    }
    else {
      this.acdcNotificationsService.toast({
        title: 'You must Select Cycle and Customer"',
        timeout: 3000,
        notificationLevel: 'Error'
      });

    }
  }

  repbonusCreateDialog() {
    if (this.selectedCustomerDn != null && this.selectedCycle != null) {
      this.showbonuslist = false;
      this.showdevbonuslist = false;
      this.showrepbonuslist = true;
      this.showrepbonusform = true;
      this.showrepbonustable = false;

    }
    else {
      this.acdcNotificationsService.toast({
        title: 'You must Select Cycle  and Customer"',
        timeout: 3000,
        notificationLevel: 'Error'
      });

    }
  }


  addBonusJunior() {
    var data = {
      "ciclosId": this.selectedCycle,
      "customerDn": this.selectedCustomerDn
    };


    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: '350px',
      height: '350px',
      data: {
        message: "Confirm Adding Junior Bonus to Customer",
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        this.http.post<number>(bonusjuniorserviceUrl, data, httpOptions).subscribe(
          res => {
            this.getBonusData();
          });
      }
    });
  }

  devbonusDeleteDialog(event) {
    this.bonusregisterapi.deleteXtraBonus(event.id);
    console.log('dev delete');

  }

  repbonusDeleteDialog(event) {
    this.bonusregisterapi.deleteXtraBonus(event.id);
    //alert('delete ' + event.id);
    this.getrepBonusData();
    console.log('rep delete');

  }

  ondownlineChange(event) {
    this.selectedDownlineDn = event.customerDn;
  }

  onBonusDevChange(event) {

    this.selectedDevBonus = event.codigoBono;
    this.selectedDevBonusImporte = event.importe;
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Customer Data',
      timeout: 20000,
      notificationLevel: 'Info'
    });
    this.customerapi.getAebonusregisterDownlines(this.selectedCustomerDn, this.selectedCycle, this.selectedDevBonus)
      .subscribe((data: Aebonusregistercustomer[]) => {
        this.aedownlines = data;
        this.acdcNotificationsService.deleteToast(acdcid);
      });
  }

  onBonusRepChange(event) {
    this.selectedRepBonus = event.codigoBono;
    this.selectedRepBonusImporte = event.importe;
    this.selectedRepBonusInicio = event.inicioRepetido;
    this.selectedRepBonusFin = event.finRepetido;
    this.selectedRepBonusFactor = event.factor;
    if (this.selectedRepBonusFactor == 0) { this.selectedRepBonusFactor = 9999999; }
    this.aerepbonusForm.controls['ngselectedRepBonusInicio'].setValue(event.inicioRepetido);
    if (this.selectedVgrupo < this.selectedRepBonusInicio) {
      this.selectedHasta = 0;
      this.aerepbonusForm.controls['ngselectedRepBonusFin'].setValue(0);
    }
    else {
      if (this.selectedRepBonus == 105) {
        this.selectedHasta = Math.min(this.selectedRepBonusFin, Math.floor(this.selectedVgrupo / this.selectedRepBonusFactor) * this.selectedRepBonusFactor);
      }
      else {
        this.selectedHasta = Math.floor(this.selectedVgrupo / this.selectedRepBonusFactor) * this.selectedRepBonusFactor;
      }
      this.aerepbonusForm.controls['ngselectedRepBonusFin'].setValue(this.selectedHasta);
    }
    this.getrepBonusData();
  }

  saveDevBonus() {
    var data = {
      "CiclosId": this.selectedCycle,
      "customerDn": this.selectedCustomerDn,
      "codigoBono": this.selectedDevBonus,
      "Cantidad": 1,
      "Importe": this.selectedDevBonusImporte,
      "downlineDn": this.selectedDownlineDn,
      "inicio": 0,
      "vgrupo": this.selectedVgrupo,
      "checked": true,
      "currentOperator": this.currentOperator
    };
    this.http.post<number>(bonusxtralistserviceUrl, data, httpOptions).subscribe(
      res => {
        this.getdevBonusData();
      });
  }

  saveRepBonus() {
    // if (this.ngselectedRepBonusFin.value < this.ngselectedRepBonusInicio.value
    //   || this.ngselectedRepBonusFin.value > this.selectedHasta
    // ) {
    //   if (this.ngselectedRepBonusFin.value > this.selectedHasta) {
    //     this.acdcNotificationsService.toast({
    //       title: 'End must be less than or equal to ' + this.selectedHasta.toString(),
    //       timeout: 3000,
    //       notificationLevel: 'Error'
    //     });
    //   }
    //   else {
    //     this.acdcNotificationsService.toast({
    //       title: 'End must be greater than or equal to Start',
    //       timeout: 3000,
    //       notificationLevel: 'Error'
    //     });

    //   }
    if (this.ngselectedRepBonusFin.value < this.ngselectedRepBonusInicio.value) {
      this.acdcNotificationsService.toast({
        title: 'End must be greater than or equal to Start',
        timeout: 3000,
        notificationLevel: 'Error'
      });
    }
    else {
      var data = {
        "customerDn": this.selectedCustomerDn,
        "ciclosId": this.selectedCycle,
        "selectedBono": this.selectedRepBonus,
        "inicioRepetido": this.ngselectedRepBonusInicio.value,
        "finRepetido": this.ngselectedRepBonusFin.value,
        "currentOperator": this.currentOperator
      };
      this.http.post<number>(bonusrepeatserviceUrl, data, httpOptions).subscribe(
        res => {
          this.getrepBonusData();
        });
    }
  }

  getdevBonusData() {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Bonus data',
      timeout: 6000,
      notificationLevel: 'Info'
    });
    this.bonusregisterapi.getAecustomerdevbonus(this.selectedCustomerDn, this.selectedCycle,)
      .subscribe((datadev: Aebonusregisterlist[]) => {
        this.aedevbonus = datadev;
        this.acdcNotificationsService.deleteToast(acdcid);
      });
  }

  getrepBonusData() {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Bonus data',
      timeout: 6000,
      notificationLevel: 'Info'
    });
    this.bonusregisterapi.getAecustomerrepbonus(this.selectedCustomerDn, this.selectedCycle, this.selectedRepBonus)
      .subscribe((datarep: Aebonusregisterlist[]) => {
        this.aerepbonus = datarep;
        if (this.aerepbonus.length > 0) {
          this.showrepbonustable = true;
          this.showrepbonusform = false;
        } else {
          this.showrepbonustable = false;
          this.showrepbonusform = true;
        }
        this.acdcNotificationsService.deleteToast(acdcid);
      });
  }

  showDetail(codigobono, asignados, cupones) {
    if (asignados > 0 || cupones > 0) {
      this.getBonosCuponesPrevios(codigobono);
    }
  }

  previousbonusBack() {
    this.showpreviousbonuslist = false;
    this.showbonuslist = true;

  }

  getBonosCuponesPrevios(codigoBono: number) {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Bonus data',
      timeout: 6000,
      notificationLevel: 'Info'
    });
    this.bonusregisterapi.getBonosCuponesPrevios(this.selectedCustomerDn, this.selectedCycle, codigoBono)
      .subscribe((datadev: Aebonoscuponesprevios[]) => {
        this.aebonoscuponesprevios = datadev;
        this.acdcNotificationsService.deleteToast(acdcid);
        this.showbonuslist = false;
        this.showpreviousbonuslist = true;
      });
  }

}