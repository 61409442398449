import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Router, ActivatedRoute } from '@angular/router';
import { AcdcNotificationsService } from 'acdc-notifications';
import { Aedistributioncenterordersservice } from './aemasterdistribution_service_data.service';
import { Aedistributioncenterorders } from './aemasterdistribution_class_aedistributioncenterorders';
import { environment } from '../../../environments/environment';
import { CsvExport } from '../aeshared/aeshared_class_csvexport';
import { MatDialog, } from '@angular/material';
import { ConfirmationDialog } from '../aeshared/aedialog.component';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Aedistributioncenterlist } from './aemasterdistribution_class_aedistributioncenterlist';
import { Aedistributioncenteravailable } from './aemasterdistribution_class_aedistributioncenteravailable';

const centersserviceUrl = `${environment.apiServiceUrl}/aedistributioncenterlist`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};


@Component({
  selector: 'ngx-aemasterdistribution',
  templateUrl: 'aemasterdistribution.component.html',
  styleUrls: ['aemasterdistribution.component.css'],
})
export class AemasterdistributionComponent implements OnInit, AfterViewInit, OnDestroy {

  public setdisable: boolean = false;
  aedistributioncenterorders: Aedistributioncenterorders[];
  aedistributioncenterlist: Aedistributioncenterlist[];
  aedistributioncenteravailable: Aedistributioncenteravailable[];
  aepositions = [{ key: 'U', value: 'Upline' }, { key: 'D', value: 'Downline' }, { key: 'N', value: 'None' }, { key: 'A', value: 'All' },];

  aedistributioncenterorderssettings = {

    actions: {
      columnTitle: '',
      delete: false,
      add: false,
      edit: true,
    },
    pager: {
      display: true,
      perPage: 20,
    },

    mode: 'external',
    hideHeader: true,
    noDataMessage: '',

    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
    },
    columns: {
      invoiceNumber: {
        title: 'Invoice',
        width: '10%',
        editable: false,
      },
      invoiceDate: {
        title: 'Date',
        width: '10%',
        editable: false,
      },
      customerDn: {
        title: 'DN',
        width: '10%',
        editable: false,
      },
      customer: {
        title: 'Distributor',
        width: '40%',
        editable: false,
      },
      city: {
        title: 'City',
        width: '10%',
        editable: false,
      },
      stateCode: {
        title: 'State',
        width: '10%',
        editable: false,
      },
      id: {
        title: 'Serial',
        width: '5%',
        editable: false,
      },
    },
  };

  aedistributioncenterlistsettings = {
    actions: {
      columnTitle: '',
      add: false,
      edit: true,
      delete: true,
    },
    pager: {
      display: true,
      perPage: 20,
    },

    mode: 'external',
    hideHeader: false,
    noDataMessage: '',

    edit: {
      editButtonContent: '<i class="nb-arrow-retweet"></i>',
    },
    delete: {
      deleteButtonContent: '<img src="../../../assets/images/view.jpg">',
    },

    columns: {
      position: {
        title: 'Position',
        width: '5%',
        editable: false,
      },
      distance: {
        title: 'Distance',
        width: '5%',
        editable: false,
      },
      inventory: {
        title: 'Inventory',
        width: '5%',
        editable: false,
      },
      name: {
        title: 'Name',
        width: '30%',
        editable: false,
      },
      dcenterDn: {
        title: 'Center DN',
        width: '10%',
        editable: false,
      },
      saddress: {
        title: 'Address',
        width: '30%',
        editable: false,
      },
      id: {
        title: 'Serial',
        width: '5%',
        editable: false,
      },
    },
  };

  aedistributioncenteravailablesettings = {
    actions: {
      columnTitle: '',
      add: false,
      edit: false,
      delete: false,
    },
    pager: {
      display: true,
      perPage: 20,
    },

    mode: 'external',
    hideHeader: true,
    noDataMessage: '',

    columns: {
      sku: {
        title: 'Sku',
        width: '10%',
        editable: false,
      },
      name: {
        title: 'Product',
        width: '25%',
        editable: false,
      },
      quantity: {
        title: 'Quantity',
        width: '10%',
        editable: false,
      },
      availableQuantity: {
        title: 'Available',
        width: '30%',
        editable: false,
      },
    },
  };

  showcenterOrders: boolean = true;
  showcenterList: boolean = false;
  showcenterInventory: boolean = false;


  aeproducts = [];

  protected readonly unsubscribedata$ = new Subject<void>();
  protected readonly unsubscribedetail$ = new Subject<void>();

  selectedOrderId: number = 0;
  selectedCustomerDn: string = '';
  selectedPosition: string = 'A';
  selectedDistributionCenter: number = 1004;
  selectedDistributioncenterId: number = 0;


  labelContent = 'Distribution Centers '

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private acdcNotificationsService: AcdcNotificationsService,
    private http: HttpClient,
    private api: Aedistributioncenterordersservice) {
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.getCenterOrders();
  }


  getCenterOrders() {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Orders Information',
      timeout: 60000,
      notificationLevel: 'Info'
    });
    this.api.getAedistributioncenterorders().toPromise().then((data: Aedistributioncenterorders[]) => {
      this.aedistributioncenterorders = data;
      this.acdcNotificationsService.deleteToast(acdcid);
    });
  }

  getCentersList() {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Centers List',
      timeout: 60000,
      notificationLevel: 'Info'
    });
    this.api.getAedistributioncenterslist(this.selectedOrderId, this.selectedPosition).toPromise()
      .then((data: Aedistributioncenterlist[]) => {
        this.aedistributioncenterlist = data;
        this.acdcNotificationsService.deleteToast(acdcid);
      });
  }
  getCentersAvailable() {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Centers Inventory',
      timeout: 60000,
      notificationLevel: 'Info'
    });
    this.api.getAedistributioncenteravailable(this.selectedOrderId, this.selectedDistributioncenterId)
      .toPromise()
      .then((data: Aedistributioncenteravailable[]) => {
        this.aedistributioncenteravailable = data;
        this.acdcNotificationsService.deleteToast(acdcid);
      });
  }

  centerOrdersback() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  back() {
    this.getCenterOrders();
    this.showcenterOrders = true;
    this.showcenterList = false;
  }

  backcenters() {
    this.showcenterOrders = false;
    this.showcenterList = true;
    this.showcenterInventory = false;
  }

  ngOnDestroy(): void {
    this.unsubscribedata$.next();
    this.unsubscribedata$.complete();
    this.unsubscribedetail$.next();
    this.unsubscribedetail$.complete();
  }

  centerordersEditDialog(event) {
    this.selectedOrderId = event.data.id;
    this.selectedCustomerDn = event.data.customerDn;
    this.labelContent = 'Distribution Centers for ' + event.data.customer;
    this.getCentersList();
    this.showcenterOrders = false;
    this.showcenterList = true;
  }

  onPositionChange(event) {
    this.selectedPosition = event.key;
    this.getCentersList();
  }

  centerlistEditDialog(event) {
    this.selectedDistributioncenterId = event.data.id;


    const dialogRef = this.dialog.open(ConfirmationDialog, {
      maxWidth: "400px",
      data: {
        message: "Assigning Selected Distribution Center" +
          " to Distributor " + this.selectedCustomerDn,
      }

    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true) {
        var data = {
          "orderId": this.selectedOrderId,
          "distributioncenterId": this.selectedDistributioncenterId,
        };
        console.log(data);
        this.http.post<number>(centersserviceUrl, data, httpOptions).subscribe(
          res => {
            console.log('assignedcenter ' + res);
            this.acdcNotificationsService.toast({
              title: 'Distribution Center Assigned',
              timeout: 3000,
              notificationLevel: 'Success'
            });
            this.back();
          });
      }

    });

  }
  centerlistDeleteDialog(event) {
    this.selectedDistributioncenterId = event.data.id;
    this.getCentersAvailable();
    this.showcenterOrders = false;
    this.showcenterList = false;
    this.showcenterInventory = true;

  }

  backfromAvailable(){
    this.showcenterOrders = false;
    this.showcenterList = true;
    this.showcenterInventory = false;

  }

  csvDownload() {
    let today = new Date().toISOString().slice(0, 10)
    let csvfile = 'MasterDistributionOrders_' + today + '.csv';
    CsvExport.exportToCsv(csvfile, this.aedistributioncenterorders);
  }
}

