import { Component, AfterViewInit } from '@angular/core';
import { AewalletService } from './aewallet_service.service';
import { Aewalletlist } from './aewallet_class_list';
import { AewalletcustomerService } from './aewallet_service_customer.service';
import { Aewalletcustomer } from './aewallet_class_customer';
import { Aewalletdownline } from './aewallet_class_downline';
import { Router } from '@angular/router';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AcdcNotificationsService } from 'acdc-notifications';
import { CsvExport } from '../aeshared/aeshared_class_csvexport';
import { AeWalletCreditlistTableSettings } from './aewallet_settings_creditlisttable';
import { AeWalletDownlineTableSettings } from './aewallet_settings_downlinetable';
import { MatDialog, } from '@angular/material';


@Component({
  selector: 'ngx-aewallet-creditlist',
  templateUrl: './aewallet_creditlist.component.html',
  styleUrls: ['./aewallet_view_and_crud.component.scss']
})
export class Aewallet_Creditlist_Component  {

  dataWallet: Aewalletlist[] = [];
  dataCustomer: Aewalletcustomer[] = [];
  isLoadingResults = true;
  aewallets: Aewalletlist[];
  aecustomers: Aewalletdownline[];
  showwallet: boolean = false;
  showcustomer: boolean = true;

  selectedCustomerDn = null;
  currentCustomerDn = null;
  isadmin: string;
  iscservice: string;

  walletcreditlisttablesettings = AeWalletCreditlistTableSettings;
  walletcustomertablesettings = AeWalletDownlineTableSettings;



  constructor(
    private walletapi: AewalletService, private customerapi: AewalletcustomerService,
    private router: Router, private localstorage: LocalStorageService,
    private acdcNotificationsService: AcdcNotificationsService,) {
    this.currentCustomerDn = localstorage.getItem('customerdn');
    this.isadmin = this.localstorage.getItem("isadmin");
    this.iscservice = this.localstorage.getItem("iscservice");
    if (this.isadmin === "True" || this.iscservice === "True") {this.currentCustomerDn = 'D999' }
    else {this.currentCustomerDn = localstorage.getItem('customerdn');}
    this.getCustomerData();
  }

  getCustomerData() {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Downline Data',
      timeout: 60000,
      notificationLevel: 'Info'
    });
    this.showCustomerTable(true);
    this.customerapi.getAewalletDownline(this.currentCustomerDn,'00010').subscribe((data: Aewalletdownline[]) => {
      this.aecustomers = data;
      this.acdcNotificationsService.deleteToast(acdcid);
    });

  }

  customerEditDialog(event) {
    this.aewallets = undefined;
    this.showCustomerTable(false);
    this.selectedCustomerDn = event.data.customerDn;
    this.localstorage.setItem('walletcustomerdn', this.selectedCustomerDn);
    this.localstorage.setItem('returnurl', this.router.url)
    //   alert(this.localstorage.getItem('returnurl'));
    this.getWalletData();
  }

  getWalletData() {
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Credit Wallet data',
      timeout: 60000,
      notificationLevel: 'Info'
    });
    this.walletapi.getAewalletsCredit(this.selectedCustomerDn, '00010').subscribe((data: Aewalletlist[]) => {
      this.aewallets = data;
      this.acdcNotificationsService.deleteToast(acdcid);
      if (this.aewallets.length == 0) {
        this.acdcNotificationsService.toast({
          title: 'Warning...No Credit Wallet data',
          timeout: 6000,
          notificationLevel: 'Warn'
        });
      }
    });
  }


  showCustomerTable(bshow: boolean) {
    this.showcustomer = bshow;
    this.showwallet = !bshow;

  }

  back() {
    this.showCustomerTable(true);

  }

  customerback() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  csvWallet() {
    let csvfile = 'CreditData_Dn_' + this.selectedCustomerDn + '.csv';
    CsvExport.exportToCsv(csvfile, this.aewallets);
  }


}
