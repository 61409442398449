import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AesharedModule } from '../aeshared/aeshared.module';

import { AestoreproductsComponent } from './aestoreproducts.component';
import { AestoreordersComponent } from './aestoreorders.component';
import { AestoreComponent } from './aestore.component';
import { ImageRenderComponent } from './aestore_render_imagerender.component';
import { PriceRenderComponent } from './aestore_render_pricerender.component';

@NgModule({
  declarations: [
    AestoreComponent,
    AestoreproductsComponent,
    AestoreordersComponent,
    ImageRenderComponent,
    PriceRenderComponent,    
  ],
  entryComponents: [
    ImageRenderComponent,
    PriceRenderComponent,        
  ],

  imports: [
    CommonModule,
    AesharedModule,
  ]
})
export class AestoreModule { }
