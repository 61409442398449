
import { Component, Input, OnInit } from '@angular/core';


@Component({
  template: `
    <img *ngIf="value=='noclub'" width="25" height="25" src="../../../assets/images/unchecked.jpg"/>
    <img *ngIf="value=='club24'" width="40" height="40" src="../../../assets/images/club24.jpg"/>
    <img *ngIf="value=='club24h'" width="40" height="40" src="../../../assets/images/club24h.jpg"/>
    <img *ngIf="value=='club50'" width="40" height="40" src="../../../assets/images/club50.jpg"/>
    <img *ngIf="value=='club50h'" width="40" height="40"src="../../../assets/images/club50h.jpg"/>
    <p *ngIf="value==0"></p>
  `,
})

export class HeadImageRenderComponent implements OnInit {
  public renderValue;
  @Input() value;
  constructor() { }
  ngOnInit() {
    this.renderValue = 'data:image/jpg;base64,' + this.value;
  }
}