import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot} from '@angular/router';
import { AeuplineshowService } from './aeuplineshow.service';


@Injectable({
    providedIn: 'root'
})


export class AeuplineshowResolverService implements Resolve<any> {

    constructor(
        private uplineApi: AeuplineshowService,
    ) {

    }

    resolve(route: ActivatedRouteSnapshot): any {
        var parameters = route.params['aeuplineparam'];
        var sponsordn = parameters.split(',',2)[0];
        var ciclosid = parameters.split(',',2)[1];
        console.log('resolve parameters ' + sponsordn+ ',' + ciclosid);
        return this.uplineApi.getOrganization(sponsordn,ciclosid)
    }



}