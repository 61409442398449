import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse,  } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map, retry } from 'rxjs/operators';
import { Aeupline } from './aeuplineshow_classupline';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const organizationserviceUrl = `${environment.apiServiceUrl}/aeuplineshow`;

@Injectable({
  providedIn: 'root'
})

export class AeuplineshowService {

  constructor(private http: HttpClient) { }

 
  getOrganization (customerdn, ciclosid): Observable<Aeupline[]> {
    const url = `${organizationserviceUrl}/${customerdn},${ciclosid}`;
    return this.http.get<Aeupline[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aeorganization')),
        catchError(this.handleError)
      );
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
/* agregar notificacion*/    
    return throwError(errorMessage);
  }
}
