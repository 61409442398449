import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import { AestateprovincekeyvalueService } from './aestateprovincekeyvalue.service';


@Injectable({
    providedIn: 'root'
})


export class AestateprovincecountryResolverService implements Resolve<any> {

    constructor(
        private stateprovincekeyvalueApi: AestateprovincekeyvalueService,
    ) {

    }

    resolve(): any {
        return this.stateprovincekeyvalueApi.getAestatescountry()
    }



}