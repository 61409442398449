import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AeformcantidadreservarComponent } from './aeformcantidadreservar.component';
import {
  MatCommonModule,
  MatFormFieldModule,
} from '@angular/material';



@NgModule({
	declarations: [AeformcantidadreservarComponent,],
	entryComponents: [AeformcantidadreservarComponent,],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatRadioModule,
		MatSelectModule,
		MatCheckboxModule,
		MatCommonModule,
		MatFormFieldModule,
	],

	})
export class AeformcantidadreservarModule {}
