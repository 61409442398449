import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'ngx-maintenance',
  styleUrls: ['./maintenance.component.scss'],
  templateUrl: './maintenance.component.html',
})
export class MaintenanceComponent {
  constructor(
  private router: Router) {}

  goToHome() {
    let url = 'https://eternalsb.com';
    window.location.assign(url);
  }
}
