
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

const bonusserviceUrl = `${environment.apiServiceUrl}/aebonusregisterselected200`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

@Component({
  template: `
  <nb-checkbox status="primary" [ngModel]="chk"  (change)="toggleShipping($event)"></nb-checkbox>
`,
})

export class Selected200BonusRenderComponent implements ViewCell,  OnInit {

  @Input() value: number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();
  
  public chk: boolean;
  chkOri: boolean;
  constructor(private http: HttpClient,)  {  }

  ngOnInit() {
    if (this.value == 0) {this.chk = false; this.chkOri = false;} else {this.chk = true; this.chkOri = true}
  }

  toggleShipping(event){
  this.chk = !this.chkOri
   var data = {
    "id": this.rowData.id,
    "checked200": this.chk,
  };
  this.http.post<number>(bonusserviceUrl, data, httpOptions).subscribe(
    res => {
      this.save.emit(res);
      });
  }
}