import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';

import 'rxjs/add/operator/toPromise';

import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { Aecustomeraddress } from './aecustomeraddress';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const aecustomeraddressserviceUrl = `${environment.apiServiceUrl}/aecustomer`;
const aepickupaddressserviceUrl = `${environment.apiServiceUrl}/aepickupaddress`;

@Injectable({
  providedIn: 'root'
})
export class AecustomeraddressService {

  //aecustomeraddressesJson: Aecustomeraddress[];
  aecustomeraddresses = [];
  selectedcustomeraddresses = [];  
  //aepickupaddressesJson: Aecustomeraddress[];
  aepickupaddresses = [];
  //aemastersponsoraddressesJson: Aecustomeraddress[];
  aemastersponsoraddresses = [];
  customerDn = '';
  mastersponsorDn = '';



  constructor(private http: HttpClient, private localstorage: LocalStorageService) {
    this.customerDn = localstorage.getItem("customerdn"); 
    this.mastersponsorDn = localstorage.getItem("mastersponsordn"); 
   }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  getAecustomeraddress(): Observable<Aecustomeraddress[]> {
    const url = `${aecustomeraddressserviceUrl}/${this.customerDn}`;
    return this.http.get<Aecustomeraddress[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched customeraddress')),
        catchError(this.handleError('getcustomeaddress', []))
      );
  }

  getAeordercustomeraddress(ordercustomerdn: string): Observable<Aecustomeraddress[]> {
    const url = `${aecustomeraddressserviceUrl}/${ordercustomerdn}`;
    return this.http.get<Aecustomeraddress[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched customeraddress')),
        catchError(this.handleError('getcustomeaddress', []))
      );
  }



  getAemastersponsoraddress(): Observable<Aecustomeraddress[]> {
    const url = `${aecustomeraddressserviceUrl}/${this.mastersponsorDn}`;
    return this.http.get<Aecustomeraddress[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched mastersponsoraddress')),
        catchError(this.handleError('getaemastersponsoraddress', []))
      );
  }

  getAemastersponsorAddressData(): any {
    return this.getAemastersponsoraddress().subscribe((data: Aecustomeraddress[]) => {
      data.forEach(element => { this.aemastersponsoraddresses.push(element); });
    })
  }

  getAepickupaddress(): Observable<Aecustomeraddress[]> {
    const url = `${aepickupaddressserviceUrl}`;
    return this.http.get<Aecustomeraddress[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched pickupaddress')),
        catchError(this.handleError('getpickupaddress', []))
      );
  }

  getAepickupAddressData(): any {
    return this.getAepickupaddress().subscribe((data: Aecustomeraddress[]) => {
      data.forEach(element => { this.aepickupaddresses.push(element); });
    })
  }

  getSelectedcustomeraddress(selectedcustomerdn: string): Observable<Aecustomeraddress[]> {
    const url = `${aecustomeraddressserviceUrl}/${selectedcustomerdn}`;
    return this.http.get<Aecustomeraddress[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched customeraddress')),
        catchError(this.handleError('getcustomeaddress', []))
      );
  }

}
