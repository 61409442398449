import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import {
  MatCommonModule,
  MatFormFieldModule,
} from '@angular/material';

import { AeformcantidadinicioComponent } from './aeformcantidadinicio.component';

@NgModule({
declarations: [AeformcantidadinicioComponent],
entryComponents: [AeformcantidadinicioComponent],
imports: [
	CommonModule,
	FormsModule,
	ReactiveFormsModule,
	MatInputModule,
	MatButtonModule,
	MatIconModule,
	MatRadioModule,
	MatSelectModule,
	MatCommonModule,
  MatFormFieldModule,
],
})
export class AeformcantidadinicioModule {}
