import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';

import 'rxjs/add/operator/toPromise';

import { Aeciclovolume } from './aeciclovolume';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const aecicloserviceUrl = `${environment.apiServiceUrl}/aeciclovolume`;

@Injectable({
  providedIn: 'root'
})
export class AeciclovolumeService {

  aecyclesvolume = [];
  customerDn = '';

  constructor(private http: HttpClient, private localstorage: LocalStorageService) {
    this.customerDn = localstorage.getItem("customerdn"); 
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  getAeciclosvolume(): Observable<Aeciclovolume[]> {
    const url = `${aecicloserviceUrl}/${this.customerDn}`;
    return this.http.get<Aeciclovolume[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched AeciclosVolume')),
        catchError(this.handleError('getaeciclovolume', []))
      );
  }

  getAeciclosvolumeData(): any {
    return this.getAeciclosvolume().subscribe((data: Aeciclovolume[]) => {
      data.forEach(element => { this.aecyclesvolume.push(element); });
    })
  }


}
