import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'ngx-not-found',
  styleUrls: ['./notfound.component.scss'],
  templateUrl: './notfound.component.html',
})
export class NotFoundComponent {
  constructor(
  private router: Router) {}

  goToHome() {
	const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }
}
