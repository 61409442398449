import { Component, AfterViewInit, OnInit } from '@angular/core';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { AestoreService } from './aestore.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Aestoreorderslist } from './aestore_class_orderslist';
import { Aestorelist } from './aestore_class_storelist';
import { Aestoreorderproductslist } from './aestore_class_orderproductslist';
import { Router, ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AcdcNotificationsService } from 'acdc-notifications';


@Component({
  selector: 'ngx-aestoreorders',
  templateUrl: './aestoreorders.component.html',
  styleUrls: ['./aestoreorders.component.scss']
})
export class AestoreordersComponent implements AfterViewInit {
  customerDn = '';
  isLoadingResults = true;
  aestoreorders: Aestoreorderslist[] = undefined;
  aestoreorderproducts: Aestoreorderproductslist[] = undefined;
  recompute: boolean = false;
  showorders: boolean = false;
  showorderdetail: boolean = false;
  selectedOrderNumber = '';

  labelContent = '';
  customerName = '';
  orderTotal = '';
  authorizationCode = '';
  storeName = '';
  orderNumber = '';
  orderDate = '';

  storeorderssettings = {
    actions: {
      columnTitle: '',
      delete: false,
      add: false,
      edit: true,
    },
    pager: {
      display: true,
      perPage: 200,
    },

    hideHeader: true,

    noDataMessage: '',
    mode: 'external',
    edit: {
      editButtonContent: '<i class="ion-ios-eye-outline"></i>',
    },
    columns: {
      orderNumber: {
        title: 'Order',
        width: '10%',
        editable: false,
      },
      orderDate: {
        title: 'Date',
        width: '10%',
        editable: false,
      },
      customerName: {
        title: 'Customer',
        width: '50%',
        editable: false,
      },
      orderTotal: {
        title: 'Total',
        width: '10%',
        editable: false,
      },
    },
  };
  storeproductssettings = {
    actions: {
      columnTitle: '',
      delete: false,
      add: false,
      edit: false,
    },
    pager: {
      display: true,
      perPage: 200,
    },

    hideHeader: true,

    noDataMessage: '',
    columns: {
      productName: {
        title: 'Product',
        width: '40%',
        editable: false,
      },
      productQuantity: {
        title: 'Quantity',
        width: '10%',
        editable: false,
      },
      productPrice: {
        title: 'Price',
        width: '20%',
        editable: false,
      },
      productTotal: {
        title: 'Total',
        width: '20%',
        editable: false,
      },
    },
  };


  constructor(private api: AestoreService, private localstorage: LocalStorageService,
    private router: Router, private http: HttpClient,
    private route: ActivatedRoute,
    private acdcNotificationsService: AcdcNotificationsService,
  ) {
    this.customerDn = localstorage.getItem("customerdn");
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.showstoreTable(1);
  }

  showstoreTable(bshow: number) {
    if (bshow == 1) {
      this.labelContent = 'Showing Orders'
      this.showorders = true;
      this.showorderdetail = false;
      const acdcid: string = this.acdcNotificationsService.toast({
        title: 'Please wait... Loading Order data',
        timeout: 60000,
        notificationLevel: 'Info'
      });
      this.api.getAestore(this.customerDn)
        .subscribe((data: Aestorelist) => {
          this.storeName = data.storeName;
        });
      this.api.getAestoreorders(this.customerDn)
        .subscribe((data: Aestoreorderslist[]) => {
          this.aestoreorders = data;
          this.acdcNotificationsService.deleteToast(acdcid);
          if (this.aestoreorders.length == 0) {
            this.acdcNotificationsService.toast({
              title: 'Warning... No Order data',
              timeout: 3000,
              notificationLevel: 'Warn'

            });
          }
        });
    }

    if (bshow == 2) {

      this.labelContent = 'Order Detail'
      this.showorders = false;
      this.showorderdetail = true;
      this.api.getAestoreorderdetail(this.customerDn, this.selectedOrderNumber)
        .subscribe((data: Aestoreorderslist) => {
          this.orderNumber = data.orderNumber;
          this.orderTotal = data.orderTotal;
          this.customerName = data.customerName;
          this.orderDate = data.orderDate;
          this.authorizationCode = data.authorizationCode
        });
      this.api.getAestoreorderproducts(this.customerDn, this.selectedOrderNumber)
        .subscribe((data: Aestoreorderproductslist[]) => {
          this.aestoreorderproducts = data;
          if (this.aestoreorderproducts.length == 0) {
            this.acdcNotificationsService.toast({
              title: 'Warning... No Product data',
              timeout: 3000,
              notificationLevel: 'Warn'

            });
          }
        });

      //         alert("Order Detail to be shown here")        
    }

  }

  storedetailback() {
    this.showstoreTable(1);
  }

  aestoreordersEditDialog(event) {
    this.selectedOrderNumber = event.data.orderNumber;
    this.showstoreTable(2);
  }

}

