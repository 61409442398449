import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Aepromotions } from './aepromos_class_record';
import { environment } from '../../../environments/environment';
import { Aepromocomponentlist } from './aepromo_component_class_list';
import { Aepromocomponentmodel } from './aepromo_component_class_model';
import { Aepromorequirementlist } from './aepromo_requirement_class_list';
import { Aepromorequirementmodel } from './aepromo_requirement_class_model';
import { Aepromodefinitionmodel } from './aepromo_definition_class_model';
import { Aepromodefinitionkeyvalue } from './aepromo_component_class_definitionkeyvalue';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

const serviceUrl = `${environment.apiServiceUrl}/aepromotion`;
const promocomponentserviceUrl = `${environment.apiServiceUrl}/aepromocomponent`;
const promorequirementserviceUrl = `${environment.apiServiceUrl}/aepromorequirement`;
const promodefinitionkeyvalueserviceUrl = `${environment.apiServiceUrl}/aepromodefinitionkeyvalues`;
const promodefinitionserviceUrl = `${environment.apiServiceUrl}/aepromodefinition`;

@Injectable({
  providedIn: 'root'
})
export class AepromosService {

  constructor(private http: HttpClient) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getAepromodefinitionkeyvalues (definitiontype: string): Observable<Aepromodefinitionkeyvalue[]> {
    const url = `${promodefinitionkeyvalueserviceUrl}/${definitiontype}`;
    return this.http.get<Aepromodefinitionkeyvalue[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aepromodefinitionkeyvalue')),
        catchError(this.handleError('getAepromodefinitionkeyvalue', []))
      );
  }

  getAepromotions (): Observable<Aepromotions[]> {
    const url = `${serviceUrl}`;
    return this.http.get<Aepromotions[]>(url, httpOptions)
      .pipe(
        tap(heroes => console.log('fetched Aepromotionss')),
        catchError(this.handleError('getAepromotionss', []))
      );
  }

  getAepromotion(id: number): Observable<Aepromotions> {
    const url = `${serviceUrl}/${id}`;
    return this.http.get<Aepromotions>(url, httpOptions).pipe(
      tap(_ => console.log(`fetched Aepromotions id=${id}`)),
      catchError(this.handleError<Aepromotions>(`getAepromotions id=${id}`))
    );
  }

  getAepromotiondefinition(id: number): Observable<Aepromodefinitionmodel> {
    const url = `${promodefinitionserviceUrl}/${id}`;
    console.log('url get definition ' + url);
    return this.http.get<Aepromodefinitionmodel>(url, httpOptions).pipe(
      tap(_ => console.log(`fetched getAepromotiondefinition id=${id}`)),
      catchError(this.handleError<Aepromodefinitionmodel>(`getAepromotiondefinition id=${id}`))
    );
  }
  addAepromodefinition (aepromodefinition: Aepromodefinitionmodel): Observable<Aepromodefinitionmodel> {
    const url = `${promodefinitionserviceUrl}`;
    return this.http.post<Aepromodefinitionmodel>(url, aepromodefinition, httpOptions).pipe(
      tap((aewalletRes: Aepromodefinitionmodel) => console.log(`added Aepromotiondefinitionrecord`)),
      catchError(this.handleError<Aepromodefinitionmodel>('addAepromotionrecord'))
    );

  }
  updateAepromodefinition (id: number, aepromodefinition: any): Observable<any> {
    const url = `${promodefinitionserviceUrl}/${id}`;
    return this.http.put(url, aepromodefinition, httpOptions).pipe(
      tap(_ => console.log(`updated Aepromodefinition id=${id}`)),
      catchError(this.handleError<any>('updateAepromodefinition'))
    );
  }

  getAepromotioncomponents(promotionid: number): Observable<Aepromocomponentlist[]> {
    const url = `${promocomponentserviceUrl}/${promotionid},0`;
    return this.http.get<Aepromocomponentlist[]>(url, httpOptions).pipe(
      tap(_ => console.log(`fetched Aepromotion components promotionid=${promotionid}`)),
      catchError(this.handleError<Aepromocomponentlist[]>(`getAepromotions promotionid=${promotionid}`))
    );
  }
  getAepromotioncomponent(id: number): Observable<Aepromocomponentmodel> {
    const url = `${promocomponentserviceUrl}/${id}`;
    console.log('url get component ' + url);
    return this.http.get<Aepromocomponentmodel>(url, httpOptions).pipe(
      tap(_ => console.log(`fetched getAepromotioncomponent id=${id}`)),
      catchError(this.handleError<Aepromocomponentmodel>(`getAepromotioncomponent id=${id}`))
    );
  }
  addAepromocomponent (aepromocomponent: Aepromocomponentmodel): Observable<Aepromocomponentmodel> {
    const url = `${promocomponentserviceUrl}`;
    return this.http.post<Aepromocomponentmodel>(url, aepromocomponent, httpOptions).pipe(
      tap((aewalletRes: Aepromocomponentmodel) => console.log(`added Aepromotioncomponentrecord`)),
      catchError(this.handleError<Aepromocomponentmodel>('addAepromotionrecord'))
    );

  }
  updateAepromocomponent (id: number, aepromocomponent: any): Observable<any> {
    const url = `${promocomponentserviceUrl}/${id}`;
    return this.http.put(url, aepromocomponent, httpOptions).pipe(
      tap(_ => console.log(`updated Aepromocomponent id=${id}`)),
      catchError(this.handleError<any>('updateAepromocomponent'))
    );
  }


  getAepromotionrequirements(promotionid: number): Observable<Aepromorequirementlist[]> {
    const url = `${promorequirementserviceUrl}/${promotionid},0`;
    return this.http.get<Aepromorequirementlist[]>(url, httpOptions).pipe(
      tap(_ => console.log(`fetched AePromorequirements promotionid=${promotionid}`)),
      catchError(this.handleError<Aepromorequirementlist[]>(`getAepromorequirements promotionid=${promotionid}`))
    );
  }
  getAepromotionrequirement(id: number): Observable<Aepromorequirementmodel> {
    const url = `${promorequirementserviceUrl}/${id}`;
    console.log('url get requirement ' + url);
    return this.http.get<Aepromorequirementmodel>(url, httpOptions).pipe(
      tap(_ => console.log(`fetched Aepromotionrequirement id=${id}`)),
      catchError(this.handleError<Aepromorequirementmodel>(`getAepromotionrequirement id=${id}`))
    );
  }
  addAepromorequirement (aepromorequirement: Aepromorequirementmodel): Observable<Aepromorequirementmodel> {
    const url = `${promorequirementserviceUrl}`;
    return this.http.post<Aepromorequirementmodel>(url, aepromorequirement, httpOptions).pipe(
      tap((aewalletRes: Aepromorequirementmodel) => console.log(`added Aepromotionrequirement`)),
      catchError(this.handleError<Aepromorequirementmodel>('addAepromotionrequirement'))
    );

  }
  updateAepromorequirement (id: number, aepromorequirement: any): Observable<any> {
    const url = `${promorequirementserviceUrl}/${id}`;
    return this.http.put(url, aepromorequirement, httpOptions).pipe(
      tap(_ => console.log(`updated Aepromorequirement id=${id}`)),
      catchError(this.handleError<any>('updateAepromorequirement'))
    );
  }
}
