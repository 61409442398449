import { Component, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { LocalStorageService } from '../aeshared/aeshared_service_localstorage.service';
import { AexordersService } from './aexorders.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Aexorder } from './aexorder_class_aexorder';
import { Aexorderproductslist } from './aexorder_class_productslist';
import { Aexorderproductsmodel } from './aexorder_class_productsmodel';
import { Aexorderproductsgiftlist } from './aexorder_class_productsgiftlist';
import { Router, ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { TransfersRenderComponent } from './aexorders_render_transfersrender.component';
import { ImageRenderComponent } from './aexorders_render_imagerender.component';
import { PriceRenderComponent } from './aexorders_render_pricerender.component';
import { AdditionalProductsRenderComponent } from './aexorders_render_additionalproducts.component';
import { Aexordertotals } from './aexorder_class_ordertotals';
import { Aeproductquantities } from './aexorder_class_productquantities';
import { Aeipaddress } from './aexorder_class_ipaddress';
import { AcdcNotificationsService } from 'acdc-notifications';
import { Aestateprovincecountry } from '../aeshared/aestateprovincecountry';
import { AecustomeraddressService } from '../aeshared/aecustomeraddress.service';
import { Aecustomeraddress } from '../aeshared/aecustomeraddress';
import { AewarehouseService } from '../aeshared/aewarehouse.service'
import { Aewarehouse } from '../aeshared/aewarehouse';
import { AeXorderCustomerTableSettings } from './aexorder_settings_customertable';
import { Aetaxjarresponse } from './aexorder_class_taxjarresponse';
import { Aeinvoiceresponse } from './aexorder_class_invoiceresponse';
import { Aexorderdcproducts } from './aexorder_class_dcproducts';
import { Aedistributioncentersassigned } from './aexorder_class_distributioncentersassigned';

import { MatDialog, } from '@angular/material';
import { ConfirmationDialog } from '../aeshared/aedialog.component';
import { ContinuationDialog } from '../aeshared/aecontinuationdialog.component';
import { ImageDialog } from '../aeshared/aeimagedialog.component';
import { Aexordermodeldc } from './aexorder_class_modeldc';
import { Additionalorderproductslist } from './aexorder_class_additionalorderproductslist';
import { Transferproductslist } from './aexorder_class_transferproductslist';
import { Aeimageurl } from './aexorder_class_imageurl';
import { v1 as uuid } from 'uuid';

const xorderproductserviceUrl = `${environment.apiServiceUrl}/aexorderproducts`;
const xordermodeldcserviceUrl = `${environment.apiServiceUrl}/aexorderdc`;
const customerproductstransferUrl = `${environment.apiServiceUrl}/aecustomerproductstransfer`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'dbconnection': 'us' })
};

@Component({
  selector: 'ngx-aexordersinventoryuser',
  templateUrl: './aexorders_inventoryuser.component.html',
  styleUrls: ['./aexorders_inventoryuser.component.scss']
})
export class AexordersInventoryUserComponent implements OnInit, AfterViewInit {
  deskSessionId = '0';
  customerAddressForm: FormGroup;
  customerDn = '';
  dcentercustomerDn = '';
  dcenteraddressId = 0;
  currentGuid: string = undefined;
  impersonatorDn = '';
  currentOperator = '';
  isLoadingResults = true;
  aexorders: Aexorder[];
  aeadditionalproducts: Additionalorderproductslist[];
  aetransferproducts: Transferproductslist[] = undefined;
  aeselectedtransferproducts: Transferproductslist[] = undefined;
  aexorderproducts: Aexorderproductslist[] = undefined;

  availablemessage: boolean = false;
  aexorderproductsgifts: Aexorderproductsgiftlist[] = undefined;
  aexorderdcproducts: Aexorderdcproducts[];


  backfromCart: boolean = false;
  recompute: boolean = false;
  showxorders: boolean = false;
  showaddproducts: boolean = false;
  showxcart: boolean = false;
  showinvoice: boolean = false;
  showcustomers: boolean = false;
  showtransfers: boolean = false;
  showadditionalproducts: boolean = false;
  showfaxnumber: boolean = false;
  showform: boolean = true;

  fromcenters: boolean = false;

  productValidationMessage = '';
  orderValidationMessage = '';

  phoneNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  zipCodePattern = "^[0-9]{5}(?:-[0-9]{4})?$";


  get ngselectedCycle() { return this.customerAddressForm.get('ngselectedCycle'); }
  get ngselectedAddress() { return this.customerAddressForm.get('ngselectedAddress'); }
  get saveAddress() { return this.customerAddressForm.get('saveAddress'); }
  // get pickupinStore() { return this.customerAddressForm.get('pickupinStore'); }
  get firstName() { return this.customerAddressForm.get('firstName'); }
  get lastName() { return this.customerAddressForm.get('lastName'); }
  get email() { return this.customerAddressForm.get('email'); }
  get city() { return this.customerAddressForm.get('city'); }
  get zipCode() { return this.customerAddressForm.get('zipCode'); }
  get ngselectedState() { return this.customerAddressForm.get('ngselectedState'); }
  get address1() { return this.customerAddressForm.get('address1'); }
  get address2() { return this.customerAddressForm.get('address2'); }
  get phoneNumber() { return this.customerAddressForm.get('phoneNumber'); }
  get faxNumber() { return this.customerAddressForm.get('faxNumber'); }

  labelContent = '';
  labelContent1 = '';
  labelContent2 = '';
  invoiceNumberLabel = '0';
  totalCredit = 0.00;
  availableCredit = 0.00;
  currentTotal = 0.00;
  previousTotal = 0.00;
  availableCreditLabel = '0.00'
  currentTotalLabel = '0.00'
  previousTotalLabel = '0.00'
  continueLabel = 'Checkout'
  tolerance = 0.00;
  customerIp = '000.000.000.000';

  aexorderModel: Aexordermodeldc;
  taxJarRate: number = 0;
  invalidPromotions = "";
  foreignShippingAmount = "0.00";
  foreignWalletRecharge = "0.00";

  invoiceId: string = "";
  invoiceNumber: string = '';
  customerAddress = '';

  selectedXOrderId = 0;
  selectedXOrderNumber = '';
  selectedXProductId = 0;
  selectedXProductQuantity = 0;
  selectedFreeProductId = 0;
  transferCustomerDn = null;
  chkAvailable: boolean = true;
  confirmarDireccion: boolean = false;
  esLenceria: boolean = false;
  soloMexico: boolean = false;

  selectedCycle = undefined;
  selectedState = 47;
  selectedStateCode = "CA";
  selectedCountry = 1;
  countryLabel = "US United States"
  selectedAddress = 0;
  selectedWarehouse = 1;
  selectedPreviousWarehouse = 0;
  selectedDc = 0;
  selectedPickupInDC: boolean = false;
  selectedGroupId = 0;
  selectedGroupName = '';

  aewarehouses = [];
  aewarehousesJson: Aewarehouse[];

  aeWEdc: Aedistributioncentersassigned[];

  enablewarehouse: boolean = true;

  totalGifts = "0";
  totalGiftsN = 0;

  aecycles = [];
  aestates = [];
  aestatescountry = [];

  pickupChecked = false;
  aepickupaddresses = [];
  aecustomeraddresses = [];
  aeaddresses = [];



  dcsettings = {
    actions: {
      columnTitle: '',
      delete: false,
      add: false,
      edit: true,
    },
    pager: {
      display: true,
      perPage: 20,
    },
    mode: 'external',
    hideHeader: true,
    noDataMessage: '',
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
    },
    columns: {
      dcenterId: {
        title: 'Serial',
        width: '5%'
      },
      dcenterName: {
        title: 'Distribution Center',
        width: '40%'
      },
      dcentercustomerDn: {
        title: 'Distributor',
        width: '30%'
      },
    }

  }

  xordersettings = {
    actions: {
      columnTitle: '',
      delete: false,
      add: false,
      edit: true,
    },
    pager: {
      display: true,
      perPage: 20,
    },
    mode: 'external',
    hideHeader: true,
    noDataMessage: '',
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
    },
    columns: {
      customerDn: {
        title: 'DN',
        width: '5%'
      },
      orderNumber: {
        title: 'Order',
        width: '5%'
      },
      productName: {
        title: 'Product',
        width: '30%'
      },
      quantity: {
        title: 'Quantity',
        width: '10%'
      },
      totalCredit: {
        title: 'Credit',
        width: '10%'
      },
      totalCurrent: {
        title: 'Current',
        width: '10%'
      },
      totalPrevious: {
        title: 'Previous',
        width: '10%'
      },
      availableCredit: {
        title: 'Available',
        width: '10%'
      },
    },
  };

  xorderproductssettings = {
    actions: {
      columnTitle: '',
      delete: false,
      add: false,
      edit: true,
    },
    pager: {
      display: true,
      perPage: 200,
    },

    mode: 'inline',
    hideHeader: true,
    noDataMessage: '',

    edit: {
      editButtonContent: '<i class="ion-ios-plus-outline"></i>',
      saveButtonContent: '<i class="nb-checkmark-circle"></i>',
      cancelButtonContent: '<i class="nb-close-circled"></i>',
      confirmSave: true,
    },
    columns: {
      sku: {
        title: 'Sku',
        width: '10%',
        editable: false,
      },
      categoryName: {
        title: 'Category',
        width: '30%',
        editable: false,
      },
      productImg: {
        title: 'Image',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: ImageRenderComponent,
      },
      productName: {
        title: 'Product',
        width: '15%',
        editable: false,
      },
      availableMessage: {
        title: 'Available',
        width: '5%',
        editable: false,
      },
      productQuantity: {
        title: 'Quantity',
        width: '10%'
      },
      previousQuantity: {
        title: 'Previous',
        width: '10%',
        editable: false,
      },
      productPrice: {
        title: 'Price',
        width: '5%',
        type: 'custom',
        editable: false,
        renderComponent: PriceRenderComponent,
      },
      productTotal: {
        title: 'Total',
        width: '5%',
        editable: false,
      },
      id: {
        title: 'Serial',
        width: '5%',
        editable: false,
      },
    },
  };
  xinvoicesettings = {
    actions: {
      columnTitle: '',
      delete: false,
      add: false,
      edit: false,
    },
    pager: {
      display: true,
      perPage: 200,
    },


    hideHeader: true,

    noDataMessage: '',

    columns: {
      sku: {
        title: 'Sku',
        width: '10%',
        editable: false,
      },
      categoryName: {
        title: 'Category',
        width: '30%',
        editable: false,
      },
      productImg: {
        title: 'Image',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: ImageRenderComponent,
      },
      productName: {
        title: 'Product',
        width: '30%',
        editable: false,
      },
      productQuantity: {
        title: 'Quantity',
        width: '5%'
      },
      previousQuantity: {
        title: 'Previous',
        width: '5%'
      },
      productPrice: {
        title: 'Price',
        width: '5%',
        type: 'custom',
        renderComponent: PriceRenderComponent,
      },
      productTotal: {
        title: 'Total',
        width: '5%',
        editable: false,
      },
      id: {
        title: 'Serial',
        width: '5%',
        editable: false,
      },
    },
  };
  additionalproductssettings = {
    actions: {
      position: 'right',
      columnTitle: '',
      delete: false,
      add: false,
      edit: true,
    },
    pager: {
      display: false,
    },
    mode: 'external',
    edit: {
      editButtonContent: '<i class="ion-ios-eye-outline"></i>',
    },
    hideHeader: true,
    noDataMessage: '',
    columns: {
      checked: {
        title: 'Process',
        width: '10%',
        editable: false,
        type: 'custom',
        renderComponent: AdditionalProductsRenderComponent,
        valuePrepareFunction: (cell, row) => {
          return cell;
        },
        onComponentInitFunction: (instance) => {
        },
      },
      customerDn: {
        title: 'Distributor',
        width: '5%'
      },
      orderNumber: {
        title: 'Order',
        width: '5%'
      },
      productSku: {
        title: 'Sku',
        width: '5%'
      },
      productName: {
        title: 'Name',
        width: '40%'
      },
      quantity: {
        title: 'Quantity        Image',
        width: '15%'
      },
    },
  };

  transferssettings = {
    actions: {
      position: 'right',
      columnTitle: '',
      delete: false,
      add: false,
      edit: true,
    },
    pager: {
      display: false,
    },
    mode: 'external',
    edit: {
      editButtonContent: '<i class="ion-ios-eye-outline"></i>',
    },
    hideHeader: true,
    noDataMessage: '',
    columns: {
      checked: {
        title: 'Select',
        width: '5%',
        editable: false,
        type: 'custom',
        renderComponent: TransfersRenderComponent,
        valuePrepareFunction: (cell) => {
          return cell;
        },
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(schecked => {
            if (schecked > 1) { this.transfers(); }
          });
        },
      },
      customerDn: {
        title: 'Distributor',
        width: '10%'
      },
      orderNumber: {
        title: 'Order',
        width: '10%'
      },
      productSku: {
        title: 'Sku',
        width: '10%'
      },
      productName: {
        title: 'Name',
        width: '35%'
      },
      quantity: {
        title: 'Quantity        Image',
        width: '10%'
      },
    },
  };
  xorderproductgiftsettings = {
    actions: {
      columnTitle: '',
      delete: false,
      add: false,
      edit: true,
    },
    pager: {
      display: true,
      perPage: 200,
    },

    mode: 'inline',
    hideHeader: true,
    noDataMessage: '',

    edit: {
      editButtonContent: '<i class="ion-ios-plus-outline"></i>',
      saveButtonContent: '<i class="nb-checkmark-circle"></i>',
      cancelButtonContent: '<i class="nb-close-circled"></i>',
      confirmSave: true,
    },
    columns: {
      sku: {
        title: 'Sku',
        width: '10%',
        editable: false,
      },
      productName: {
        title: 'Product',
        width: '15%',
        editable: false,
      },
      productQuantity: {
        title: 'Quantity',
        width: '10%',
        editable: true,
      },
      id: {
        title: 'Serial',
        width: '5%',
        editable: false,
      },
    },
  };


  xordercustomertablesettings = AeXorderCustomerTableSettings;

  constructor(private api: AexordersService, private localstorage: LocalStorageService,
    private router: Router, private http: HttpClient,
    private route: ActivatedRoute,
    private acdcNotificationsService: AcdcNotificationsService,
    private dialog: MatDialog,
    private warehousesApi: AewarehouseService,
    private customeraddressApi: AecustomeraddressService,
    private fb: FormBuilder,
    private fbdc: FormBuilder) {
    this.customerAddressForm = this.fb.group({
      ngselectedCycle: this.fb.control(0),
      ngselectedAddress: this.fb.control(''),
      saveAddress: this.fb.control(false),
      // pickupinStore: this.fb.control(false),
      firstName: this.fb.control('', Validators.required),
      lastName: this.fb.control('', Validators.required),
      email: this.fb.control('', [Validators.required, Validators.pattern(this.emailPattern)]),
      city: this.fb.control('', Validators.required),
      zipCode: this.fb.control('', [Validators.required, Validators.pattern(this.zipCodePattern)]),
      ngselectedState: this.fb.control(47, Validators.required),
      address1: this.fb.control('', Validators.required),
      address2: this.fb.control(' ', Validators.required),
      phoneNumber: this.fb.control('', [Validators.required, Validators.pattern(this.phoneNumberPattern)]),
      faxNumber: this.fb.control(' ', [Validators.required]),
    });
    this.customerDn = localstorage.getItem("customerdn");
    this.impersonatorDn = localstorage.getItem("impersonatordn");
    this.currentOperator = localstorage.getItem("current");
    this.deskSessionId = localstorage.getItem("desksessionid");
    this.getwarehousesData().subscribe((data: Aewarehouse[]) => {
      data.forEach(element => {
        console.log(element);
        this.aewarehouses.push(element);
      });
    });

    this.selectedWarehouse = 1
    this.selectedCountry = 1;
    this.getStates();
    this.selectedState = 47;
    this.selectedStateCode = 'CA';
  }

  ngOnInit(): void {
    this.aestatescountry = this.route.snapshot.data['aestatescountryRes'];
    this.aecycles = this.route.snapshot.data['aecicloRes'];
    this.aeWEdc = this.route.snapshot.data['aeinventorycenterRes'];
    this.selectedCycle = this.aecycles[0].key;
    var selecteddc = this.aeWEdc.find(x => x.dcentercustomerDn === this.customerDn);
    this.selectedDc = selecteddc.dcenterId;
    this.dcentercustomerDn = selecteddc.dcentercustomerDn;
    this.dcenteraddressId = selecteddc.dcenteraddressId;
  }

  getStates() {
    this.aestates = [];
    this.selectedState = 0;
    this.aestatescountry.forEach((element: Aestateprovincecountry) => {
      if (element.countryId == this.selectedCountry) {
        var stateprovince = {
          key: element.key,
          value: element.value
        }
        this.aestates.push(stateprovince);
        console.log('state ' + element.key + ' ' + element.value);
      }
    });
  }

  getwarehousesData() {
    return this.warehousesApi.getAewarehouses().pipe(tap(data => {
      this.aewarehousesJson = data;
    }));
  }

  ngAfterViewInit() {
    this.showxorderTable("showxorders");
  }

  showxorderTable(bshow: string) {
    if (bshow == "showxorders") {
      this.showxorders = true;
      this.showaddproducts = false;
      this.showxcart = false;
      this.showinvoice = false;
      this.showcustomers = false;
      this.showtransfers = false;
      this.showadditionalproducts = false;
      this.aexorders = undefined;
      if (this.selectedDc == 0) {
        this.acdcNotificationsService.toast({
          title: 'Warning... No available XPress Orders',
          timeout: 3000,
          notificationLevel: 'Warn'
        })
      }
      else {
        const acdcid: string = this.acdcNotificationsService.toast({
          title: 'Please wait... Loading available Xpress Orders',
          timeout: 120000,
          notificationLevel: 'Info'
        });
        this.api.getAexorders('00000', this.selectedDc, true, this.chkAvailable ? "y" : "n")
          .subscribe((data: Aexorder[]) => {
            this.aexorders = data;
            this.acdcNotificationsService.deleteToast(acdcid);
            if (this.aexorders.length == 0) {
              this.acdcNotificationsService.toast({
                title: 'Warning... No available XPress Orders',
                timeout: 3000,
                notificationLevel: 'Warn'
              })
            }
          });
      }
    } else

      if (bshow == "showaddproducts") {
        this.aexorderproducts = [];
        this.labelContent = 'Adding Products';
        this.labelContent1 = 'Order: ' + this.selectedXOrderNumber
        this.labelContent2 = 'Distributor: ' + this.dcentercustomerDn;
        this.showxorders = false;
        this.showaddproducts = true;
        this.showinvoice = false;
        this.showcustomers = false;
        this.invoiceId = '0';
        this.showxcart = false;
        this.showtransfers = false;
        this.showadditionalproducts = false;
        const acdcid: string = this.acdcNotificationsService.toast({
          title: 'Please wait... Loading Product data',
          timeout: 60000,
          notificationLevel: 'Info'
        });
        this.aexorderproducts = undefined;
        this.api.getAexorderproducts(this.selectedXOrderId, this.selectedXProductId, 0, this.selectedWarehouse)
          .subscribe((data: Aexorderproductslist[]) => {
            this.aexorderproducts = data;
            if (this.aexorderproducts.length == 0) {
              this.acdcNotificationsService.deleteToast(acdcid);
              this.acdcNotificationsService.toast({
                title: 'Warning... No Product data',
                timeout: 3000,
                notificationLevel: 'Warn'
              });
            }
            else {
              //                  if (this.selectedWarehouse != this.selectedPreviousWarehouse) {
              this.api.getAdditionalorderProducts(this.dcentercustomerDn, this.selectedXOrderId, this.selectedXProductId, this.currentGuid, this.selectedWarehouse,0)
                .subscribe((dataa: Additionalorderproductslist[]) => {
                  this.aeadditionalproducts = dataa;
                  console.log(this.aeadditionalproducts);
                  this.selectedPreviousWarehouse = this.selectedWarehouse;
                  if (this.aeadditionalproducts.length > 0) {
                    this.continueLabel = 'Continue'
                  }
                });
              //                  }
              this.aexorderproductsgifts = undefined;
              this.api.getAexorderproductgifts(this.selectedXOrderId, this.selectedXProductId, this.currentGuid, this.dcentercustomerDn)
                .subscribe((dataf: Aexorderproductsgiftlist[]) => {
                  this.aexorderproductsgifts = dataf;
                  console.log('gifts ' + dataf);
                })
              this.aexorderdcproducts = undefined;
              this.api.getAexorderdcproducts(this.selectedXOrderId, this.selectedXProductId, this.currentGuid, this.dcentercustomerDn, false)
                .subscribe((datag: Aexorderdcproducts[]) => {
                  this.aexorderdcproducts = datag;
                  console.log('dcproducts ' + this.aexorderdcproducts);
                })
            }
            this.api.getAexordertotals(this.selectedXOrderId, this.selectedXProductId)
              .subscribe((data: Aexordertotals) => {
                this.currentTotal = data.currentTotal;
                this.previousTotal = data.previousTotal;
                this.tolerance = data.tolerance;
                this.availableCredit = (this.totalCredit - this.currentTotal - this.previousTotal);
                this.currentTotalLabel = this.currentTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                this.previousTotalLabel = this.previousTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                this.availableCreditLabel = this.availableCredit.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                this.acdcNotificationsService.deleteToast(acdcid);
              });
          });

      } else
        if (bshow == "showxcart") {
          this.labelContent = 'Order Information'
          this.showxorders = false;
          this.showaddproducts = false;
          this.showinvoice = false;
          this.showcustomers = false;
          this.showxcart = true;
          this.showtransfers = false;
          this.showadditionalproducts = false;
        } else
          if (bshow == "showadditionalproducts") {
            this.labelContent = 'Order Information'
            this.showxorders = false;
            this.showaddproducts = false;
            this.showinvoice = false;
            this.showcustomers = false;
            this.showxcart = false;
            this.showtransfers = false;
            this.showadditionalproducts = true;
          }
          else
            if (bshow == "showcustomers") {
              this.labelContent = 'Order Information'
              this.showxorders = false;
              this.showaddproducts = false;
              this.showinvoice = false;
              this.showcustomers = true;
              this.showxcart = false;
              this.showtransfers = false;
              this.showadditionalproducts = false;
            }
            else
              if (bshow == "showtransfers") {
                this.labelContent = 'Order Information'
                this.showxorders = false;
                this.showaddproducts = false;
                this.showinvoice = false;
                this.showcustomers = false;
                this.showxcart = false;
                this.showtransfers = true;
                this.showadditionalproducts = false;
              }
              else
                if (bshow == "showinvoice") {
                  const ordermessage = 'Order ' + this.invoiceNumber + ' Registered'
                  this.acdcNotificationsService.toast({
                    title: ordermessage,
                    timeout: 6000,
                    notificationLevel: 'Success'
                  });
                  this.labelContent = 'Invoice Information'
                  this.showxorders = false;
                  this.showaddproducts = false;
                  this.showinvoice = true;
                  this.showcustomers = false;
                  this.invoiceNumberLabel = this.invoiceNumber;
                  this.showxcart = false;
                  this.showtransfers = false;
                  this.showadditionalproducts = false;
                  const acdcid: string = this.acdcNotificationsService.toast({
                    title: 'Please wait... Loading Product data',
                    timeout: 60000,
                    notificationLevel: 'Info'
                  });
                  this.api.getAexorderproducts(this.selectedXOrderId, this.selectedXProductId, 1, this.selectedWarehouse)
                    .subscribe((dataInv: Aexorderproductslist[]) => {
                      this.aexorderproducts = dataInv;
                      console.log('dataInv ' + dataInv)
                      this.acdcNotificationsService.deleteToast(acdcid);
                      if (this.aexorderproducts.length == 0) {
                        this.acdcNotificationsService.toast({
                          title: 'Warning... No Product data',
                          timeout: 3000,
                          notificationLevel: 'Warn'

                        });
                      }
                      this.api.getAexordertotals(this.selectedXOrderId, this.selectedXProductId)
                        .subscribe((dataTot: Aexordertotals) => {
                          this.currentTotal = dataTot.currentTotal;
                          this.previousTotal = dataTot.previousTotal;
                          this.tolerance = dataTot.tolerance;
                          this.availableCredit = (this.totalCredit - this.currentTotal - this.previousTotal);
                          this.currentTotalLabel = this.currentTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                          this.previousTotalLabel = this.previousTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                          this.availableCreditLabel = this.availableCredit.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                        });
                    });
                }

  }

  xorderEditDialog(event) {
    this.currentTotalLabel = '0.00';
    this.previousTotalLabel = '0.00';
    this.availableCreditLabel = '0.00';
    var selectedorder = this.aexorders.find(x => x.orderNumber === event.data.orderNumber && x.productName === event.data.productName)
    this.customeraddressApi.getAeordercustomeraddress(this.dcentercustomerDn)
      .subscribe((data: Aecustomeraddress[]) => {
        this.aeaddresses = data;
        console.log(this.aeaddresses);
      });
    this.selectedXOrderId = selectedorder.xorderId;
    this.selectedXOrderNumber = selectedorder.orderNumber;
    this.selectedXProductId = selectedorder.productId;
    this.selectedXProductQuantity = selectedorder.quantity;
    this.confirmarDireccion = selectedorder.confirmarDireccion;
    this.esLenceria = selectedorder.esLenceria;
    this.soloMexico = selectedorder.soloMexico;

    // let sufixI = " and must";
    // let sufixE = " y debe";
    let sufixI = " and should";
    let sufixE = " y debería";
    // if (this.impersonatorDn.length > 0) {
    //   sufixI = " and should";
    //   sufixE = " y debería";
    // }

    var previousindex = this.aexorders.findIndex(x => x.xorderId < selectedorder.xorderId && x.availableCreditD >= 50)
    if (previousindex != -1) {
      const dialogRef = this.dialog.open(ContinuationDialog, {
        maxWidth: "500px",
        data: {
          message: "Order " + this.aexorders[previousindex].orderNumber + " is prior to" +
            " \n Order " + this.selectedXOrderNumber + sufixI +
            " \n be processed first",
          message2: "La Orden " + this.aexorders[previousindex].orderNumber + " es anterior" +
            " \n a la Orden " + this.selectedXOrderNumber + sufixE +
            " \n ser procesada primero",
        }
      });
      // if (this.impersonatorDn.length == 0) {
      //   this.showxorderTable("showxorders");
      // }
      // else { this.xorderEdit(selectedorder) }
    }
    // else {
    //   this.xorderEdit(selectedorder)
    // }
    this.xorderEdit(selectedorder)
  }

  xorderEdit(selectedorder: Aexorder) {
    if (this.confirmarDireccion) {
      this.acdcNotificationsService.toast({
        message: 'Must confirm Order Address',
        timeout: 3000,
        notificationLevel: 'Warn'
      });
    }
    if (this.esLenceria) { this.selectedWarehouse = 1; this.enablewarehouse = false; }
    else { this.enablewarehouse = true; }

    if (this.currentGuid == undefined) {
      this.currentGuid = uuid();
      this.localstorage.setItem('currentguid', this.currentGuid);
      this.localstorage.setItem('cleancustomerdn', this.dcentercustomerDn);
      this.localstorage.setItem('dcxorderid', this.selectedXOrderId);
    }
    let orderGuidValid: boolean = true;
    this.api.getValidorderguid(this.selectedXOrderId, this.selectedXProductId, this.currentGuid, this.dcentercustomerDn, this.deskSessionId,0).subscribe({
      next: dataz => {
        if (dataz.validOrderGuid != this.currentGuid) {
          orderGuidValid = false;
          this.acdcNotificationsService.toast({
            message: dataz.validOrderGuid,
            timeout: 3000,
            notificationLevel: 'Error'
          });
        }
        else {
          if (this.soloMexico) { this.selectedCountry = 145; this.enablewarehouse = false; }
          else { this.selectedCountry = selectedorder.countryId; }
          this.getStates();
          var selectedwarehouse = this.aewarehouses.find(x => x.countryId === this.selectedCountry)
          this.selectedWarehouse = selectedwarehouse.id
          this.countryLabel = selectedwarehouse.countryName;
          if (this.selectedCountry == 1) {
            this.showfaxnumber = false;
            this.customerAddressForm.controls['faxNumber'].setValue(' ');
            this.customerAddressForm.controls['address2'].setValue(' ');
            this.selectedState = 47;
            var selectedstate = this.aestates.find(x => x.key === this.selectedState);
            this.selectedStateCode = selectedstate.value.substring(0, 2);
          }
          else if (this.selectedCountry == 145) {
            this.showfaxnumber = true;
            this.customerAddressForm.controls['faxNumber'].setValue('');
            this.customerAddressForm.controls['address2'].setValue('');
            this.selectedState = 86;
            var selectedstate = this.aestates.find(x => x.key === this.selectedState);
            this.selectedStateCode = selectedstate.value.substring(0, 2);
          } else {
            this.showfaxnumber = true;
            this.customerAddressForm.controls['faxNumber'].setValue('');
            this.customerAddressForm.controls['address2'].setValue('');
            this.aestates = [{ key: 77, value: "N/A" }];
            this.selectedState = 77
            this.selectedStateCode = 'NA';
          }
          console.log('selected ware ' + this.selectedWarehouse);
          this.selectedXOrderId = selectedorder.xorderId;
          this.selectedXProductId = selectedorder.productId;
          this.selectedFreeProductId = selectedorder.freeProductId;
          this.totalCredit = parseFloat(selectedorder.totalCredit.replace(',', ''));
          this.api.getIpaddress()
            .subscribe((data: Aeipaddress) => {
              this.customerIp = data.ip;
            });
          this.showxorderTable("showaddproducts");
        }
      }
    });
  }

  onSaveConfirm(event) {
    let minimumquantity: number = 1;
    let restrictedquantity: number = 1;
    this.api.getAeproductquantities(event.newData.id)
      .subscribe((dataq: Aeproductquantities) => {
        minimumquantity = dataq.minimumQuantity;
        restrictedquantity = dataq.restrictedQuantity;
        var data = {
          "xorderId": this.selectedXOrderId,
          "xproductId": this.selectedXProductId,
          "productId": event.newData.id,
          "productQuantity": parseInt(event.newData.productQuantity),
          "productPrice": parseFloat(event.newData.productPrice.replace(',', '')),
          "warehouseId": this.selectedWarehouse,
          "pickup": false,
          "porderId": 0
        };
        var oldQuantity = parseInt(event.data.productQuantity)

        if (this.validateProduct(data, oldQuantity)) {
          console.log(data);
          this.http.post<number>(xorderproductserviceUrl, data, httpOptions).subscribe(
            res => {
              if (res < 0) {
                let mtitle = '';
                if (res === -1) { mtitle = 'Product rejected due to unsufficient stock' }
                if (res === -2) { mtitle = 'Product rejected due to low price' }
                if (res === -3) { mtitle = 'Minimum quantity for this Product: ' + minimumquantity.toString(); }
                if (res === -4) { mtitle = 'Please order multiples of : ' + restrictedquantity.toString() + ' for this product' }
                this.acdcNotificationsService.toast({
                  title: `${mtitle}`,
                  timeout: 3000,
                  notificationLevel: 'Error'
                });
                event.confirm.reject();
                this.showxorderTable("showaddproducts");
              }
              else {
                console.log('product ' + res);
                event.confirm.resolve(event.newData);
                this.acdcNotificationsService.toast({
                  title: 'Added...' + event.newData.productQuantity + ' ' + event.newData.productName,
                  timeout: 3000,
                  notificationLevel: 'Success'
                });

                let prdind = this.aexorderproducts.findIndex(x => x.id === event.newData.id)
                let newTotal = event.newData.productQuantity *
                  this.aexorderproducts[prdind]["price"];
                event.newData.productTotal = newTotal.toLocaleString("en-US");
                this.showxorderTable("showaddproducts");
              }
            },
            (err: HttpErrorResponse) => {
              if (err.error instanceof Error) {
                console.log("Client-side error occured.");
                this.acdcNotificationsService.toast({
                  title: 'Client-side error occured.',
                  timeout: 3000,
                  notificationLevel: 'Error'
                });
              } else {
                console.log("Server-side error occured.");
                this.acdcNotificationsService.toast({
                  title: 'Server-side error occured.',
                  timeout: 3000,
                  notificationLevel: 'Error'
                });
              }
            });
        } else {
          if (this.availablemessage) {
            this.acdcNotificationsService.toast({
              title: this.productValidationMessage,
              timeout: 3000,
              notificationLevel: 'Info'
            });
          } else {
            this.acdcNotificationsService.toast({
              title: this.productValidationMessage,
              timeout: 3000,
              notificationLevel: 'Error'
            });
          }
          event.confirm.reject();
        }
      });
  }

  convertToAexorder(value: any): Aexordermodeldc {
    const aexorderrecord: Aexordermodeldc = value;
    return aexorderrecord;
  }

  noporderValidation() {
    this.aexorderModel = this.convertToAexorder(this.customerAddressForm.value);
    var selectedstate = this.aestates.find(x => x.key === this.selectedState);
    this.selectedStateCode = selectedstate.value.substring(0, 2);
    this.taxJarRate = 0;

    this.api.getInvalidpromotions(this.dcentercustomerDn, this.selectedWarehouse).subscribe({
      next: data => {
        this.invalidPromotions = data.invalidPromotionsList;

        if (this.invalidPromotions != 'ok') {
          this.acdcNotificationsService.toast({
            title: "Invalid Promotions",
            message: this.invalidPromotions,
            timeout: 3000,
            notificationLevel: 'Error'
          });
        }
        else {
          if (this.selectedCountry == 1) {
            let zipCodeValid: boolean = true;
            this.api.getZipcodevalidation(this.selectedCountry, this.selectedStateCode, this.aexorderModel.zipCode)
              .subscribe({
                next: dataz => {
                  if (dataz.validZipcode == 'ko') {
                    this.api.getTaxjarvalidation(this.aexorderModel.zipCode)
                      .subscribe((dataR: Aetaxjarresponse) => {
                        if (dataR.error.length == 0) {
                          if (dataR.state != this.selectedStateCode) {
                            zipCodeValid = false;
                            this.acdcNotificationsService.toast({
                              title: "Entered state doesn't match entered ZipCode",
                              timeout: 3000,
                              notificationLevel: 'Error'
                            });
                          }
                          else {
                            this.taxJarRate = dataR.combinedR;
                            this.noporderRegister();
                          }
                        }
                        else {
                          this.acdcNotificationsService.toast({
                            title: "ZipCode " + dataR.error,
                            timeout: 3000,
                            notificationLevel: 'Error'
                          });
                        }
                      });
                  }
                  else {
                    this.taxJarRate = 0;
                    this.noporderRegister();
                  }
                }
              });
          }
          else if (this.selectedCountry == 145) {
            let zipCodeValid: boolean = true;
            this.api.getZipcodevalidation(this.selectedCountry, this.selectedStateCode, this.aexorderModel.zipCode)
              .subscribe({
                next: dataz => {
                  if (dataz.validZipcode == 'ko') {
                    zipCodeValid = false;
                    this.acdcNotificationsService.toast({
                      title: "Entered state doesn't match entered ZipCode",
                      timeout: 3000,
                      notificationLevel: 'Error'
                    });
                  }
                  else {
                    this.taxJarRate = 0;
                    this.noporderRegister();
                  }
                }
              });
          }
          else {
            this.taxJarRate = 0;
            this.noporderRegister();
          }
        }
      },
      error: err => console.log(`Oops... ${err}`),
      complete: () => console.log(`Complete!`),
    });
  }

  noporderRegister() {
    let bsaveaddress = false;
    //    let bpickupinStore = false;
    let bpickupinDC = false;
    if (this.aexorderModel.saveAddress !== null) { bsaveaddress = this.aexorderModel.saveAddress; }
    //    if (this.aexorderModel.pickupinStore !== null) { bpickupinStore = this.aexorderModel.pickupinStore; }
    if (this.aexorderModel.pickupinDC !== null) { bpickupinDC = this.aexorderModel.pickupinDC; }
    var data = {
      "xorderId": this.selectedXOrderId,
      "xproductId": this.selectedXProductId,
      "ciclosId": this.selectedCycle,
      "customerIp": this.customerIp,
      "taxRate": 0,
      "orderTotal": this.currentTotal,
      "firstName": this.aexorderModel.firstName,
      "lastName": this.aexorderModel.lastName,
      "email": this.aexorderModel.email,
      "countryId": this.selectedCountry,
      "stateProvinceId": this.selectedState,
      "city": this.aexorderModel.city,
      "address1": this.aexorderModel.address1,
      "address2": this.aexorderModel.address2,
      "zipCode": this.aexorderModel.zipCode,
      "phoneNumber": this.aexorderModel.phoneNumber,
      "faxNumber": this.aexorderModel.faxNumber,
      "saveAddress": bsaveaddress,
      "pickupinstore": false,
      "impersonatordn": this.impersonatorDn,
      "currentoperator": this.currentOperator,
      "currentGuid": this.currentGuid,
      "pickupinDC": bpickupinDC,
      "distributionCenterId": this.selectedDc,
      "warehouseId": this.selectedWarehouse,
      "includeSuperPacks": false,
      "partialOrderId": 0
    };
    console.log('data ' + data);
    data.taxRate = this.taxJarRate;

    this.api.getForeignshipping(this.selectedXOrderId, this.selectedXProductId, this.selectedCountry).subscribe({
      next: dataS => {
        this.foreignShippingAmount = dataS.foreignShippingAmount;
        this.foreignWalletRecharge = dataS.foreignWalletRecharge;
        if (this.foreignShippingAmount != "0.00") {
          if (this.foreignWalletRecharge != "0.00") {
            const dialogRef = this.dialog.open(ContinuationDialog, {
              maxWidth: "400px",
              data: {
                message: "You must recharge your wallet with US$" + this.foreignWalletRecharge +
                  " \n for this order to be shipped",
              }
            });
          } else {
            const dialogRef = this.dialog.open(ContinuationDialog, {
              maxWidth: "400px",
              data: {
                message: "Your Wallet will be charged with US$" + this.foreignShippingAmount +
                  " \n for this order to be shipped",
              }
            });
          }
        }
        const acdcid: string = this.acdcNotificationsService.toast({
          title: 'Please wait... Processing Order',
          timeout: 60000,
          notificationLevel: 'Info'
        });
        this.invoiceNumber = '';
        this.http.post<Aeinvoiceresponse>(xordermodeldcserviceUrl, data, httpOptions).subscribe(
          res => {
            this.acdcNotificationsService.deleteToast(acdcid);
            let ordermessage = '';
            console.log('res val ' + res.invoiceNumber);
            if (res.invoiceNumber.startsWith('Error') || res.invoiceNumber.length == 0 || res.invoiceNumber == null) {
              ordermessage = `Order Processing Error  ${res.invoiceNumber} 
                    Your order was NOT placed`
              this.acdcNotificationsService.toast({
                title: ordermessage,
                timeout: 3000,
                notificationLevel: 'Error'
              });
            }
            else if (res.invoiceNumber.startsWith('I')) {
              this.invoiceId = res.invoiceNumber.substring(0, 8);
              this.invoiceNumber = res.invoiceNumber;
              this.showxorderTable("showinvoice");
            }
            else { this.showxorderTable("showxorders"); }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log("Client-side error occured");
              this.acdcNotificationsService.toast({
                title: 'Client-side error occured. Please retry',
                timeout: 3000,
                notificationLevel: 'Error'
              });
            } else {
              console.log("Server-side error occured");
              this.acdcNotificationsService.toast({
                title: 'Server-side error occured. Please retry',
                timeout: 4000,
                notificationLevel: 'Error'
              });
            }
          }
        );
      }
    });
  }

  validateProduct(data: Aexorderproductsmodel, oldQuantity: number): boolean {
    var validProduct = true
    var productToValidate = this.aexorderproducts.find(x => x.id === data.productId);
    this.availablemessage = false;
    if (productToValidate.id == this.selectedFreeProductId) {
      this.productValidationMessage = "This product can't be modified";
      validProduct = false;
    }
    // else if ((this.selectedXProductId == 7145 || this.selectedXProductId == 6092) &&
    //     productToValidate.id == 8832 && data.productQuantity > 0) {
    //   if (productToValidate.previousQuantity + data.productQuantity > this.selectedXProductQuantity) {
    //     this.productValidationMessage = 'You can only Order ' + this.selectedXProductQuantity +
    //     ' units per package from this product';
    //     validProduct = false;
    //   }
    // }
    // else if ((this.selectedXProductId != 7145 && this.selectedXProductId != 6092) &&
    //    productToValidate.id == 8832 && data.productQuantity > 0) {
    //     this.productValidationMessage = 'This product is not available in this package';
    //     validProduct = false;
    // }
    else {
      if (data.productQuantity >= 0) {
        if (productToValidate.availableQuantity < data.productQuantity) {
          validProduct = false;
          if (productToValidate.availableQuantity > 0) {
            this.productValidationMessage = 'There are only ' + productToValidate.availableQuantity +
              ' units left to order from this product';
          }
          else if (productToValidate.availableMessage != 'Out of Stock') {
            this.productValidationMessage = 'Available from: ' + productToValidate.availableMessage;
            this.availablemessage = true;
          }
          else {
            if (data.productQuantity > 0) {
              this.productValidationMessage = productToValidate.availableMessage;
            } else {
              validProduct = true;
            }
          }
        }
        else {
          if (

            this.totalCredit - (this.previousTotal + this.currentTotal - oldQuantity * data.productPrice +
              data.productQuantity * data.productPrice) < -this.tolerance
          ) {
            validProduct = false;
            this.productValidationMessage = "You can't exceed the available credit for this order";
          }
        }
      }
      else {
        validProduct = false;
        this.productValidationMessage = 'Quantity must be greater or equal to 0';
      }
    }
    return validProduct;
  }

  back() {
    if (this.showxcart) { this.backfromCart = true; this.showxorderTable("showaddproducts"); }
    else { this.showxorderTable("showxorders"); }
  }

  ordersBack() {
    const url = '/aeuserdashboard'
    this.router.navigate([url]);
  }

  gotoCart() {
    if (this.currentTotal == 0) {
      this.acdcNotificationsService.toast({
        title: "Order total = 0; Please select Products",
        timeout: 3000,
        notificationLevel: 'Warn'
      });
    }
    else {
      this.showxorderTable("showxcart");
    }
  }

  onCycleChange(event) {
    this.selectedCycle = event.key;
  }

  onStateChange(event) {
    this.selectedState = event.key;
    this.selectedStateCode = event.value.substring(0, 2);
  }

  onPickupChange(event) {
    if (event.target.checked == true) {
      this.aeaddresses = [...this.aepickupaddresses];
      this.pickupChecked = true;
      this.customerAddressForm.get('saveAddress').disable();
    } else {
      this.aeaddresses = [...this.aecustomeraddresses];
      this.customerAddressForm.get('saveAddress').enable();
      this.pickupChecked = false
    }
  }



  onWarehouseChange(event) {
    this.selectedWarehouse = event.id;
    this.selectedCountry = event.countryId;
    this.getStates();
    var selectedwarehouse = this.aewarehouses.find(x => x.countryId === this.selectedCountry)
    //    this.selectedWarehouse = selectedwarehouse.id
    this.countryLabel = selectedwarehouse.countryName;
    if (this.selectedCountry == 1) {
      this.showfaxnumber = false;
      this.customerAddressForm.controls['faxNumber'].setValue(' ');
      this.customerAddressForm.controls['address2'].setValue(' ');
      this.selectedState = 47;
      var selectedstate = this.aestates.find(x => x.key === this.selectedState);
      this.selectedStateCode = selectedstate.value.substring(0, 2);
    }
    else if (this.selectedCountry == 145) {
      this.showfaxnumber = true;
      this.customerAddressForm.controls['faxNumber'].setValue('');
      this.customerAddressForm.controls['address2'].setValue('');
      this.selectedState = 86;
      var selectedstate = this.aestates.find(x => x.key === this.selectedState);
      this.selectedStateCode = selectedstate.value.substring(0, 2);
    } else {
      this.showfaxnumber = true;
      this.customerAddressForm.controls['faxNumber'].setValue('');
      this.customerAddressForm.controls['address2'].setValue('');
      this.aestates = [{ key: 77, value: "N/A" }];
      this.selectedState = 77
      this.selectedStateCode = 'NA';
    }

    console.log('selected ware ' + this.selectedWarehouse);
    this.selectedAddress = null;
    this.customerAddressForm.controls['ngselectedAddress'].setValue('');
    this.customerAddressForm.controls['firstName'].setValue('');
    this.customerAddressForm.controls['lastName'].setValue('');
    this.customerAddressForm.controls['email'].setValue('');
    this.customerAddressForm.controls['city'].setValue('');
    this.customerAddressForm.controls['zipCode'].setValue('');
    this.customerAddressForm.controls['address1'].setValue('');
    this.customerAddressForm.controls['phoneNumber'].setValue('');
    this.showxorderTable("showaddproducts");
  }

  onAddressChange(event) {
    const selectedaddress = event.addressId;
    const addressIndex = this.aeaddresses.findIndex(element => element.addressId == selectedaddress);
    const selectedcountry = this.aeaddresses[addressIndex].countryId;
    if (selectedcountry == this.selectedCountry) {
      this.selectedAddress = selectedaddress;
      this.setaddressValue(addressIndex);
    }
    else {
      this.customerAddressForm.controls['ngselectedAddress'].setValue('');
      this.selectedAddress = null;
      this.acdcNotificationsService.toast({
        title: "Address must be in Selected Country or create a new one",
        timeout: 3000,
        notificationLevel: 'Warn'
      });
    }
  }


  setaddressValue(addressIndex: number) {
    this.selectedState = this.aeaddresses[addressIndex].stateProvinceId;
    if (this.selectedState == 0) {
      if (this.selectedCountry == 1) { this.selectedState = 47; this.selectedStateCode = "CA"; }
      else if (this.selectedCountry == 145) { this.selectedState = 76; this.selectedStateCode = "NA"; }
      else { this.selectedState = 77; this.selectedStateCode = "NA"; }
    } else {
      var selectedstate = this.aestates.find(x => x.key === this.selectedState);
      this.selectedStateCode = selectedstate.value.substring(0, 2);
    }

    this.customerAddressForm.controls['firstName'].setValue(this.aeaddresses[addressIndex].firstName);
    this.customerAddressForm.controls['lastName'].setValue(this.aeaddresses[addressIndex].lastName);
    this.customerAddressForm.controls['email'].setValue(this.aeaddresses[addressIndex].email);
    this.customerAddressForm.controls['city'].setValue(this.aeaddresses[addressIndex].city);
    this.customerAddressForm.controls['zipCode'].setValue(this.aeaddresses[addressIndex].zipCode);
    this.customerAddressForm.controls['address1'].setValue(this.aeaddresses[addressIndex].address1);
    this.customerAddressForm.controls['phoneNumber'].setValue(this.aeaddresses[addressIndex].phoneNumber);
    if (this.selectedCountry != 1) {
      this.customerAddressForm.controls['address2'].setValue(this.aeaddresses[addressIndex].address2);
      this.customerAddressForm.controls['faxNumber'].setValue(this.aeaddresses[addressIndex].faxNumber);
    }
    else {
      this.customerAddressForm.controls['address2'].setValue(' ');
      this.customerAddressForm.controls['faxNumber'].setValue(' ');
    }
    this.selectedCycle = this.aecycles[0].key;
  }

  customerEditDialog(event) {
    this.transferCustomerDn = event.data.customerDn;
    if (this.dcentercustomerDn != this.transferCustomerDn) {
      this.localstorage.setItem('xorderscustomerdn', this.transferCustomerDn);
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        maxWidth: "400px",
        data: {
          message: "Transfering Selected Products" +
            " to Distributor " + this.transferCustomerDn,
          imageurl: "https://eternalsb.com/images/thumbs/0014404_promo-0916-1-d-1_600.jpeg",
        }

      });
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult == true) {
          var data = {
            "fromCustomerDn": this.dcentercustomerDn,
            "toCustomerDn": this.transferCustomerDn,
            "currentOperator": this.currentOperator
          };
          console.log(data);
          this.http.post<number>(customerproductstransferUrl, data, httpOptions).subscribe(
            res => {
              console.log('transfered products ' + res);
              this.acdcNotificationsService.toast({
                title: 'Products Transfered',
                timeout: 3000,
                notificationLevel: 'Success'
              });
              this.showxorderTable("showxorders");
            });
        }

      });
    }
    else {
      this.acdcNotificationsService.toast({
        title: 'Transfers to the same Customer are not allowed',
        timeout: 3000,
        notificationLevel: 'Error'
      });
    }
  }

  transferEditDialog(event) {
    const imageSku = event.data.productSku;
    this.api.getImageurl(imageSku)
      .subscribe((data: Aeimageurl) => {
        var dataimageurl = data.imageUrl;
        console.log(data.imageUrl);
        const imagedialogRef = this.dialog.open(ImageDialog, {
          maxWidth: "900px",
          data: {
            imageurl: dataimageurl,

          }
        });
      });
  }
  additionalproductsEditDialog(event) {
    const imageSku = event.data.productSku;
    this.api.getImageurl(imageSku)
      .subscribe((data: Aeimageurl) => {
        var dataimageurl = data.imageUrl;
        console.log(data.imageUrl);
        const imagedialogRef = this.dialog.open(ImageDialog, {
          maxWidth: "900px",
          data: {
            imageurl: dataimageurl,

          }
        });
      });
  }

  additionalProducts() {
    if (this.currentTotal == 0) {
      this.acdcNotificationsService.toast({
        title: "Order total = 0; Please select Products",
        timeout: 3000,
        notificationLevel: 'Warn'
      });
    }
    else {
      if (this.additionalProducts != undefined && this.aeadditionalproducts.length > 0) {
        this.showxorderTable("showadditionalproducts");
      }
      else {
        this.gotoCart();
      }
    }
  }

  additionalproductsBack() {
    this.showxorderTable("showaddproducts");
  }

  transfersBack() {
    this.showxorderTable("showxorders");
  }

  transfers() {
    this.showxorderTable("showtransfers");
    const acdcid: string = this.acdcNotificationsService.toast({
      title: 'Please wait... Loading Available Transfer Products',
      timeout: 120000,
      notificationLevel: 'Info'
    });
    this.api.getTransferProducts(this.dcentercustomerDn)
      .subscribe((data: Transferproductslist[]) => {
        this.acdcNotificationsService.deleteToast(acdcid);
        this.aetransferproducts = data;
        console.log(this.aetransferproducts);
      });
  }

  toggleAvailable(event) {
    this.chkAvailable = event.target.checked;
    this.showxorderTable("showxorders");
  }


}

