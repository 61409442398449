import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AesharedModule } from '../aeshared/aeshared.module';
import { AexordersComponent } from './aexorders.component';
import { AexordersSuperComponent } from './aexorders_super.component';
import { AexordersPickupComponent } from './aexorders_pickup.component';
import { AexordersInventoryComponent } from './aexorders_inventory.component';
import { AexordersInventoryUserComponent } from './aexorders_inventoryuser.component';
//import { AexordersMultiComponent } from './aexorders_multi.component';
// import { AepordersComponent } from './aeporders.component';
//import { AexordersadminComponent } from './aexorders_admin.component';
//import { SelectedXorderRenderComponent } from './aexorders_render_selected.component';
import { DistributionRenderComponent } from './aexorders_render_distributionrender.component';
import { ImageRenderComponent } from './aexorders_render_imagerender.component';
import { PriceRenderComponent } from './aexorders_render_pricerender.component';
import { TransfersRenderComponent } from './aexorders_render_transfersrender.component';
import { AdditionalProductsRenderComponent } from './aexorders_render_additionalproducts.component';

@NgModule({
  declarations: [
    AexordersComponent,  
    AexordersSuperComponent,
    AexordersPickupComponent,
    AexordersInventoryComponent,
    AexordersInventoryUserComponent,
    ImageRenderComponent,
    PriceRenderComponent,
    TransfersRenderComponent,
    AdditionalProductsRenderComponent,
    DistributionRenderComponent,
    //SelectedXorderRenderComponent,
    //AexordersMultiComponent,
    //AexordersadminComponent,
    // AepordersComponent,

  ],
  entryComponents: [

    ImageRenderComponent,
    PriceRenderComponent,
    TransfersRenderComponent,
    AdditionalProductsRenderComponent,
    DistributionRenderComponent,
    //SelectedXorderRenderComponent,
  ],
  imports: [
    CommonModule,
    AesharedModule,
  ]
})
export class AexordersModule { }
